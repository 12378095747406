import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updatePxPosition } from 'store/modules/simulator/actions';
import usePrevState from '../usePrevState';

function PositionSubscriber({ position, quotationServiceFunc }) {
	const dispatch = useDispatch();
	const timerUpdate = useSelector(state => state.simulator.timerUpdate);
	const prevSymbol = usePrevState(position?.symbol);
	const prevQtty = usePrevState(position?.qtty);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const [streamingConnectionId, setStreamingConnectionId] = useState('');
	const [streamingMBookConnectionId, setStreamingMBookConnectionId] =
		useState('');
	const prevStreamingConnectionId = useRef();
	const prevStreamingMbookConnectionId = useRef();
	const [updatePxPending, setUpdatePxPending] = useState(false); //Atualização do preço no redux de acordo com o tipo de input selecionado

	const [workingMktData_entryPx, setWorkingMktData_entryPx] = useState(null);
	const [workingMktData_exitPx, setWorkingMktData_exitPx] = useState(null);
	const [workingMktData_tradePx, setWorkingMktData_tradePx] = useState(null);
	const [workingMktData_symbol, setWorkingMktData_symbol] = useState(null);

	useEffect(() => {
		if (
			((position?.symbol &&
				prevSymbol &&
				position.symbol !== prevSymbol) ||
				(position?.qtty &&
					prevQtty &&
					position.qtty / Math.abs(position.qtty) !==
						prevQtty / Math.abs(prevQtty))) &&
			!position.expired
		) {
			paperUnsubs(
				prevStreamingConnectionId.current || streamingConnectionId
			);
			paperUnsubs(
				prevStreamingMbookConnectionId.current ||
					streamingMBookConnectionId
			);
			setWorkingMktData_entryPx(0);
			setWorkingMktData_exitPx(0);
			setWorkingMktData_tradePx(0);
			const subTradeId = paperSubscribe(position?.symbol, 'trade');
			const subMbookId = paperSubscribe(position?.symbol, 'minibook');
			setStreamingConnectionId(subTradeId);
			setStreamingMBookConnectionId(subMbookId);
		}
	}, [position?.symbol, position?.qtty]);

	useEffect(() => {
		if (updatePxPending) {
			if (position?.symbol !== workingMktData_symbol) {
				//If has changed legs
				return;
			}

			let workingMktData = {
				last_entryPx: workingMktData_entryPx,
				last_exitPx: workingMktData_exitPx,
				last_midPx:
					workingMktData_exitPx && workingMktData_entryPx
						? parseFloat(
							(workingMktData_entryPx +
									workingMktData_exitPx) /
									2
						  ).toFixed(4)
						: workingMktData_entryPx || workingMktData_exitPx,
				last_tradePx: workingMktData_tradePx,
			};
			dispatch(
				updatePxPosition(activeStrategy, position.id, workingMktData)
			);
			setUpdatePxPending(false);
		}
	}, [timerUpdate]);

	const paperSubscribe = (symbol, type) => {
		if (!symbol) return;

		const props = {
			action: 'subscribe',
			type: type,
			key: symbol,
			callbackFunction: data => {
				const { msgType } = data;
				//console.log("From mini: symbol: " + data.symbol + "; ask: " + data.askPx + "; bid: " + data.bidPx + "; price: " + data.price + "; lastPx: " + data.lastPx);
				if (msgType === 'M' || msgType === 'T') {
					//Acrescentado trade para poder recuperar o ultimo em cada position
					if (
						data.askPx != null ||
						data.bidPx != null ||
						data.price ||
						data.lastPx
					) {
						if (position?.symbol === data.symbol) {
							if (setFieldPxToUpdate(data, position)) {
								setUpdatePxPending(true);
							}
						} else {
							console.log(
								'SUBSCRIBE: Callback for wrong symbol - Received: ' +
									data.symbol +
									' - Should be: ' +
									position?.symbol
							);
						}
					}
				}
			},
		};

		return quotationServiceFunc(props);
	};

	React.useEffect(() => {
		prevStreamingConnectionId.current = streamingConnectionId;
	}, [streamingConnectionId]);

	React.useEffect(() => {
		prevStreamingMbookConnectionId.current = streamingMBookConnectionId;
	}, [streamingMBookConnectionId]);

	const paperUnsubs = id => {
		const props = {
			action: 'unsubscribe',
			id: id,
		};
		quotationServiceFunc(props);
	};

	useEffect(() => {
		const subTradeId = paperSubscribe(position?.symbol, 'trade');
		const subMBookId = paperSubscribe(position?.symbol, 'minibook');
		setStreamingConnectionId(subTradeId);
		setStreamingMBookConnectionId(subMBookId);

		return () => {
			paperUnsubs(subTradeId);
			paperUnsubs(subMBookId);
		};
	}, []);

	const setFieldPxToUpdate = (data, position) => {
		setWorkingMktData_symbol(data.symbol);
		if (data.askPx != null) {
			if (position.qtty > 0) {
				if (workingMktData_entryPx !== data.askPx) {
					setWorkingMktData_entryPx(data.askPx);
					return true;
				}
			} else if (position.qtty < 0) {
				if (workingMktData_exitPx !== data.askPx) {
					setWorkingMktData_exitPx(data.askPx);
					return true;
				}
			}
		} else if (data.bidPx != null) {
			if (position.qtty > 0) {
				if (workingMktData_exitPx !== data.bidPx) {
					setWorkingMktData_exitPx(data.bidPx);
					return true;
				}
			} else if (position.qtty < 0) {
				if (workingMktData_entryPx !== data.bidPx) {
					setWorkingMktData_entryPx(data.bidPx);
					return true;
				}
			}
		} else if (data.price || data.lastPx) {
			if (workingMktData_tradePx !== (data.price || data.lastPx)) {
				setWorkingMktData_tradePx(data.price || data.lastPx);
				return true;
			}
		}
		return false;
	};

	return '';
}

export default PositionSubscriber;
