import { toast } from 'react-toastify';
import { format } from 'date-fns';

import { categories } from 'utils/categories';
import {
	getFirstLegFlexOrder,
	getParsedStrategyLegFields,
	getSubUid,
} from 'utils/strategies';
import { store } from 'store';

import {
	STRATEGY_HANDLE,
	STRATEGY_CLEAR,
	VISIBILITY_SET,
	STRATEGY_NAME,
	STRATEGY_UPDATE,
	REPLACE_CUSTOM_PARAMETERS,
	UPDATE_CUSTOM_PARAMETERS,
	UPDATE_STRATEGY_LEG,
	UPDATE_STRATEGY_INFO,
	REPLACE_STRATEGY_LEGS,
	ADDITIONAL_UPDATE,
	HANDLE_SAVED_ORDERS,
	HANDLE_RECENT_ORDERS,
	ORDER_ENTRY,
	UPDATE_STRATEGY_INFOS,
	UPDATE_STRATEGY_MARKET_PRICES,
	UPDATE_CONTENT,
	UPDATE_CONTEXT,
	LOADING_MARKET_PRICE,
	SET_IS_RECURRENCE_STATE,
	UPDATE_RECURRENCE_INFO,
} from './actionTypes';
import { getSubExchangeFromSecurityType } from '../../../utils/strategies';
import { updateStrategyInfosFromApi } from './actions';
import { getStrategyTemplate } from 'utils/templates';
import { NEW, EDT, SWP, CLN, REV } from 'utils/bulletContext';
import { handleRevertStrategy } from '../../../utils/handleRevertStrategy';
import { getParsedCustomParameters } from '../../../utils/strategies';
import { getParsedDate } from 'utils/getParsedDate';
import { addClipProportion } from 'utils/addClipProportion';

const spreadLeg = {
	ILegAllocAccount: '',
	LegQuantity: 0,
	LegSecurityExchange: 'XBSP',
	LegSide: '1',
	LegSymbol: '',
	LegResting: 'N',
	LegMaxClipSize: 0,
};

const initialState = {
	bullet: {
		category: '1',
		category_type: '',
		code: '',
		closeOnSubmit: true,
		tooltip: 'Single',
		visible: true,
		uid: 'sorder',
		subUid: 1,
		id: 0.4180047046265263,
		StrategyInfos: [{}],
		legs: [spreadLeg],
		signature: '',
		context: NEW,
		isRecurrence: false,
		recurrenceInfo: {
			ActiveStartDate: format(new Date(), 'yyyy-MM-dd'),
			ActiveEndType: 1,
			ActiveEndDate: null,
			FreqType: 2,
		},
		content: {
			// InitSuspended: 'N',
			...getStrategyTemplate('sorder'),
		},
	},
	marketPrices: 0.0,
	recentOrders: [],
	savedOrders: [],
	loadingMarketPrice: false,
};

const reducer = {
	[STRATEGY_HANDLE]: (stt, payload) => {
		const { strategy } = payload;
		const bulletState = {};
		bulletState.id = Math.random();
		bulletState.content = {};
		bulletState.clone = strategy.clone;
		bulletState.editing = strategy.editing;
		bulletState.revert = strategy.revert;
		bulletState.paramsView = strategy.paramsView;
		if (strategy.clone || strategy.editing || strategy.revert) {
			const hash = {
				CustomParameters: {},
				StrategyLegs: [],
			};

			if (strategy?.values?.StrategyLegs) {
				hash.StrategyLegs = strategy.values.StrategyLegs.map(leg => {
					return { ...getParsedStrategyLegFields(leg) };
				});
				bulletState.StrategyInfos = strategy.values.StrategyLegs.map(
					leg => {
						return { symbol: leg.LegSymbol };
					}
				);
			}

			if (strategy?.values?.CustomParameters) {
				hash.CustomParameters = { ...strategy.values.CustomParameters };
			}

			hash.StrategyCode = strategy.values.StrategyCode;
			hash.Name = strategy.values.Name;

			bulletState.isOpen = true;
			bulletState.signature = stt.bullet.signature;
			bulletState.keepSignature = stt.bullet.keepSignature;
			bulletState.subUid = getSubUid(hash);
			bulletState.isRecurrence = stt.bullet.isRecurrence;
			bulletState.recurrenceInfo = stt.bullet.recurrenceInfo;

			store.dispatch(
				updateStrategyInfosFromApi(bulletState.StrategyInfos)
			);

			//EDIT
			if (strategy.editing) {
				hash.ClOrdID = strategy.values.ClOrdID;
				bulletState.context = EDT;
				hash.closeOnSubmit = true;
				if (hash.StrategyCode === 'fxorder') {
					hash.StrategyLegs[0] =
						strategy.values.StrategyLegs[0].LegRefID[
							strategy.values.StrategyLegs[0].LegRefID.length - 1
						] === '1'
							? strategy.values.StrategyLegs[0]
							: strategy.values.StrategyLegs[1];

					hash.CustomParameters.stop = !(
						bulletState.subUid === 3 &&
						hash.CustomParameters.ReversionType === 4
					);

					if (hash.CustomParameters.StartPriceTrigger) {
						hash.CustomParameters.PriceLimit =
							hash.CustomParameters.StartPriceTrigger;
					}
				}
			} else {
				hash.StrategyLegs?.forEach(leg => {
					delete leg.LegRefID;
				});
			}

			hash.Name = strategy.values.Name;
			hash.BasketId = 'fxs';
			hash.EndTime = strategy.values.EndTime;
			hash.InitTime = strategy.values.InitTime;

			const dateFormat = strategy.values.ExpireDate.includes('-')
				? 'yyyy-MM-dd'
				: 'yyyyMMdd';

			const parsedDate = getParsedDate(strategy.values.ExpireDate);

			hash.ExpireDate = format(parsedDate, dateFormat);

			hash.TimeInForce = strategy.values.TimeInForce;

			// VIEW PARAMS
			if (strategy.paramsView) {
				if (
					strategy.values.recurrenceInfo &&
					strategy.values.recurrenceInfo.isRecurrence
				) {
					bulletState.isRecurrence = true;
					bulletState.recurrenceInfo = strategy.values.recurrenceInfo;
				}

				bulletState.context = SWP;
				bulletState.editing = false;
				if (hash.StrategyCode === 'fxorder') {
					hash.StrategyLegs = getFirstLegFlexOrder(hash.StrategyLegs);
				}
			}

			// CLONE
			if (strategy.clone) {
				if (
					strategy.values.recurrenceInfo &&
					strategy.values.recurrenceInfo.isRecurrence
				) {
					bulletState.isRecurrence = true;
					bulletState.recurrenceInfo =
						initialState.bullet.recurrenceInfo;
					hash.ExpireDate = format(new Date(), 'yyyy-MM-dd');
				}

				delete hash.ClOrdID;
				bulletState.context = CLN;
				bulletState.editing = false;
				hash.CustomParameters = getParsedCustomParameters(
					hash.CustomParameters
				);

				if (hash.StrategyCode === 'fxorder') {
					//saida
					if (hash.CustomParameters.CustomOrdType === 3) {
						hash.CustomParameters.PriceLimit =
							hash.CustomParameters.EntranceExecPrice;
					} else if (
						[2, 4].includes(hash.CustomParameters.CustomOrdType)
					) {
						// start e limit
						hash.CustomParameters.PriceLimit =
							hash.CustomParameters.StartPriceTrigger;
					} else if (hash.CustomParameters.CustomOrdType === 1) {
						delete hash.CustomParameters.StartPriceTrigger;
					}

					hash.StrategyLegs = getFirstLegFlexOrder(hash.StrategyLegs);
				} else if (hash.StrategyCode === 'cvvol') {
					hash.StrategyLegs = hash.StrategyLegs.map((leg, index) => {
						if (index === 1) {
							return {
								...leg,
								LegQuantity: 100,
								LegMaxClipSize: 100,
							};
						} else {
							return { ...leg };
						}
					});
				} else if (['grddin', 'grdlin'].includes(hash.StrategyCode)) {
					hash.CustomParameters.StartWhenCross =
						hash.CustomParameters.NumberOfMinPriceIncrementsToStart;
				}
			}

			bulletState.content = hash;

			//REVERT
			if (strategy.revert) {
				delete hash.ClOrdID;
				bulletState.editing = false;
				bulletState.context = REV;
				// strategy.StrategyInfos = [];
				// bulletState.StrategyInfos = [];
				hash.StrategyLegs?.forEach((el, index) => {
					hash.StrategyLegs[index].LegSide =
						el.LegSide === '1' ? '2' : '1';
					hash.StrategyLegs[index].LegQuantity = parseInt(
						el.LegExecQty,
						10
					);
				});

				bulletState.content = handleRevertStrategy(hash);
			}

			if (strategy.revert || strategy.clone) {
				if (strategy.values.TimeInForce === '0') {
					hash.ExpireDate = format(new Date(), 'yyyy-MM-dd');
				}

				if (strategy.values.TimeInForce === '6') {
					hash.ExpireDate = null;
				}

				// Workaround
				// Código feito para adicionar a porporção do clip máximo, baseado no campo legado "Cst_MinOrderQuantity"
				const formattedStrategyInfos =
					bulletState.content.StrategyLegs.map(leg => ({
						minOrderQty: Number(leg.Cst_MinOrderQuantity),
						symbol: leg.LegSymbol,
					}));

				addClipProportion(
					bulletState.content.StrategyLegs,
					formattedStrategyInfos
				);
			}

			bulletState.category = categories.filter(c =>
				(c.code === hash.StrategyCode) === 'fxorder'
					? 'sorder'
					: hash.StrategyCode
			)[0].id;
		} else {
			bulletState.content.Name =
				bulletState.tooltip.replace(/ /g, '') +
				new Date().toLocaleString().replace(/[^\da-z]/gi, '');
		}

		return { ...stt, bullet: bulletState };
	},

	[ORDER_ENTRY]: (stt, bullet) => {
		const { bullet: bullets, subUid } = bullet;
		const { StrategyCode, clean } = bullets;

		const strategyCodeParsed =
			StrategyCode === 'spread2p' ? 'spread' : StrategyCode;

		const strategyCodeToGetTemplate = strategyCodeParsed.includes('spread')
			? 'spread'
			: strategyCodeParsed;

		let timeInForceInfos = {};

		if (['RCT', 'SVD'].includes(bullets.context)) {
			timeInForceInfos = {
				TimeInForce: bullets.TimeInForce,
				ExpireDate: bullets.ExpireDate,
			};
		}

		let defaultBulletTemplate = {
			...getStrategyTemplate(strategyCodeToGetTemplate),
			...timeInForceInfos,
		};

		if (!defaultBulletTemplate) {
			toast.error('Código da estratégia não encontrado');
			return { bullet: { ...stt.bullet } };
		}

		//pegar apenas numeros
		const spreadNumLegs = +strategyCodeParsed.match(/\d+/);

		const numLegs =
			strategyCodeToGetTemplate === 'spread'
				? spreadNumLegs === 0
					? 2
					: spreadNumLegs
				: defaultBulletTemplate.StrategyLegs.length;

		// So existe um template para varias spreads, entao apenas acrescentamos mais legs
		if (strategyCodeToGetTemplate === 'spread' && numLegs > 2) {
			const tempIndex = defaultBulletTemplate.StrategyLegs.length;
			const lastLeg = defaultBulletTemplate.StrategyLegs[tempIndex - 1];
			for (let i = tempIndex; i < numLegs; i++) {
				defaultBulletTemplate.StrategyLegs.push({ ...lastLeg });
				defaultBulletTemplate.StrategyLegs[i].LegSide =
					i % 2 === 0 ? '1' : '2';
			}
		}

		const savedSignature = store.getState().bottomBullet.bullet
			.keepSignature
			? store.getState().bottomBullet.bullet.signature
			: '';
		const savedKeepSignature =
			store.getState().bottomBullet.bullet.keepSignature;
		const savedClose = store.getState().bottomBullet.bullet.closeOnSubmit;

		let bul;

		let strategyInfos = defaultBulletTemplate.StrategyLegs.map(() => {
			return {};
		});

		if (clean) {
			bul = {
				...defaultBulletTemplate,
				ExpireDate: format(new Date(), 'yyyy-MM-dd'),
				StrategyCode: strategyCodeParsed,
			};
		} else {
			if (bullets.StrategyInfos) {
				strategyInfos = bullets.StrategyInfos.map(info => {
					return {
						...info,
						subExchange:
							info.subExchange ??
							getSubExchangeFromSecurityType(info.securityType),
					};
				});
			}

			let customParameter = {};

			Object.keys(defaultBulletTemplate.CustomParameters).forEach(
				param => {
					customParameter[param] =
						bullets.CustomParameters[param] ??
						defaultBulletTemplate.CustomParameters[param];
				}
			);

			if (StrategyCode === 'skew') {
				customParameter.DeltaFixedValue1 =
					bullets.CustomParameters.DeltaFixedValue1;
				customParameter.DeltaFixedValue2 =
					bullets.CustomParameters.DeltaFixedValue2;
				customParameter.DaysToExpiration1 =
					bullets.CustomParameters.DaysToExpiration1;
				customParameter.DaysToExpiration2 =
					bullets.CustomParameters.DaysToExpiration2;
			}

			let newExpireDate = format(new Date(), 'yyyy-MM-dd');

			if (bullets.ExpireDate && bullets.ExpireDate !== '20191801') {
				newExpireDate = bullets.ExpireDate;
			}

			bul = {
				...defaultBulletTemplate,
				Name: defaultBulletTemplate.Name,
				BasketId: bullets.BasketId ?? '',
				EndTime: bullets.EndTime ?? defaultBulletTemplate.EndTime,
				InitTime: bullets.InitTime ?? defaultBulletTemplate.InitTime,
				StrategyCode: strategyCodeParsed,
				ExpireDate: newExpireDate,
				Text: bullets.Text ?? '',
				CustomParameters: { ...customParameter },
				StrategyLegs: defaultBulletTemplate.StrategyLegs.map(
					(leg, legIndex) => {
						if (bullets.StrategyLegs[legIndex].LegSymbol) {
							let legFinal = {};
							Object.keys(leg).forEach(param => {
								legFinal[param] =
									bullets.StrategyLegs[legIndex][param] ??
									defaultBulletTemplate.StrategyLegs[
										legIndex
									][param];
							});
							legFinal.LegSymbol =
								legFinal.LegSymbol.toUpperCase();
							return legFinal;
						}
					}
				),
			};
		}

		return {
			...stt,
			bullet: {
				uid: strategyCodeParsed,
				subUid,
				legs: numLegs,
				visible: true,
				clone: !clean,
				content: bul,
				StrategyInfos: strategyInfos,
				signature: savedSignature,
				keepSignature: savedKeepSignature,
				closeOnSubmit: savedClose,
				context: bullets.context,
				account: store.getState().bottomBullet.bullet.account,
			},
		};
	},

	[STRATEGY_CLEAR]: stt => {
		return { ...stt, bullet: {} };
	},
	[VISIBILITY_SET]: (stt, payload) => {
		const { visibility } = payload;
		return { ...stt, bullet: { ...stt.bullet, visible: visibility } };
	},

	[STRATEGY_NAME]: (stt, payload) => {
		const { value } = payload;
		const newBullets = { ...stt.bullet, ...{ Name: value } };

		return { ...stt, bullet: newBullets };
	},

	[STRATEGY_UPDATE]: (stt, payload) => {
		const { content } = payload;
		const newBullets = {
			...stt.bullet,
			content: {
				...stt.bullet.content,
				...content,
				CustomParameters: {
					...stt.bullet.content.CustomParameters,
					...content.CustomParameters,
				},
				StrategyLegs: content.StrategyLegs.map((leg, index) => {
					return {
						...stt.bullet.content.StrategyLegs[index],
						...leg,
					};
				}),
			},
		};
		return { ...stt, bullet: newBullets };
	},

	[REPLACE_CUSTOM_PARAMETERS]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				content: {
					...stt.bullet.content,
					CustomParameters: { ...payload },
				},
			},
		};
	},

	[UPDATE_CUSTOM_PARAMETERS]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				content: {
					...stt.bullet.content,
					CustomParameters: {
						...stt.bullet.content.CustomParameters,
						...payload,
					},
				},
			},
		};
	},

	[UPDATE_STRATEGY_LEG]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				content: {
					...stt.bullet.content,
					StrategyLegs: stt.bullet.content.StrategyLegs.map(
						(leg, legIndex) => {
							if (legIndex === payload.legIndex) {
								return {
									...leg,
									...payload.legParams,
								};
							} else {
								return {
									...leg,
								};
							}
						}
					),
				},
			},
		};
	},

	[UPDATE_CONTENT]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				content: { ...stt.bullet.content, ...payload },
			},
		};
	},

	[UPDATE_CONTEXT]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				context: payload,
			},
		};
	},

	[UPDATE_STRATEGY_MARKET_PRICES]: (stt, payload) => {
		return {
			...stt,
			...payload,
		};
	},

	[UPDATE_STRATEGY_INFOS]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				StrategyInfos: stt.bullet.StrategyInfos.map(
					(info, infoIndex) => {
						if (infoIndex === payload.infoIndex) {
							return {
								...payload.infoParams,
							};
						} else {
							return {
								...info,
							};
						}
					}
				),
			},
		};
	},

	[UPDATE_STRATEGY_INFO]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				StrategyInfos: stt.bullet.StrategyInfos.map(
					(info, infoIndex) => {
						if (infoIndex === payload.infoIndex) {
							return {
								...info,
								...payload.infoParams,
							};
						} else {
							return {
								...info,
							};
						}
					}
				),
			},
		};
	},

	[REPLACE_STRATEGY_LEGS]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				content: {
					...stt.bullet.content,
					StrategyLegs: payload.map(leg => {
						return {
							...leg,
						};
					}),
				},
			},
		};
	},

	[ADDITIONAL_UPDATE]: (stt, payload) => {
		const { key, value } = payload;
		return { ...stt, bullet: { ...stt.bullet, [key]: value } };
	},

	[HANDLE_SAVED_ORDERS]: (stt, payload) => {
		return { ...stt, ...payload };
	},

	[HANDLE_RECENT_ORDERS]: (stt, payload) => {
		return { ...stt, ...payload };
	},
	[LOADING_MARKET_PRICE]: (stt, { isLoading }) => {
		return { ...stt, loadingMarketPrice: isLoading };
	},
	[SET_IS_RECURRENCE_STATE]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				isRecurrence: payload.isRecurrence,
				recurrenceInfo: initialState.bullet.recurrenceInfo,
			},
		};
	},
	[UPDATE_RECURRENCE_INFO]: (stt, payload) => {
		return {
			...stt,
			bullet: {
				...stt.bullet,
				recurrenceInfo: {
					...stt.bullet.recurrenceInfo,
					...payload.newInfo,
				},
			},
		};
	},
};

export default function strategyFloatingReducer(
	state = initialState,
	{ type, payload }
) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
}
