import React from 'react';

import {
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

export const CurrentPositionsToolbar = (apiRef, profile) => {
	const isButtonsDisabled =
		apiRef.current.getAllRowIds().length === 0 || profile === 4;

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton
				color="inherit"
				disabled={profile === 4}
			/>

			<GridToolbarFilterButton
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarDensitySelector
				color="inherit"
				disabled={isButtonsDisabled}
			/>

			<GridToolbarExport color="inherit" disabled={isButtonsDisabled} />
		</GridToolbarContainer>
	);
};
