export const PANEL_CONFIG = {
	TABLES_SET: 'CONFIG_TABLES::SET',
	CONFIG_SET: 'CONFIG::SET',
	REQUEST_SET: 'PANEL_CONFIG_REQUEST::SET',
	PANEL_CONFIG_RESET: 'PANEL_CONFIG::RESET_SET',
	SET_EXPANDED: 'PANEL_CONFIG::SET_EXPANDED',
	EDIT_TARGET: 'PANEL_CONFIG::EDIT_TARGET',
	SET_RESIZE_HEADER: 'PANEL_CONFIG::SET_RESIZE_HEADER',
	SET_IS_GLOBAL_SETTINGS_OPEN: 'CONFIG::SET_IS_GLOBAL_SETTINGS_OPEN',
	SET_GLOBAL_SETTINGS_MODULE: 'CONFIG::SET_GLOBAL_SETTINGS_MODULE',
	SET_GLOBAL_SETTINGS: 'CONFIG::SET_GLOBAL_SETTINGS',
	SET_PARTIAL_GLOBAL_SETTING: 'CONFIG::SET_PARTIAL_GLOBAL_SETTING',
	RESET_CHANGES: 'CONFIG::RESET_CHANGES',
	SET_STRATEGIES_TAB_INDEX: 'CONFIG::SET_STRATEGIES_TAB_INDEX',
	SET_HOLIDAYS: 'CONFIG::SET_HOLIDAYS',
	SET_POSITIONS_TAB_INDEX: 'CONFIG::SET_POSITIONS_TAB_INDEX',
};
