import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';
import { differenceInDays, isAfter, isBefore, isValid, parse, sub } from 'date-fns';

import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DateRangePicker } from '@mui/x-date-pickers-pro';

import { orderStatus } from 'utils/strategies';
import { removeDuplicatesItem } from 'utils/removeDuplicatesItem';
import { getDayPicker } from 'utils/getDayPicker';
import {
	clearFormValues,
	updateFormValues,
} from 'store/modules/history/actions';

import { StrategyCodeSelect } from '../StrategyCodeSelect';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

export const HistorySearchForm = ({
	isLoading = false,
	searchForm,
	handleSearch,
	paginationInfo,
}) => {
	const dispatch = useDispatch();

	const { accounts, userType, profile } = useSelector(state => state.auth);

	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'warning',
	});

	const userAccounts = removeDuplicatesItem(accounts, 'code');

	const formattedAccounts =
		userAccounts.length === 1
			? userAccounts
			: [{ code: 'Todos' }, ...userAccounts];

	const yesterday = sub(new Date(), { days: 1 });

	function handleChangeDatePicker(rangeDate) {
		const [startDate, endDate] = rangeDate;

		if (startDate) {
			const isStartDateValid = isBefore(startDate, parse('2023-08-28', 'yyyy-MM-dd', new Date()));

			if (isStartDateValid) {
				setSnackbarState({
					isOpen: true,
					message:
						'Os dados de histórico só estão disponíveis a partir da data 28/08/2023',
				});
				return;
			}
		}

		if (startDate && endDate) {
			const daysDifference = differenceInDays(endDate, startDate);

			if (daysDifference > 15) {
				setSnackbarState({
					...snackbarState,
					type: 'warning',
					message:
						'As datas devem estar no maximo em um range de 15 dias!',
					isOpen: true,
					duration: 5000,
				});

				return;
			} else {
				dispatch(
					updateFormValues('transect_date', [startDate, endDate])
				);
			}
		}
	}

	const validateSearchForm = searchForm => {
		if (searchForm.transect_date.length === 0) {
			setSnackbarState({
				...snackbarState,
				type: 'warning',
				message: 'Selecione o range de datas!',
				isOpen: true,
				duration: 5000,
			});

			return;
		}

		const [startDate, endDate] = searchForm.transect_date;

		if (!isValid(startDate)) {
			setSnackbarState({
				...snackbarState,
				type: 'warning',
				message: 'A data de início precisa ser uma data válida!',
				isOpen: true,
				duration: 5000,
			});

			return;
		}

		if (!isValid(endDate)) {
			setSnackbarState({
				...snackbarState,
				type: 'warning',
				message: 'A data de fim precisa ser uma data válida!',
				isOpen: true,
				duration: 5000,
			});

			return;
		}

		if (isAfter(startDate, yesterday)) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'A data de início não pode ser uma data futuro!',
			});
			return;
		}

		if (isAfter(endDate, yesterday)) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'A data fim não pode ser uma data futura ou presente!',
			});
			return;
		}

		if (isAfter(startDate, endDate)) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'A data de início não pode ser maior que a data fim!',
			});
			return;
		}

		return true;
	};

	function handleChangeSearchFormValues(field, value) {
		dispatch(updateFormValues(field, value));
	}

	function handleClear() {
		dispatch(clearFormValues());
	}

	function handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		if (validateSearchForm(searchForm)) {
			handleSearch(
				{
					...paginationInfo,
					page: 0,
					offset: 0,
					total: null,
				},
				searchForm,
				event
			);
		}
	}

	return (
		<>
			<form
				onSubmit={handleSubmit}
				style={{ width: '100%', padding: '0 1rem' }}
			>
				<Grid xs={12} item container spacing={1}>
					<Grid item xs={3}>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={ptBR}
							localeText={{
								start: 'Data início',
								end: 'Data Fim',
							}}
						>
							<DateRangePicker
								value={searchForm.transect_date ?? [yesterday, yesterday]}
								onChange={newValue =>
									handleChangeDatePicker(newValue)
								}
								renderInput={(startProps, endProps) => (
									<>
										<TextField
											size="small"
											sx={{ marginRight: '10px' }}
											fullWidth
											{...startProps}
										/>
										<TextField
											size="small"
											fullWidth
											{...endProps}
										/>
									</>
								)}
								maxDate={yesterday}
								dayOfWeekFormatter={day =>
									`${getDayPicker(day)}`
								}
							/>
						</LocalizationProvider>
					</Grid>

					<Grid item xs={1.5}>
						<TextField
							id="name"
							name="name"
							label="Nome"
							fullWidth
							size="small"
							value={searchForm.name}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
						/>
					</Grid>

					<Grid item xs={1}>
						{[2, 5].includes(userType.id) ? (
							<TextField
								id="account"
								name="account"
								label="Conta"
								size="small"
								fullWidth
								value={
									searchForm.account === 'all'
										? ''
										: searchForm.account
								}
								onChange={event =>
									handleChangeSearchFormValues(
										event.target.name,
										event.target.value
									)
								}
							/>
						) : (
							<TextField
								id="account"
								name="account"
								labelId="account-label"
								label="Conta (CBLC)"
								SelectProps={{ native: 'native' }}
								defaultValue={
									userAccounts.length === 1
										? userAccounts[0]
										: 'all'
								}
								fullWidth
								size="small"
								value={searchForm.account}
								InputLabelProps={{ shrink: true }}
								onChange={event =>
									handleChangeSearchFormValues(
										event.target.name,
										event.target.value
									)
								}
								select
							>
								{formattedAccounts.map(account => (
									<>
										<option
											key={account.code}
											value={account.code}
										>
											{account.code}
										</option>
									</>
								))}
							</TextField>
						)}
					</Grid>

					<Grid item xs={1.5}>
						<TextField
							label="Status"
							id="order_status"
							name="order_status"
							size="small"
							defaultValue="all"
							value={searchForm.order_status}
							SelectProps={{ native: 'native' }}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
							fullWidth
							select
						>
							{orderStatus.map(status => (
								<option key={status.value} value={status.value}>
									{status.label}
								</option>
							))}
						</TextField>
					</Grid>

					<Grid item xs={1}>
						<StrategyCodeSelect
							label="Tipo"
							value={searchForm.strategycode}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
						/>
					</Grid>

					<Grid item xs={1}>
						<TextField
							id="ClOrdID"
							name="ClOrdID"
							label="ClOrdID"
							size="small"
							fullWidth
							value={searchForm.ClOrdID}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
						/>
					</Grid>

					<Grid item xs={2}>
						<LoadingButton
							fullWidth
							type="submit"
							loading={isLoading}
							variant="contained"
							startIcon={<Search />}
							sx={{ height: '100%' }}
							disabled={profile === 4}
						>
							Procurar
						</LoadingButton>
					</Grid>

					<Grid item xs={1}>
						<Button
							fullWidth
							variant="contained"
							onClick={handleClear}
							sx={{
								height: '100%',
								bgcolor: '#fff',
								color: '#2196f3',
								'&:hover': {
									bgcolor: '#e7e7e7',
									color: '#1069b3',
								},
							}}
						>
							Limpar
						</Button>
					</Grid>
				</Grid>
			</form>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'warning'}
				handleClose={() =>
					setSnackbarState({
						isOpen: false,
						message: '',
					})
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</>
	);
};
