import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { additionalUpdate, replaceCustomParameters, strategyUpdate } from 'store/modules/bottomBullet/actions';
import MaterialCheckBox from '../MaterialCheckBox';
import SelectInput from '../SelectInput';
import MaterialTriggerSwitch from '../MaterialTriggerSwitch';
import DecimalInput from '../DecimalInput';
import { updateCustomParameters, updateStrategyLeg } from 'store/modules/bottomBullet/actions';

import Stop from './Stop';
import paperValidator from 'utils/paperValidation';
import { useTranslation } from 'react-i18next';
import IconWithTooltip from 'components/MaterialComponents/IconTooltip';
import { getStrategyTemplate } from 'utils/templates';

const Exit = ({ handleChange, subUid, stepFunction }) => {
	const [triggerCurrency, setTriggerCurrency] = useState('%');
	const triggerStep = 0.01;
	const { bullet } = useSelector(state => state.bottomBullet);
	const dispatch = useDispatch();
	const { t } = useTranslation();

	function changeTrigger() {
		const { TriggerEntryType } = bullet.content.CustomParameters;

		const triggerPercent = TriggerEntryType === 2;

		setTriggerCurrency(triggerPercent ? '%' : 'Pts');

		dispatch(
			updateCustomParameters({
				TriggerEntryType: triggerPercent ? 1 : 2,
				StopLoss: 0.0,
				StopGain: 0.0,
				StopGainL1: 0.0,
				StopGainL2: 0.0,
				StopGainL3: 0.0,
			}),
		);
	}

	function changeType(selected) {
		const defaultTemplate = getStrategyTemplate(bullet.content.CustomParameters.stop ? 'fxorder' : 'sorder');
		// setTriggerSelect(selected);
		const price = bullet.content.CustomParameters.PriceLimit;
		const qty = bullet.content.CustomParameters.OrderQuantity;
		const ordType = bullet.content.CustomParameters.CustomOrdType;
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));
		dispatch(
			updateCustomParameters({
				PriceLimit: price,
				StopType: selected,
				OrderQuantity: qty,
				CustomOrdType: ordType,
			}),
		);
	}

	const setReversionType = shouldRevert => {
		dispatch(updateCustomParameters({ ReversionType: shouldRevert ? 1 : 3 }));
	};

	const handleStop = () => {
		const price = bullet.content.CustomParameters.PriceLimit;
		const useAuction = bullet.content.CustomParameters.UseAuctionOpenPrice;
		const qty = bullet.content.CustomParameters.OrderQuantity;
		if (bullet.uid === 'fxorder') {
			dispatch(additionalUpdate('uid', 'sorder'));
			const hash = {...bullet.content};
			hash.StrategyCode = 'sorder';
			dispatch(strategyUpdate(hash));
		}
		const defaultTemplate = getStrategyTemplate(bullet.content.CustomParameters.stop ? 'sorder' : 'fxorder');
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));
		dispatch(updateCustomParameters({ PriceLimit: price }));
		if (subUid === 1) {
			dispatch(updateCustomParameters({ CustomOrdType: 4 }));
		}
		if (!bullet.content.CustomParameters.stop) {
			dispatch(
				updateCustomParameters({
					OrderQuantity: bullet.content.StrategyLegs[0].LegQuantity,
				}),
			);
		} else {
			dispatch(updateStrategyLeg({ LegQuantity: qty }, 0));
		}
		if (subUid === 5) {
			dispatch(
				updateCustomParameters({
					UseAuctionOpenPrice: useAuction,
					OrderQuantity: qty,
				}),
			);
		}
		
	};

	function valorDeVenda(value) {
		if (value) {
			value = +value.toFixed(bullet.StrategyInfos[0].tickSizeDenominator);
		}
		return value;
	}
	return (
		<>
			{subUid === 1 || subUid === 2 || subUid === 3 || subUid === 4 || subUid === 5 ? (
				<Grid item xs={6}>
					<Grid container spacing={1} direction="row">
						<Grid item xs={12} style={{ marginLeft: '-3px' }}>
							<Typography variant="subtitle1">
								{subUid === 4 ? (
									<Typography color="primary" style={{ marginBottom: '3px' }}>
										<strong>
											Objetivo: Tentar {bullet.content.StrategyLegs[0].LegSide === '2' ? 'Vender' : 'Comprar'}{' '}
											{valorDeVenda(bullet.content.StrategyLegs[0].LegQuantity || bullet.content.CustomParameters.OrderQuantity)} de{' '}
											{bullet.content.StrategyLegs[0].LegSymbol}
										</strong>
									</Typography>
								) : (
									'Saída'
								)}
							</Typography>
						</Grid>
						{subUid !== 4 && (
							<Grid item xs={2}>
								<Box display="flex" alignItems="center" ml={-1.2} mt={-0.2}>
									<MaterialCheckBox
										name="stopBox"
										label="Habilitar Stop"
										onChange={handleStop}
										checked={bullet.content.CustomParameters.stop ?? false}
										disabled={
											paperValidator(bullet.StrategyInfos[0], bullet, 'marketQty') ||
											bullet.content.CustomParameters.CustomOrdType === 3 ||
											bullet.content.ClOrdID
										}
									/>
									<Box ml={0.5} mt={0.5}>
										<IconWithTooltip sizeFont="12px" title={t('bullets.single.exit_single')} />
									</Box>
								</Box>
							</Grid>
						)}
						<Grid item xs={2} style={{ marginLeft: '-6px' }}>
							<SelectInput
								name="triggerSelect"
								iconTooltip={t('bullets.single.trigger_type')}
								disabled={paperValidator(bullet.StrategyInfos[0], bullet) || !bullet.content.CustomParameters.stop || bullet.content.ClOrdID}
								label="Tipo de gatilho"
								onChange={e => changeType(parseInt(e.target.value))}
								defaultValue={1}
								value={bullet.content.CustomParameters.StopType ?? 1}
								selectOptions={[
									{
										value: 1,
										label: 'Gain/Loss',
									},
									{
										value: 3,
										label: 'Parcial',
									},
									{
										value: 2,
										label: 'Móvel',
									},
								]}
							/>
						</Grid>
						<Grid item xs={2} style={{ marginLeft: '2px' }}>
							<Box justifyContent="center" display="flex">
								<MaterialTriggerSwitch
									namePts="ptsButton"
									namePerc="percButton"
									disabled={paperValidator(bullet.StrategyInfos[0], bullet) || !bullet.content.CustomParameters.stop || bullet.content.ClOrdID}
									isEdit={bullet.content.ClOrdID}
									triggerType={bullet.content.CustomParameters.TriggerEntryType ?? 2}
									changeTrigger={changeTrigger}
									isSingle
								/>
							</Box>
						</Grid>
						{bullet.content.CustomParameters.StopType == 1 && (
							<Grid item xs={2}>
								<DecimalInput
									name="gainField"
									iconTooltip={t('bullets.general.stop_gain')}
									disabled={paperValidator(bullet.StrategyInfos[0], bullet) || !bullet.content.CustomParameters.stop}
									label="Stop Gain"
									minVal={0}
									maxVal={999999}
									minDecScale={2} // casas decimais
									stepFunction={stepFunction}
									stepType="Custom" // type
									stepSubType="StopGain" // subtype
									stepSize={
										bullet.content.CustomParameters.TriggerEntryType === 1
											? bullet.StrategyInfos[0].minPriceIncrement || bullet.StrategyInfos[0].Cst_MinPriceIncrement
											: triggerStep
									} // incremento
									valueToStep={
										isNaN(bullet.content.CustomParameters.StopGain) || bullet.content.CustomParameters.StopGain === undefined
											? 0
											: bullet.content.CustomParameters.StopGain
									} // valor a ser modificado
									value={bullet.content.CustomParameters.StopGain ?? 0}
									onChange={e => handleChange('Custom', 'StopGain', parseFloat(e.value), 0)}
								/>
							</Grid>
						)}
						<Grid item xs={2} style={{ marginLeft: '2px' }}>
							<DecimalInput
								name="lossField"
								iconTooltip={t('bullets.general.stop_loss')}
								disabled={paperValidator(bullet.StrategyInfos[0], bullet) || !bullet.content.CustomParameters.stop}
								label="Stop Loss"
								minVal={0}
								maxVal={999999}
								minDecScale={2} // casas decimais
								stepFunction={stepFunction}
								stepType="Custom" // type
								stepSubType="StopLoss" // subtype
								stepSize={
									bullet.content.CustomParameters.TriggerEntryType === 1
										? bullet.StrategyInfos[0].minPriceIncrement || bullet.StrategyInfos[0].Cst_MinPriceIncrement
										: triggerStep
								} // incremento
								valueToStep={
									isNaN(bullet.content.CustomParameters.StopLoss) || bullet.content.CustomParameters.StopLoss === undefined
										? 0
										: bullet.content.CustomParameters.StopLoss
								} // valor a ser modificado
								value={bullet.content.CustomParameters.StopLoss ?? 0}
								onChange={e => handleChange('Custom', 'StopLoss', parseFloat(e.value), 0)}
							/>
						</Grid>
						<Grid item xs={2}>
							<Box display="flex" alignItems="center" width="80px" ml={-0.4} mt={-0.5}>
								<MaterialCheckBox
									name="finalHour"
									label="Zerar na hora fim"
									disabled={
										paperValidator(bullet.StrategyInfos[0], bullet) || !bullet.content.CustomParameters.stop || bullet.content.ClOrdID
									}
									onChange={e => setReversionType(e.target.checked)}
									checked={bullet.content.CustomParameters.ReversionType === 1}
									smallLabel
								/>
								<Box ml={0.3}>
									<IconWithTooltip sizeFont="12px" title={t('bullets.single.reversion_type')} />
								</Box>
							</Box>
						</Grid>
						<Stop
							enableStop={!bullet.content.CustomParameters.stop}
							triggerStep={triggerStep}
							handleChange={handleChange}
							stepFunction={stepFunction}
							triggerCurrency={triggerCurrency}
						/>
					</Grid>
				</Grid>
			) : (
				''
			)}
		</>
	);
};

export default Exit;
