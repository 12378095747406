import React from 'react';
import moment from 'moment';

import { Box, Typography } from '@mui/material';
import { Explicit, LockOutlined } from '@mui/icons-material';

import { store } from 'store';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { handleSimulateStrategy } from './handleSimulateStrategy';

import { LightTooltip } from 'components/LightTooltip';
import { OutlinedLabel } from 'components/OutlinedLabel';

const GreeksHeader = label => {
	const greekType =
		store.getState().configs.globalSettings.userSettings.simulator
			.greekType;

	const greeksTooltipLabel = {
		exit: 'Gregas de saída',
		entry: 'Gregas de entrada',
		theoretical: 'Gregas teóricas',
	};

	return (
		<LightTooltip title={greeksTooltipLabel[greekType]}>
			<Typography variant="subtitle1" fontWeight="bold">
				{label}
			</Typography>
		</LightTooltip>
	);
};

const ExitPriceHeader = () => {
	const exitPriceType =
		store.getState().configs.globalSettings.userSettings.simulator
			.exitPrice;

	const exitPriceLabel = {
		last: 'Preço do últ. negócio',
		medium: 'Preço médio',
		market: 'Preço de mercado',
	};

	return (
		<LightTooltip title={exitPriceLabel[exitPriceType]}>
			<Typography variant="subtitle1" fontWeight="bold">
				Preço saída
			</Typography>
		</LightTooltip>
	);
};

const PriceCell = ({ priceValue, isPriceLocked }) => {
	return (
		<>
			{getFormatedNumber(priceValue, 4)}

			<LockOutlined
				sx={{
					ml: 0.5,
					color: isPriceLocked ? '#ff7777' : 'transparent',
				}}
			/>
		</>
	);
};

function getFormatedNumber(number, minDigits = null, maxDigits = null) {
	if (!number || isNaN(number)) {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits ?? minDigits,
	}).format(number);
}

function getPriceFormatting(params, isExit = false) {
	let template = {
		class: '',
		label: '-',
	};

	if (
		isNaN(params.value) ||
		typeof params.value !== 'number' ||
		params.value === null ||
		params.value === undefined
	) {
		return template;
	}

	if (params.row.hierarchy.length > 1 && params.value === 0) {
		if (params.row.expired) {
			// Quando expirado, 0 é um valor válido.
			return {
				class: '',
				label: '0,00',
			};
		}

		return template;
	}

	if (
		params.row.hierarchy.length === 1 &&
		params.value === 0 &&
		params.row.positions.every(position => position.expired)
	) {
		return template;
	}

	if (params.row.hierarchy.length === 1) {
		if (params.value === 0) {
			return template;
		} else if (params.value > 0) {
			template = {
				...template,
				class: 'colored-positive',
				label: `Cr ${getFormatedNumber(Math.abs(params.value), 2, 3)}`,
			};
		} else {
			template = {
				...template,
				class: 'colored-negative',
				label: `Db ${getFormatedNumber(Math.abs(params.value), 2, 3)}`,
			};
		}
	} else {
		const quantityValidation = isExit
			? params.row.qtty > 0
			: params.row.qtty < 0;

		if (quantityValidation) {
			template = {
				...template,
				class: 'colored-positive',
				label: `Cr ${getFormatedNumber(Math.abs(params.value), 2)}`,
			};
		} else {
			template = {
				...template,
				class: 'colored-negative',
				label: `Db ${getFormatedNumber(Math.abs(params.value), 2)}`,
			};
		}
	}

	return template;
}

function getOptionClass(optionClass) {
	if (['EUROPEAN', 'AMERICAN'].includes(optionClass)) {
		return optionClass.slice(0, 3);
	}

	return '';
}

export const columns = [
	{
		flex: 1.5,
		field: 'name',
		headerName: 'Nome',
		type: 'string',
		renderCell: params => (
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					gap: 1,
				}}
			>
				{params.row.itm && (
					<OutlinedLabel
						tooltip="Strike dentro do dinheiro"
						label="ITM"
						color="#ff7777"
						fontWeight="bold"
					/>
				)}

				{params.row.hierarchy.length > 1 && params.row.expired && (
					<LightTooltip arrow title="Contrato expirado">
						<Explicit
							sx={{
								color: 'orange',
								'&:hover': {
									cursor: 'pointer',
								},
							}}
						/>
					</LightTooltip>
				)}

				{params.row.hierarchy.length === 1 && (
					<Typography
						variant="body1"
						onClick={() => handleSimulateStrategy(params.row)}
						className="clickable-text"
					>
						{params.row.name}
					</Typography>
				)}
			</Box>
		),
	},
	{
		flex: 1,
		field: 'account',
		headerName: 'Conta',
		type: 'string',
		filterable: false,
	},
	{
		flex: 1,
		field: 'underSymbol',
		headerName: 'Underlying',
		type: 'string',
		maxWidth: 100,
		cellClassName: params =>
			params.row.hierarchy.length === 1 ? 'paper-cell' : '',
		renderCell: params =>
			params.row.hierarchy.length === 1 && params.row.underSymbol,
	},
	{
		flex: 1,
		field: 'qtty',
		headerName: 'Qtde.',
		type: 'number',
		maxWidth: 125,
		filterable: false,
	},
	{
		flex: 1,
		field: 'paper',
		headerName: 'Papel',
		type: 'string',
		filterable: false,
		minWidth: 175,
		renderCell: params =>
			params.row.hierarchy.length > 1 &&
			(params.row.expired ? (
				<Typography variant="body1">{params.row.paper}</Typography>
			) : (
				<>
					<Typography
						variant="body1"
						className="clickable-text"
						onClick={() => addWidgetFromButton(params.row.symbol)}
					>
						{params.row.paper}
					</Typography>
				</>
			)),
	},
	{
		flex: 1,
		field: 'entryPx',
		headerName: 'Preço entrada',
		type: 'number',
		filterable: false,
		cellClassName: params =>
			params.row.hierarchy.length === 1 &&
			getPriceFormatting(params).class,
		renderCell: params =>
			params.row.hierarchy.length === 1 ? (
				getPriceFormatting(params).label
			) : (
				<PriceCell
					priceValue={params.row.entryPx}
					isPriceLocked={params.row.entryPxLocked}
				/>
			),
	},
	{
		flex: 1,
		field: 'exitPx',
		headerName: 'Preço saída',
		type: 'number',
		filterable: false,
		renderHeader: ExitPriceHeader,
		cellClassName: params =>
			params.row.hierarchy.length === 1 &&
			getPriceFormatting(params).class,
		renderCell: params =>
			params.row.hierarchy.length === 1 ? (
				getPriceFormatting(params).label
			) : (
				<PriceCell
					priceValue={params.row.exitPx}
					isPriceLocked={params.row.exitPxLocked}
				/>
			),
	},
	{
		flex: 1,
		field: 'strikePx',
		headerName: 'Strike',
		type: 'number',
		filterable: false,
		renderCell: params =>
			params.row.securityType === 'OPT'
				? typeof params.row.strikePx === 'number'
					? getFormatedNumber(params.row.strikePx, 2)
					: params.row.strikePx
				: '',
	},
	{
		flex: 1,
		field: 'expirationDate',
		headerName: 'Vencimento',
		type: 'date',
		renderCell: params => {
			const formatedExpirationDate = moment(
				params.row.expirationDate
			).format('DD/MM/YYYY');

			const finalExpirationDate = [
				'Invalid date',
				'Data inválida',
			].includes(formatedExpirationDate)
				? params.row.expirationDate
				: formatedExpirationDate;

			const validLegExpirationDate =
				params.row.expirationDate && params.row.securityType === 'OPT'
					? finalExpirationDate
					: '';

			return (
				<>
					{params.row.hierarchy.length === 1
						? finalExpirationDate
						: validLegExpirationDate}
				</>
			);
		},
	},
	{
		flex: 1,
		field: 'optionClass',
		headerName: 'AME/EUR',
		type: 'string',
		filterable: false,
		renderCell: params => getOptionClass(params.row.optionClass),
	},
	{
		flex: 1,
		field: 'createdAt',
		headerName: 'Criação',
		type: 'date',
		filterable: false,
		renderCell: params =>
			moment(params.row.createdAt).format('DD/MM/YYYY HH:mm:ss'),
	},
	{
		flex: 1,
		field: 'updatedAt',
		headerName: 'Atualização',
		type: 'date',
		filterable: false,
		renderCell: params =>
			moment(params.row.updatedAt).format('DD/MM/YYYY HH:mm:ss'),
	},
	{
		flex: 1,
		field: 'cost',
		headerName: 'Custo entrada',
		type: 'number',
		filterable: false,
		cellClassName: params => getPriceFormatting(params).class,
		renderCell: params => getPriceFormatting(params).label,
	},
	{
		flex: 1,
		field: 'exitCost',
		type: 'number',
		headerName: 'Custo saída',
		filterable: false,
		cellClassName: params => getPriceFormatting(params, true).class,
		renderCell: params => getPriceFormatting(params, true).label,
	},
	{
		flex: 1,
		field: 'pl',
		headerName: 'P/L',
		type: 'number',
		filterable: false,
		cellClassName: params =>
			params.value > 0 ? 'colored-positive' : 'colored-negative',
		renderCell: params =>
			params.row.pl ? getFormatedNumber(params.row.pl, 2) : params.row.pl,
	},
	{
		flex: 1,
		field: 'pop',
		headerName: 'PoP',
		type: 'string',
		filterable: false,
		renderHeader: () => (
			<LightTooltip title="Probabilidade de lucro">
				<Typography variant="subtitle1" fontWeight="bold">
					PoP
				</Typography>
			</LightTooltip>
		),
		renderCell: params =>
			['0,00%'].includes(params.value) ? '-' : params.value,
	},
	{
		flex: 1,
		field: 'delta',
		headerName: 'Delta',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Delta'),
		renderCell: params => getFormatedNumber(params.row.delta, 2),
	},
	{
		flex: 1,
		field: 'gamma',
		headerName: 'Gamma',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Gamma'),
		renderCell: params => getFormatedNumber(params.row.gamma, 2),
	},
	{
		flex: 1,
		field: 'theta',
		headerName: 'Theta',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Theta'),
		renderCell: params => getFormatedNumber(params.row.theta, 2),
	},
	{
		flex: 1,
		field: 'vega',
		headerName: 'Vega',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Vega'),
		renderCell: params => getFormatedNumber(params.row.vega, 2),
	},
	{
		flex: 1,
		field: 'deltaUnitary',
		headerName: 'Delta unitário',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Delta unitário'),
		renderCell: params =>
			params.row.hierarchy.length > 1 &&
			getFormatedNumber(params.row.deltaUnitary, 4),
	},
	{
		flex: 1,
		field: 'gammaUnitary',
		headerName: 'Gamma unitário',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Gamma unitário'),
		renderCell: params =>
			params.row.hierarchy.length > 1 &&
			getFormatedNumber(params.row.gammaUnitary, 4),
	},
	{
		flex: 1,
		field: 'thetaUnitary',
		headerName: 'Theta unitário',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Theta unitário'),
		renderCell: params =>
			params.row.hierarchy.length > 1 &&
			getFormatedNumber(params.row.thetaUnitary, 4),
	},
	{
		flex: 1,
		field: 'vegaUnitary',
		headerName: 'Vega unitário',
		type: 'number',
		filterable: false,
		renderHeader: () => GreeksHeader('Vega unitário'),
		renderCell: params =>
			params.row.hierarchy.length > 1 &&
			getFormatedNumber(params.row.vegaUnitary, 4),
	},
	{
		flex: 1,
		field: 'approximateMargin',
		headerName: 'Margem aprox.',
		type: 'number',
		filterable: false,
	},
];
