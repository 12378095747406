import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import SimulatorComponent from 'pages/Simulator';
import { Portfolio } from 'components/Portfolio';

import { setActiveStrategies, setUnsavedModal, updateTabIndex } from 'store/modules/portfolio/actions';

import { Container } from './styles';

import { TabsHeader } from 'components/Portfolio/TabsHeader';
import { handleSelectStrategyTab } from 'utils/Portfolio/handleSimulateStrategy';

export const NewSimulator = ({ quotationServiceFunc }) => {
	document.title = 'Simulador | Flexscan';

	const dispatch = useDispatch();

	const activeStrategies = useSelector(state => state.portfolio?.activeStrategies);
	const tabIndex = useSelector(state => state.portfolio?.tabIndex);

	function setTabIndex(tabIndex) {
		dispatch(updateTabIndex(tabIndex));
	}

	function TabPanel({ children, value, index, ...other }) {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
				style={{ height: 'calc(100% - 50px)' }}
			>
				{value === index && (
					<Box style={{ height: '100%' }}>
						{children}
					</Box>
				)}
			</div>
		);
	}

	function handleClose(event, tabIndex, id) {
		event.stopPropagation();

		activeStrategies.forEach((strategy, activeStrategiesIndex) => {
			if (strategy.id === id) {
				if (strategy.saved === false) {
					dispatch(setUnsavedModal(true, strategy.id));
				} else {
					closeTab(tabIndex, activeStrategiesIndex);
				}
			}
		});
	}

	function closeTab(tabIndex, activeStrategiesIndex) {
		const activeStrategiesWithoutClosedTab = [...activeStrategies];
		activeStrategiesWithoutClosedTab.splice(activeStrategiesIndex, 1);

		dispatch(setActiveStrategies(activeStrategiesWithoutClosedTab));

		if (activeStrategiesIndex + 2 === tabIndex && activeStrategiesWithoutClosedTab.length > 0) {
			let newActiveStrategy = activeStrategiesWithoutClosedTab[0];

			if (activeStrategiesIndex !== 0) {
				newActiveStrategy = activeStrategiesWithoutClosedTab[activeStrategiesIndex - 1];
			}

			handleSelectStrategyTab(newActiveStrategy.id);
		}

		if ([1, 2].includes(tabIndex)) {
			if (activeStrategies.length === 1) {
				setTabIndex(0);
			} else {
				setTabIndex(2);
			}
		} else if (activeStrategiesIndex <= tabIndex - 2) {
			setTabIndex(tabIndex - 1);
		}
	}

	return (
		<>
			<Container>
				<TabsHeader
					closeTab={closeTab}
					tabIndex={tabIndex}
					setTabIndex={setTabIndex}
					handleClose={handleClose}
					activeStrategies={activeStrategies}
					handleSelectStrategyTab={handleSelectStrategyTab}
				/>

				<TabPanel value={tabIndex} index={0}>
					<Portfolio
						tabIndex={tabIndex}
						handleClose={handleClose}
						quotationServiceFunc={quotationServiceFunc}
					/>
				</TabPanel>

				{activeStrategies?.map((strategy, index) => (
					<TabPanel
						key={strategy.id}
						value={tabIndex}
						index={index + 2}
					>
						<SimulatorComponent quotationServiceFunc={quotationServiceFunc} />
					</TabPanel>
				))}

				<TabPanel
					value={tabIndex}
					index={activeStrategies.length + 2}
				>
					<SimulatorComponent quotationServiceFunc={quotationServiceFunc} />
				</TabPanel>
			</Container>
		</>
	);
};
