import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { StrategiesTabs } from './StrategiesTabs';

import { History } from './History';
import { Recurrence } from './Recurrence';
import { ViewExecutions } from './ViewExecutions';
import { CurrentStrategies } from './CurrentStrategies';

import { setStrategiesTabIndex } from 'store/modules/panelConfig/actions';

export const Strategies = ({ handleSubscription }) => {
	const dispatch = useDispatch();

	const userType = useSelector(state => state.auth.userType);
	const strategiesTabIndex = useSelector(
		state => state.configs.strategiesTabIndex
	);

	function TabPanel({ children, value, index, ...other }) {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				style={{ height: '100%' }}
				{...other}
			>
				{value === index && children}
			</div>
		);
	}

	function handleChangeStrategiesTabIndex(newIndex) {
		dispatch(setStrategiesTabIndex(newIndex));
	}

	return (
		<Box sx={{ height: 'calc(100% - 50px)', width: '100%' }}>
			<StrategiesTabs
				userType={userType.id}
				tabIndex={strategiesTabIndex}
				handleChangeTabIndex={handleChangeStrategiesTabIndex}
			/>

			<TabPanel index={0} value={strategiesTabIndex}>
				<CurrentStrategies handleSubscription={handleSubscription} />
			</TabPanel>

			<TabPanel index={1} value={strategiesTabIndex}>
				<History />
			</TabPanel>

			{userType.id !== 2 && (
				<TabPanel index={2} value={strategiesTabIndex}>
					<ViewExecutions />
				</TabPanel>
			)}

			<TabPanel
				index={userType.id !== 2 ? 3 : 2}
				value={strategiesTabIndex}
			>
				<Recurrence />
			</TabPanel>
		</Box>
	);
};
