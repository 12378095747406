import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { apiExec } from 'services/api';
import { positionsColumns } from 'utils/tablesColumns/positions.js';
import { postPositionsPreferences } from 'services/userPreferences/positions';
import {
	formatPositions,
	getPositionsConsolidation,
} from 'utils/positionsHelpers.js';
import {
	setPositionsRows,
	setPositionsConsolidation,
	updatePositionsUserPreferences,
	updatePositionsColumnsPreferences,
} from 'store/modules/positions/actions';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { CurrentPositionsFooter } from './CurrentPositionsFooter';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { CurrentPositionsFilter } from './CurrentPositionsFilters';
import { CurrentPositionsToolbar } from './CurrentPositionsToolbar';

export const CurrentPositions = () => {
	document.title = 'Posições | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const { username, token, profile } = useSelector(state => state.auth);
	const { rows, tablePreferences, currentPositionsFilters } = useSelector(
		state => state.positions
	);

	const { platform: selectedPlatform } = currentPositionsFilters;

	const isBtg = String(username).startsWith('btg_');

	useEffect(() => {
		getPositions();

		const interval = setInterval(() => {
			getPositions();
		}, 5000);

		return () => {
			clearInterval(interval);
		};
	}, [currentPositionsFilters]);

	function getPositions(event) {
		if (event) {
			event.preventDefault();
		}

		const selectedDeskId =
			selectedPlatform === 'all' ? {} : { deskid: selectedPlatform };

		const queryParams = {
			params: {
				financial_calc: 1,
				limit: 500,
				offset: 0,
				...selectedDeskId,
			},
		};

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		apiExec
			.get('/positions', queryParams, headers)
			.then(response => {
				const formattedPositions = formatPositions(
					response.data.records
				);

				const summedPositions =
					getPositionsConsolidation(formattedPositions);

				dispatch(setPositionsRows(formattedPositions));
				dispatch(setPositionsConsolidation(summedPositions));
			})
			.catch(error => console.log(error));
	}

	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updatePositionsUserPreferences(density));
			postPositionsPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		const filteredKeys = Object.keys(event).filter(key => event[key]);

		dispatch(
			updatePositionsColumnsPreferences({
				columnVisibilityModel: { ...event },
				orderedFields: [...filteredKeys],
			})
		);

		postPositionsPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current
			.getAllColumns()
			.map(column => column.field);

		dispatch(
			updatePositionsColumnsPreferences({
				orderedFields: [...columnsOrder],
			})
		);

		postPositionsPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	function onFilterModelChange(event) {
		dispatch(
			updatePositionsUserPreferences({
				filter: { filterModel: { ...event } },
			})
		);

		postPositionsPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };
		dispatch(updatePositionsUserPreferences(sorting));
		postPositionsPreferences(username, sorting);
	}

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 0 0.5rem',
			}}
		>
			{isBtg && <CurrentPositionsFilter />}

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<StyledDataGridPro
					rows={rows}
					components={{
						Toolbar: () => CurrentPositionsToolbar(apiRef, profile),
						Footer: CurrentPositionsFooter,
						NoRowsOverlay: () =>
							NoRowsOverlay('Nenhuma execução ativa'),
					}}
					apiRef={apiRef}
					columns={positionsColumns}
					density={tablePreferences?.density ?? 'standard'}
					onStateChange={event => onTableStateChange(event)}
					onSortModelChange={event => onSortModelChange(event)}
					onColumnOrderChange={event => onColumnOrderChange(event)}
					onFilterModelChange={event => onFilterModelChange(event)}
					onColumnVisibilityModelChange={event =>
						onColumnVisibilityModelChange(event)
					}
					getRowClassName={params =>
						params.indexRelativeToCurrentPage % 2 === 0
							? 'even'
							: 'odd'
					}
					initialState={{
						columns: tablePreferences?.columns,
						sorting: tablePreferences?.sorting,
						filter: tablePreferences?.filter,
					}}
				/>
			</Box>
		</Grid>
	);
};
