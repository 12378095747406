import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { LinearProgress } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { StyledDataGridPro, TableContainer } from './styles';

import {
	setRows,
	updateColumnsSize,
	updateUserPreferences,
} from 'store/modules/portfolio/actions';
import { postSimulatorPreferences } from 'services/userPreferences/simulator';

import { columns } from 'utils/Portfolio/columns';
import { calcMidExitPx } from 'utils/Portfolio/getExitPrices';
import { getResizedColumns } from 'utils/Portfolio/columnsResize';
import { partialStrategyCalc } from 'utils/Portfolio/partialStrategyCalc';
import { getStrategiesBySymbol } from 'utils/Portfolio/getStrategiesBySymbol';
import {
	addDefinitions,
	formatStrategies,
	formatToTablePattern,
} from 'utils/Portfolio/formatStrategies';

import { DeletionModal } from './DeletionModal';
import { OverlaySimulator } from './NoRowsOverlay/OverlaySimulator';
import { Toolbar } from 'components/Portfolio/Toolbar';
import { NoRowsOverlay } from 'components/Portfolio/NoRowsOverlay';
import { CustomDataTree } from 'components/Portfolio/CustomDataTree';
import { MigrationsFlows } from './Toolbar/MigrationMenu/MigrationsFlows';

const WEBSOCKET_REFRESH_INTERVAL = 2000;

export const Portfolio = ({ quotationServiceFunc, handleClose, tabIndex }) => {
	const dispatch = useDispatch();
	const apiRef = useGridApiRef();

	const ir = useSelector(state => state.simulator?.ir);
	const { username, token } = useSelector(state => state.auth);
	const simulatorSettings = useSelector(
		state => state.configs.globalSettings.userSettings.simulator
	);
	const {
		strategies: reduxStrategies,
		preferences,
		deletionData,
		rows,
	} = useSelector(state => state.portfolio);

	const [isLoading, setIsLoading] = useState(false);
	const [migrationStep, setMigrationStep] = useState('');
	const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);

	useEffect(() => {
		if (reduxStrategies.length > 0) {
			let tempInterval;

			formatStrategies(reduxStrategies, ir);

			const { strategiesByUnderSymbols, strategiesByPositionSymbols } =
				getStrategiesBySymbol(reduxStrategies);

			const minibookList = subscribeSymbols(
				strategiesByPositionSymbols,
				'minibook'
			);
			const tradeList = subscribeSymbols(
				strategiesByUnderSymbols,
				'trade'
			);

			const idsList = minibookList.concat(tradeList);

			tempInterval = setInterval(() => {
				updateRows(strategiesByUnderSymbols, apiRef);
			}, WEBSOCKET_REFRESH_INTERVAL);

			return () => {
				unsubscribeSymbols(idsList);
				clearInterval(tempInterval);
			};
		} else {
			dispatch(setRows([]));
		}
	}, [reduxStrategies, simulatorSettings]);

	useEffect(() => {
		if (
			simulatorSettings.exitPrice === 'last' &&
			reduxStrategies.length > 0
		) {
			const { strategiesByPositionSymbols } =
				getStrategiesBySymbol(reduxStrategies);

			const idList = subscribeSymbols(
				strategiesByPositionSymbols,
				'trade'
			);

			return () => {
				unsubscribeSymbols(idList);
			};
		}
	}, [simulatorSettings.exitPrice]);

	function updateRows(strategiesByUnderSymbols) {
		const formattedRows = [];

		Object.keys(strategiesByUnderSymbols).forEach(symbol => {
			strategiesByUnderSymbols[symbol].forEach(strategy => {
				if (strategy.hasChanged) {
					const enrichedStrategy = addDefinitions([strategy], ir);
					const rowWithTablePattern =
						formatToTablePattern(enrichedStrategy);

					Object.keys(rowWithTablePattern[0]).forEach(field => {
						if (
							rowWithTablePattern[0][field] === null ||
							rowWithTablePattern[0][field] === undefined
						) {
							delete rowWithTablePattern[0][field];
						}
					});

					formattedRows.push(...rowWithTablePattern);
					strategy.hasChanged = false;
					strategy.positions.forEach(
						position => (position.hasChanged = false)
					);
				} else {
					const strategiesCalculated = partialStrategyCalc(
						strategy,
						ir
					);

					if (strategiesCalculated) {
						formattedRows.push(...strategiesCalculated);
					}
				}
			});
		});

		if (formattedRows.length > 0) {
			apiRef.current.updateRows(formattedRows);
		}
	}

	function subscribeSymbols(symbolList, type) {
		const idList = [];

		Object.keys(symbolList).forEach(symbol => {
			const props = {
				action: 'subscribe',
				type,
				key: symbol,
				callbackFunction: data => {
					calculateStrategiesMetrics(
						symbolList[symbol],
						data,
						apiRef
					);
				},
			};

			idList.push(quotationServiceFunc(props));
		});

		return idList;
	}

	function updatePositions(strategy, marketData) {
		strategy.positions?.forEach(position => {
			if (!position.expired && position.symbol === marketData.symbol) {
				const bidPx =
					marketData?.msgType === 'M'
						? marketData?.bidPx
						: marketData?.bidPrices[0];
				const askPx =
					marketData?.msgType === 'M'
						? marketData?.askPx
						: marketData?.askPrices[0];

				if (!position.entryPxLocked) {
					if (position.qtty < 0) {
						const tempPx = bidPx ?? position.entryPx ?? 'NaN';

						if (position.entryPx !== tempPx) {
							position.hasChanged = true;
							position.entryPx = tempPx;
						}
					} else {
						const tempPx = askPx ?? position.entryPx ?? 'NaN';

						if (position.entryPx !== tempPx) {
							position.hasChanged = true;
							position.entryPx = tempPx;
						}
					}
				}

				if (!position.exitPxLocked) {
					if (position.qtty < 0) {
						const tempPx = askPx ?? position.exitPx ?? 'NaN';

						if (position.exitPx !== tempPx) {
							position.hasChanged = true;
							position.exitPx = tempPx;
						}
					} else {
						const tempPx = bidPx ?? position.exitPx ?? 'NaN';

						if (position.exitPx !== tempPx) {
							position.hasChanged = true;
							position.exitPx = tempPx;
						}
					}

					if (simulatorSettings.exitPrice === 'medium') {
						const newMediumPrice = calcMidExitPx(
							position.entryPx,
							position.exitPx,
							position.exitPxLocked
						);

						if (newMediumPrice !== position.mediumPrice) {
							position.mediumPrice = newMediumPrice;
							position.hasChanged = true;
						}
					}
				}
			}
		});
	}

	function getLastTradePrices(marketData, strategy) {
		strategy.positions.forEach(position => {
			if (
				marketData.symbol === position.symbol &&
				marketData.price &&
				marketData.price != position.lastTradePx
			) {
				position.lastTradePx = marketData.price;
				position.hasChanged = true;
			}
		});
	}

	function calculateStrategiesMetrics(strategies, marketData) {
		strategies.forEach(strategy => {
			if (marketData.msgType === 'M' || marketData.msgType === 'book') {
				updatePositions(strategy, marketData);
			} else if (marketData.msgType === 'T') {
				if (
					strategy.underSymbol === marketData.symbol &&
					strategy.underPrice !== marketData.price
				) {
					const paperPrice =
						marketData?.price ??
						marketData?.prevClosePx ??
						strategy.underPrice;
					strategy.underPrice = paperPrice;
					strategy.hasChanged = true;
				}

				if (simulatorSettings.exitPrice === 'last') {
					getLastTradePrices(marketData, strategy);
				}
			}
		});
	}

	function unsubscribeSymbols(idList) {
		idList.forEach(id => {
			const props = {
				action: 'unsubscribe',
				id: id,
			};

			quotationServiceFunc(props);
		});
	}

	const groupingColDef = {
		headerName: 'Ações',
		type: 'actions',
		renderCell: params => (
			<CustomDataTree
				tabIndex={tabIndex}
				isLoading={isLoading}
				handleClose={handleClose}
				setIsLoading={setIsLoading}
				openDeletionModal={() => setIsDeletionModalOpen(true)}
				{...params}
			/>
		),
	};

	function onTableStateChange(event) {
		if (event.density.value !== preferences.density) {
			const density = { density: event.density.value };

			dispatch(updateUserPreferences(density));
			postSimulatorPreferences(username, density);
		}
	}

	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateUserPreferences({
				columns: { columnVisibilityModel: { ...event } },
			})
		);
		postSimulatorPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	function onFilterModelChange(event) {
		dispatch(
			updateUserPreferences({ filter: { filterModel: { ...event } } })
		);
		postSimulatorPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };

		dispatch(updateUserPreferences(sorting));
		postSimulatorPreferences(username, sorting);
	}

	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateUserPreferences({
				columns: { orderedFields: [...columnsOrder] },
			})
		);
		postSimulatorPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	function onColumnWidthChange(event) {
		if (event?.colDef?.field && event?.width) {
			dispatch(updateColumnsSize(event.colDef.field, event.width));
		}
	}

	function changeRowExpansion() {
		apiRef.current.getRowModels().forEach(row => {
			if (row.hierarchy.length === 1) {
				apiRef.current.setRowChildrenExpansion(
					row.id,
					!preferences.rowsExpanded
				);
			}
		});

		dispatch(
			updateUserPreferences({ rowsExpanded: !preferences.rowsExpanded })
		);
		postSimulatorPreferences(username, {
			rowsExpanded: !preferences.rowsExpanded,
		});
	}

	const resizedColumns = getResizedColumns(
		columns,
		preferences?.columns.columnsSize
	);

	return (
		<TableContainer>
			<StyledDataGridPro
				components={{
					Toolbar: () =>
						Toolbar(
							isLoading,
							changeRowExpansion,
							setMigrationStep
						),
					LoadingOverlay: LinearProgress,
					NoRowsOverlay:
						reduxStrategies.length > 0
							? OverlaySimulator
							: () => NoRowsOverlay('Nenhuma estratégia salva'),
				}}
				initialState={{
					columns: preferences?.columns,
					sorting: preferences?.sorting,
					filter: preferences?.filter,
				}}
				treeData
				hideFooter
				apiRef={apiRef}
				rows={rows}
				columns={resizedColumns}
				loading={isLoading}
				disableChildrenFiltering
				getRowId={row => row.id}
				groupingColDef={groupingColDef}
				getTreeDataPath={row => row.hierarchy}
				density={preferences?.density ?? 'standard'}
				onStateChange={event => onTableStateChange(event)}
				onSortModelChange={event => onSortModelChange(event)}
				onFilterModelChange={event => onFilterModelChange(event)}
				onColumnOrderChange={event => onColumnOrderChange(event)}
				onColumnVisibilityModelChange={event =>
					onColumnVisibilityModelChange(event)
				}
				getRowClassName={params =>
					params.row.hierarchy.length === 1
						? 'main-strategy'
						: 'strategy-legs'
				}
				onColumnWidthChange={event => onColumnWidthChange(event)}
				defaultGroupingExpansionDepth={
					preferences.rowsExpanded ? -1 : 0
				}
			/>

			<MigrationsFlows
				migrationStep={migrationStep}
				setMigrationStep={setMigrationStep}
			/>

			<DeletionModal
				token={token}
				tabIndex={tabIndex}
				handleClose={handleClose}
				deletionData={deletionData}
				isOpen={isDeletionModalOpen}
				onClose={() => setIsDeletionModalOpen(false)}
			/>
		</TableContainer>
	);
};
