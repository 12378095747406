import React, { useEffect } from 'react';
import { compareAsc, parseISO } from 'date-fns';
import { useSelector, useDispatch } from 'react-redux';
import { Switch, Route, useHistory } from 'react-router-dom';

// Services
import { api, apiAWS, apiEls, apiExec, apiMarketData } from 'services/api';

// Pages
import Login from 'pages/Login/index';
import LoginOrama from 'pages/LoginOrama';
import Logout from 'pages/Logout';
import {NotFound} from 'pages/NotFound';
import VolStudioCharts from 'pages/VolStudioCharts';
import Scanner from 'pages/Scanner';
import OptionsChain from 'pages/OptionsChain';
import { StrategiesUpload } from 'pages/StrategiesUpload';
import { Strategies } from 'pages/Strategies';
import { Positions } from 'pages/Positions';
import { Risk } from 'pages/Risk';

// Store
import { setNotifications } from 'store/modules/notifications/actions';
import { saveExternalStorage } from './store/modules/externalStorage/actions';
import {
	orderEntry,
	additionalUpdate,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import {
	addItemBookSubscriptionList,
	addItemMiniBookSubscriptionList,
	addItemSnapshotSubscriptionList,
	addItemTradeSubscriptionList,
} from './store/modules/websocket/actions';
import {
	getSimStrategyToAdd,
	saveStrategy,
} from 'store/modules/simulator/actions';

// Components
import PrivateRoute from 'components/PrivateRoute';
import AppHeader from 'components/AppHeader';
import WidgetBar from './components/WidgetBar';
import { Bullet } from 'components/Bullet';

import { MainContent } from './styles';

import addWidgetFromButton from 'utils/addWidgetFromButton';
import { NewSimulator } from 'pages/NewSimulator';
import { moveToSimulator } from 'utils/moveToSimulator';
import { EXM } from 'utils/bulletContext';
import { addClipProportion } from 'utils/addClipProportion';

function Routes(props) {
	const { registerCallback, unRegisterCallback } = props;
	const isAuthenticated = useSelector(state => state.auth.token);
	const accountType = useSelector(state => state.auth.accountType);
	const username = useSelector(state => state.auth.username);
	const account = useSelector(state => state.auth.account);
	const authToken = useSelector(state => state.auth.token);
	const dispatch = useDispatch();
	const history = useHistory();

	function getNotifications() {
		if (!authToken) return;

		const headers = { headers: { 'x-authorization': authToken } };

		apiAWS
			.get('/notifications', headers)
			.then(response => {
				const notificationsOrdened = response.data.records.sort(
					(a, b) =>
						compareAsc(
							parseISO(a.created_date),
							parseISO(b.created_date)
						)
				);
				dispatch(setNotifications(notificationsOrdened));
			})
			.catch(error => console.log(error));
	}

	useEffect(() => {
		if (isAuthenticated) {
			const baseUrl =
				accountType === 'real'
					? process.env.REACT_APP_USR_DMA_API_URL
					: process.env.REACT_APP_USR_SIM_API_URL;
			api.defaults.baseURL = baseUrl;

			apiExec.defaults.baseURL = baseUrl;

			api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiExec.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiEls.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiMarketData.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiAWS.defaults.headers.common.Authorization = `Bearer ${authToken}`;
		}

		// Temporariamente comentado devido a não utilização do mesmo.
		// getNotifications();
	}, [isAuthenticated, accountType, authToken, getNotifications]);

	const handleSubscription = props => {
		if (props.action === 'subscribe') {
			let subscriptionType = props.type;

			if (subscriptionType === 'snapshot') {
				dispatch(addItemSnapshotSubscriptionList(subscriptionType));
			} else {
				if (subscriptionType === 'book') {
					dispatch(addItemBookSubscriptionList(props.key));
				} else if (subscriptionType === 'trade') {
					dispatch(addItemTradeSubscriptionList(props.key));
				} else if (subscriptionType === 'minibook') {
					dispatch(addItemMiniBookSubscriptionList(props.key));
				}

				setFirstSnapshot(
					props.key,
					subscriptionType,
					props.callbackFunction
				);
			}

			const uuid = registerCallback({ ...props, type: subscriptionType });
			return uuid;
		} else if (props.action === 'unsubscribe') {
			unRegisterCallback(props.id);
		}
	};

	function updatePreferences(newState) {
		const payload = { ...newState };
		if (payload.instrument != {}) {
			payload.jdata = [];
			payload.portfolio = { strategies: [{ positions: [], totals: {} }] };

			delete payload.token;
			delete payload.user_name;
			delete payload.service_name;
			apiAWS
				.post(
					`/userpreferences/${username}?service=options_trader`,
					payload
				)
				.then(res => {
					if (process.env.NODE_ENV === 'development')
						console.log('res', res);
				});
		}
	}

	function getSubscriptionType(type) {
		switch (type) {
			case 'T':
			case 'trade':
				return 'trade';
			case 'minibook':
				return 'minibook';
			default:
				return 'book';
		}
	}

	function actionListener(action, payload) {
		if (action === 'mkt_subscribe' || action === 'mkt_unsubscribe') {
			// definindo a action

			payload.action =
				action === 'mkt_subscribe' ? 'subscribe' : 'unsubscribe';

			if (payload.action === 'subscribe') {
				//verificando se o valor passado a ser subscrito e valido
				if (payload.key || payload.topic) {
					//Definindo tipo de subscricao
					payload.type = getSubscriptionType(payload.type);

					//definindo chave (symbol ou topico) a ser subscrito
					payload.key = payload.key ? payload.key : payload.topic;

					//definindo callback a ser chamado
					payload.callbackFunction = payload.callbackFunction
						? payload.callbackFunction
						: payload.callbackFunc;
				} else {
					//aborta request
					return;
				}
			}

			return handleSubscription(payload);
		} else if (action === 'add_widget') {
			// Abrir book a partir da options trader
			if (payload.type === 'BookTrade') {
				addWidgetFromButton(payload.symbol);
			}
		} else if (action === 'order_entry') {
			addClipProportion(payload.StrategyLegs, payload.StrategyInfos);

			// preencher boleta
			payload.context = EXM;
			dispatch(orderEntry(payload, payload?.subUid));
			dispatch(updateStrategyInfosFromApi(payload.StrategyLegs));

			// abrir boleta
			dispatch(additionalUpdate('isOpen', true));
			dispatch(additionalUpdate('isRecentListVisible', false));
			dispatch(additionalUpdate('isSavedListVisible', false));

			// solicitar o lastPx para cada perna via api /Quotelast
		} else if (action === 'state_change') {
			// Alteração de status
			payload.state.recreateFromState = false;
			updatePreferences(payload.state);
			dispatch(saveExternalStorage(payload, 'optionsChain')); //substituir optionsChain por payload.id
		} else if (action === 'add_sim_strategy') {
			if (!payload?.strategy) return; //Se não tiver objeto strategy aborta

			const data = getSimStrategyToAdd(
				payload.strategy,
				username,
				account
			);

			if (data) {
				//Will return null if no legs are added
				dispatch(data); //Add strategy in simulator

				//Save strategy
				if (payload?.toSave === true) {
					dispatch(saveStrategy(data.payload.strategy));
				}

				// Move user to simulator
				if (payload?.navToSimulator === true) {
					moveToSimulator(history, data.payload.strategy);
				}
			}
		}
	}

	const parsingLastQuoteMiniBook = book => {
		//"PETRE239#0.23|500|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0"
		let minibooks = [];
		if (book && book.length > 0) {
			let result = book.split('#');
			if (result.length > 1) {
				//PETRE239#0.23|500|0.0|0
				let symbol = result[0];
				let topOfBook = result[1].split('|');
				let fields = ['bidPx', 'bidQtty', 'askPx', 'askQtty'];
				for (let i = 0; i < topOfBook.length; i++) {
					let minibook = {
						msgType: 'M',
						symbol: symbol,
					};
					if (fields[i] === 'bidPx' || fields[i] === 'askPx') {
						minibook[fields[i]] = +topOfBook[i];
					} else {
						minibook[fields[i]] = topOfBook[i];
					}

					minibooks.push(minibook);
				}
			} else {
				console.error('Problema ao realizar split lastQuote book....');
			}
		}

		return minibooks;
	};

	const parsingLastQuoteBook = book => {
		//"PETRE239#0.23|500|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0#0.0|0|0.0|0"
		const resultBook = {
			msgType: 'book',
			bidPrices: [],
			bidQuantities: [],
			askPrices: [],
			askQuantities: [],
		};
		if (book && book.length > 0) {
			let result = book.split('#');
			if (result.length > 1) {
				result.shift();

				for (let i = 0; i < 5; i++) {
					const rowResult = result[i].split('|');
					resultBook.bidPrices.push(rowResult[0]);
					resultBook.bidQuantities.push(rowResult[1]);
					resultBook.askPrices.push(rowResult[2]);
					resultBook.askQuantities.push(rowResult[3]);
				}
			} else {
				console.error('Problema ao realizar split lastQuote book....');
			}
		}

		return resultBook;
	};

	const parsingLastQuoteTrade = lastQuote => {
		const trade = { msgType: 'T', symbol: lastQuote.symbol };

		if ('lastPx' in lastQuote) {
			trade.price = lastQuote.lastPx;
		}

		if ('tradeVolume' in lastQuote) {
			trade.tradeVol = lastQuote.tradeVolume;
		}

		if ('financialTradeVolume' in lastQuote) {
			trade.finVol = lastQuote.financialTradeVolume;
		}

		if ('var' in lastQuote) {
			trade.var = lastQuote.var;
		}

		if ('lowPx' in lastQuote) {
			trade.lowPx = lastQuote.lowPx;
		}

		if ('highPx' in lastQuote) {
			trade.highPx = lastQuote.highPx;
		}

		if ('tradeTime' in lastQuote) {
			trade.ts = lastQuote.tradeTime;
		}

		if ('openPrice' in lastQuote) {
			trade.openPrice = lastQuote.openPrice;
		}

		if ('prevClosePx' in lastQuote) {
			trade.prevClosePx = lastQuote.prevClosePx;
		}

		if ('numberOfTrades' in lastQuote) {
			trade.tradeNbr = lastQuote.numberOfTrades;
		}

		if ('hvP' in lastQuote) {
			let percentage = +lastQuote.hvP * 100;
			trade.hv_p = percentage.toFixed(2).replace('.', ',');
		}

		if ('hvR' in lastQuote) {
			let percentage = +lastQuote.hvR * 100;
			trade.hv_rank = percentage.toFixed(2).replace('.', ',');
		}

		if ('ivP' in lastQuote) {
			let percentage = +lastQuote.ivP * 100;
			trade.iv_p = percentage.toFixed(2).replace('.', ',');
		}

		if ('ivR' in lastQuote) {
			let percentage = +lastQuote.ivR * 100;
			trade.iv_rank = percentage.toFixed(2).replace('.', ',');
		}

		if ('openInterest' in lastQuote) {
			trade.openInterest = lastQuote.openInterest;
		}

		return trade;
	};

	const setFirstSnapshot = async (key, type, actionFunction) => {
		//Verifica se o symbol tem uma entrada no objeto
		if (key.includes('/OPT/')) {
			let splited_key = key.split('/');
			if (splited_key.size < 3) {
				console.error('formato nao esperado para lastQuote symbol...');
				return;
			}

			const endpoint = `/exchanges/BVMF/instruments/${splited_key[0]}/quotes/last?derivatives=${splited_key[1]}&maturityDate=${splited_key[2]}`;

			const response = await apiMarketData.get(endpoint);

			for (let quote of response.data.records) {
				processQuoteCallback(
					{ ...quote },

					actionFunction
				);
			}
		} else {
			const endpoint = `/exchanges/BVMF/instruments/${key}/quotes/last?detailed=1`;

			const response = await apiMarketData.get(endpoint);

			processQuoteCallback({ ...response.data }, actionFunction);
		}
	};

	const processQuoteCallback = (quote, actionFunction) => {
		let minibooksList = parsingLastQuoteMiniBook(quote.book);

		let trade = parsingLastQuoteTrade(quote);

		let bookList = parsingLastQuoteBook(quote.book);

		actionFunction(trade);
		actionFunction(bookList);

		for (let item of minibooksList) {
			actionFunction(item);
		}
	};

	return (
		<div style={{ width: '100%', display: 'flex' }}>
			{isAuthenticated && <AppHeader />}
			<div
				style={{
					paddingTop: `${isAuthenticated ? '41px' : '0px'}`,
					width: '100%',
					display: 'flex',
					flex: 1,
					flexDirection: 'column-reverse',
				}}
			>
				{isAuthenticated && <Bullet />}
				<MainContent>
					<Switch>
						<PrivateRoute exact path="/">
							<Strategies
								handleSubscription={handleSubscription}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/dashboard">
							<Strategies
								handleSubscription={handleSubscription}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/posicoes">
							<Positions />
						</PrivateRoute>
						<PrivateRoute exact path="/simulator">
							<NewSimulator
								actionListener={actionListener}
								quotationServiceFunc={handleSubscription}
							/>
						</PrivateRoute>
						<PrivateRoute exact path="/riscos">
							<Risk />
						</PrivateRoute>
						<PrivateRoute exact path="/cadeia-opcoes">
							<OptionsChain actionListener={actionListener} />
						</PrivateRoute>
						<PrivateRoute exact path="/vol-charts">
							<VolStudioCharts />
						</PrivateRoute>
						<PrivateRoute exact path="/scanner">
							<Scanner actionListener={actionListener} />
						</PrivateRoute>
						<PrivateRoute exact path="/upload-estrategias">
							<StrategiesUpload />
						</PrivateRoute>
						<PrivateRoute exact path="/logout">
							<Logout />
						</PrivateRoute>

						<Route exact path="/login/orama/:userToken">
							<LoginOrama />
						</Route>
						<Route exact path="/login">
							<Login />
						</Route>
						<Route exact path="/novo-usuario/:newUserToken">
							<Login newUser />
						</Route>
						<Route path="*">
							<NotFound />
						</Route>
					</Switch>
				</MainContent>
			</div>

			{isAuthenticated && (
				<WidgetBar quotationServiceFunc={handleSubscription} />
			)}
		</div>
	);
}

export default Routes;
