import { PANEL_CONFIG } from './actionTypes';

export const globalSettingsInitialState = {
	bullet: {
		execType: 'B',
	},
	simulator: {
		greekType: 'exit',
		exitPrice: 'market',
	},
	platform: {
		volume: 30,
	},
};

export const initialState = {
	alerts: 'on',
	holidays: [],
	preferences: {
		bulletSendConfirmation: false,
		brokerageAndFees: true, // 'true' stands for fixed cost, 'false' stands for variable cost
		stocksValue: {
			firstInput: 0,
			secondInput: 0,
		},
		optionsValue: {
			firstInput: 0,
			secondInput: 0,
		},
		taxesValue: {
			firstInput: 0,
			secondInput: 0,
		},
		editTarget: {},
		defaultPriceSelect: 0, // 0 stands for market, 1 stands for last deal, 2 stands for average between bid and offer
		lastDealValue: 0, // last deal value for default price 1
		marginCalc: 0, // 0 stands for implied volatility. 1 stands for historical volatility
	},
	dashboardMode: 'card',
	theme: 'dark',
	requests: {
		tableFetch: true,
	},
	maxRowsPerVision: 20,
	widgetIsLoading: false,
	widgetBar: false,
	globalSettings: {
		isGlobalSettingsOpen: false,
		module: 'bullet',
		settingsToChange: globalSettingsInitialState,
		userSettings: globalSettingsInitialState,
	},
	strategiesTabIndex: 0,
	positionsTabIndex: 0,
	menuAnchorEl: null,
};

const reducer = {
	[PANEL_CONFIG.TABLES_SET]: (stt, payload) => ({
		...stt,
		tables: {
			...stt.tables,
			...payload,
		},
	}),
	[PANEL_CONFIG.CONFIG_SET]: (stt, payload) => ({
		...stt,
		...payload,
	}),
	[PANEL_CONFIG.REQUEST_SET]: (stt, payload) => ({
		...stt,
		requests: {
			...stt.requests,
			...payload,
		},
	}),
	[PANEL_CONFIG.SET_EXPANDED]: (stt, payload) => ({
		...stt,
		tables: {
			...stt.tables,
			[payload.table]: {
				...stt.tables[payload.table],
				expanded: payload.data,
			},
		},
	}),
	[PANEL_CONFIG.EDIT_TARGET]: (stt, payload) => ({
		...stt,
		tables: {
			...stt.tables,
			monitor: {
				...stt.tables.monitor,
				editTarget: payload.editTarget,
			},
		},
	}),
	[PANEL_CONFIG.BACKGROUND_EDIT_TARGET]: (stt, payload) => ({
		...stt,
		tables: {
			...stt.tables,
			monitor: {
				...stt.tables.monitor,
				backgroundEditTarget: payload.backgroundEditTarget,
			},
		},
	}),
	[PANEL_CONFIG.SET_RESIZE_HEADER]: (stt, { table, header }) => ({
		...stt,
		tables: {
			...stt.tables,
			[table]: {
				...stt.tables[table],
				headers: stt.tables[table].headers.map(item =>
					item.accessor === header.id
						? { ...item, width: header.value }
						: item
				),
			},
		},
	}),
	[PANEL_CONFIG.SET_IS_GLOBAL_SETTINGS_OPEN]: (stt, { isOpen, module }) => ({
		...stt,
		globalSettings: {
			...stt.globalSettings,
			isGlobalSettingsOpen: isOpen,
			module,
		},
	}),
	[PANEL_CONFIG.SET_GLOBAL_SETTINGS_MODULE]: (stt, { module }) => ({
		...stt,
		globalSettings: {
			...stt.globalSettings,
			module,
		},
	}),
	[PANEL_CONFIG.SET_GLOBAL_SETTINGS]: (stt, { newSettings }) => ({
		...stt,
		globalSettings: {
			...stt.globalSettings,
			userSettings: newSettings,
			settingsToChange: newSettings,
		},
	}),
	[PANEL_CONFIG.SET_PARTIAL_GLOBAL_SETTING]: (
		stt,
		{ module, newSettings }
	) => ({
		...stt,
		globalSettings: {
			...stt.globalSettings,
			settingsToChange: {
				...stt.globalSettings.settingsToChange,
				[module]: {
					...stt.globalSettings.settingsToChange[module],
					...newSettings,
				},
			},
		},
	}),
	[PANEL_CONFIG.RESET_CHANGES]: (stt, payload) => ({
		...stt,
		globalSettings: {
			...stt.globalSettings,
			settingsToChange: { ...payload.settings },
		},
	}),
	[PANEL_CONFIG.SET_STRATEGIES_TAB_INDEX]: (stt, payload) => ({
		...stt,
		strategiesTabIndex: payload.strategiesTabIndex,
	}),
	[PANEL_CONFIG.SET_HOLIDAYS]: (stt, payload) => ({
		...stt,
		holidays: payload.holidays,
	}),
	[PANEL_CONFIG.SET_POSITIONS_TAB_INDEX]: (stt, payload) => ({
		...stt,
		positionsTabIndex: payload.positionsTabIndex,
	}),
};

export default function panelConfigReducer(
	state = initialState,
	{ type, payload }
) {
	if (typeof reducer[type] === 'function') {
		return reducer[type](state, payload);
	}
	return state;
}
