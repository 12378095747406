import React from 'react';

import { Tabs } from '@mui/material';
import {
	FormatListBulleted,
	RepeatRounded,
	RequestQuote,
	Restore,
} from '@mui/icons-material';

import { StyledTab } from 'components/Portfolio/TabsHeader/styles';

export const StrategiesTabs = ({
	tabIndex,
	handleChangeTabIndex,
	userType,
}) => {
	function handleChange(_, newIndex) {
		handleChangeTabIndex(newIndex);
	}

	function a11yProps(index) {
		return {
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
			>
				<StyledTab
					label="Acomp. Estratégias"
					icon={<FormatListBulleted />}
					iconPosition="start"
					sx={{ minHeight: '50px !important' }}
					{...a11yProps(0)}
				/>

				<StyledTab
					label="Histórico Estratégias"
					icon={<Restore />}
					iconPosition="start"
					sx={{ minHeight: '50px !important' }}
					{...a11yProps(1)}
				/>

				{userType !== 2 && (
					<StyledTab
						label="Ordens Executadas"
						icon={<RequestQuote />}
						iconPosition="start"
						sx={{ minHeight: '50px !important' }}
						{...a11yProps(2)}
					/>
				)}

				<StyledTab
					label={
						<>
							<span>Recorrências</span>
							<span className="beta-badge">BETA</span>
						</>
					}
					icon={<RepeatRounded />}
					iconPosition="start"
					sx={{ minHeight: '50px !important' }}
					{...a11yProps(userType !== 2 ? 3 : 2)}
				/>
			</Tabs>
		</>
	);
};
