import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import { Grid, List, ListItem, Typography } from '@mui/material';
import { Check, Clear } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';

import { apiAWS } from 'services/api';

import { PasswordInput } from 'components/PasswordInput';

export const NewSingnature = ({ newUserToken }) => {
	const history = useHistory();

	const [isLoading, setIsLoading] = useState(false);

	const [isVisible, setIsVisible] = useState({
		signature: false,
		signature_confirmation: false,
	});

	const [credentials, setCredentials] = useState({
		signature: '',
		signature_confirmation: '',
	});

	function handleChangeSignature(field, value) {
		setCredentials({ ...credentials, [field]: value });
	}

	function handleVisiblePassword(type) {
		setIsVisible({ ...isVisible, [type]: !isVisible[type] });
	}

	function handleNewSignature(event) {
		event.preventDefault();

		if (credentials.signature !== credentials.signature_confirmation) {
			toast.error('Assinaturas não conferem');
			return;
		}

		setIsLoading(true);

		const body = {
			service: 'create_signature',
			password: credentials.signature,
		};

		const headers = { headers: { 'x-authorization': newUserToken } };

		apiAWS
			.post('/credentials/commands', body, headers)
			.then(() => toast.success('Assinatura eletrônica criada com sucesso! Faça seu login para acessar a plataforma'))
			.catch(() => toast.error('Erro ao criar nova assinatura, entre em contato com o suporte!'))
			.finally(() => {
				setIsLoading(false);
				history.push('/login');
			});
	}

	const regex = /^[0-9]{6}$/.test(credentials.signature);
	const isSignaturesEqual = credentials.signature !== '' && credentials.signature === credentials.signature_confirmation;

	return (
		<form onSubmit={(event) => handleNewSignature(event)}>
			<Grid
				container
				sx={{
					justifyContent: 'center',
					flexDirection: 'column',
					alignItems: 'center',
					width: '100%',
					gap: 2,
					mt: 1,
				}}
			>
				<PasswordInput
					required
					label="Assinatura"
					value={credentials.signature}
					labelStyles={{ color: '#888888', textShadow: 'none' }}
					withoutAutofill
					onChange={(event) => handleChangeSignature('signature', event.target.value)}
					isPasswordVisible={isVisible.signature}
					changePasswordVisibility={() => handleVisiblePassword('signature')}
				/>

				<PasswordInput
					label="Confirme sua assinatura"
					value={credentials.signature_confirmation}
					labelStyles={{ color: '#888888', textShadow: 'none' }}
					withoutAutofill
					onChange={(event) => handleChangeSignature('signature_confirmation', event.target.value)}
					isPasswordVisible={isVisible.signature_confirmation}
					changePasswordVisibility={() => handleVisiblePassword('signature_confirmation')}
				/>

				<List
					sx={{
						display: 'flex',
						flexDirection: 'column',
						gap: 1,
						width: '100%',
						color: '#333',
					}}
				>
					<Typography
						variant="subtitle1"
						fontWeight="bold"
					>
						Regras da assinatura
					</Typography>

					<ListItem disablePadding>
						{regex ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
						<Typography variant="subtitle1" sx={{ ml: 1 }}>Assinatura eletrônica deve ter 6 caracteres numéricos.</Typography>
					</ListItem>

					<ListItem disablePadding>
						{isSignaturesEqual ? <Check color="success" fontSize="small" /> : <Clear color="error" fontSize="small" />}
						<Typography variant="subtitle1" sx={{ ml: 1 }}>As assinaturas devem se coincidir</Typography>
					</ListItem>
				</List>

				<LoadingButton
					fullWidth
					type="submit"
					size="large"
					variant="contained"
					loading={isLoading}
					disabled={
						!regex ||
						credentials.signature === '' &&
						credentials.signature !== credentials.signature_confirmation
					}
				>
					Definir nova senha
				</LoadingButton>
			</Grid>
		</form>
	);
};
