import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Book } from '@mui/icons-material';
import { Grid, Box, IconButton, Typography } from '@mui/material';

import numberFormat from 'utils/numberFormat';
import PaperSearch from '../../services/paperSearch';
import addWidgetFromButton from 'utils/addWidgetFromButton';

import { LightTooltip } from 'components/LightTooltip';

export function OptionsChainBar({ setPaperInfo, value, actionListener }) {
	const lockBook = useSelector(state => state.configs.widgetIsLoading);

	const [paper, setPaper] = useState({});
	const [subscribeId, setSubscribeId] = useState(0);

	function fixDate(value) {
		const hour = value[8] + value[9] - 1;
		const minute = value[10] + value[11];
		const seconds = value[12] + value[13];

		return `${hour}:${minute}:${seconds}`;
	}

	useEffect(() => {
		if (value) {
			const params = {
				action: 'subscribe',
				type: 'trade',
				key: value,
				callbackFunction: msg => {
					if (msg.msgType === 'T') {
						const merged = { ...paper, ...msg };
						setPaper(merged);
					}
				},
			};

			const unsubScribeParams = {
				action: 'unsubscribe',
				type: 'trade',
				id: subscribeId,
			};

			actionListener('mkt_unsubscribe', unsubScribeParams);
			const subId = actionListener('mkt_subscribe', params);
			setSubscribeId(subId);
		}
	}, [value]);

	return (
		<Grid
			container
			sx={{
				p: 0.5,
				gap: 1,
				alignItems: 'center',
			}}
		>
			<Typography
				variant="h5"
				fontWeight="bold"
			>
				Options Trader
			</Typography>

			<PaperSearch
				index={0}
				onlyUnderlying={true}
				excludeFracionary={true}
				setPaperInfo={setPaperInfo}
				value={value || ''}
				style={{
					background: 'transparent',
					width: 80,
					color: '#707070',
					fontSize: 16,
					fontWeight: 'bold',
				}}
			/>

			<LightTooltip arrow title="Abrir book">
				<IconButton
					size="small"
					disabled={lockBook}
					onClick={() => addWidgetFromButton(value)}
				>
					<Book />
				</IconButton>
			</LightTooltip>

			<Typography
				fontWeight="bold"
				sx={{
					fontSize: '15px',
					textAlign: 'center',
					color: '#ffcb00',
				}}
			>
				{
					paper.price &&
					parseFloat(paper.price).toLocaleString('pt-Br', {
						minimumFractionDigits: 2,
					})
				}
			</Typography>

			<Box display="block">
				{paper.var && (
					<Box color={Math.sign(paper.var) < 0 ? 'error.main' : 'success.main'}>
						<Typography>{numberFormat(paper.var.toFixed(2), '00')}%</Typography>
					</Box>
				)}

				{paper.ts && <Typography>{fixDate(paper.ts)}</Typography>}
			</Box>
		</Grid>
	);
}
