import { addYears, format, isBefore } from 'date-fns';

import { store } from 'store';
import {
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { getParsedDate } from './getParsedDate';
import { addClipProportion } from './addClipProportion';
import { getSubUid, hasExpiratedSymbols } from './strategies';

export function handleOrderEntry(
	content,
	context,
	openBullet,
	setIsStrategyExpired
) {
	const subUid = getSubUid(content);

	if (
		content.StrategyCode === 'fxorder' &&
		content.CustomParameters.StartPriceTrigger
	) {
		content.CustomParameters.PriceLimit =
			content.CustomParameters.StartPriceTrigger;
	}

	if (
		content.StrategyCode === 'fxorder' &&
		content.CustomParameters.EntranceExecPrice
	) {
		content.CustomParameters.PriceLimit =
			content.CustomParameters.EntranceExecPrice;
	}

	if (
		subUid === 3 &&
		!content.CustomParameters.StopGain &&
		content.CustomParameters.StopType === 1
	) {
		content.StrategyLegs[0].LegQuantity =
			content.CustomParameters.OrderQuantity;
		content.StrategyCode = 'sorder';
	}

	if (content.StrategyCode === 'fxorder') {
		content.CustomParameters.stop = true;
	}

	if (content.TimeInForce === '0') {
		const parsedDate = getParsedDate(content.ExpireDate);
		if (isBefore(parsedDate, new Date())) {
			content.ExpireDate = format(new Date(), 'yyyyMMdd');
		}
	} else if (content.TimeInForce === '1') {
		const tenYearsLater = addYears(new Date(), 10);
		content.ExpireDate = format(tenYearsLater, 'yyyyMMdd');
	}

	const hasExpiratedSymbol = hasExpiratedSymbols(content.StrategyInfos);

	if (!hasExpiratedSymbol) {
		addClipProportion(content.StrategyLegs, content.StrategyInfos);

		content.context = context;
		store.dispatch(orderEntry(content, subUid));

		store.dispatch(updateStrategyInfosFromApi(content.StrategyLegs));

		openBullet();
	} else {
		setIsStrategyExpired();
	}
}
