import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, IconButton, Tooltip } from '@mui/material';
import { Book, Close, DragIndicator } from '@mui/icons-material';

import { Cell } from './components/Cell';
import { InputSide } from './components/InputSide';
import { InputQtty } from './components/InputQtty';
import { HeaderExit } from './components/HeaderExit';
import { CellResult } from './components/CellResult';
import { CellFooter } from './components/CellFooter';
import { HeaderGreek } from './components/HeaderGreek';
import { LightTooltip } from 'components/LightTooltip';
import { SelectSerie } from './components/SelectSerie';
import { InputExitPx } from './components/InputExitPx';
import { InputTheorPx } from './components/InputTheorPx';
import { InputEntryPx } from './components/InputEntryPx';
import { SelectStrike } from './components/SelectStrike';
import { CheckedSelector } from './components/CheckedSelector';
import { StrategyCheckbox } from './components/StrategyCheckbox';
import { InputVolatility } from './components/InputVolatilities';

import Table from './TableSimulator';

import {
	strategyUpdate,
	deletePosition,
	setSavedStrategy,
} from 'store/modules/simulator/actions';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { getPaper } from './utils';
import { OutlinedLabel } from 'components/OutlinedLabel';

const PositionList = ({ quotationServiceFunc }) => {
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const { t } = useTranslation();
	const dispatch = useDispatch();

	const paperPrice = useSelector(getPaper)?.price;

	const data = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy]
				?.positions || []
	);
	const wstrategy = useSelector(
		state =>
			state.simulator.strategyList[state.simulator.activeStrategy] || []
	);

	function verifyITM(position) {
		if (!position.exitPxLocked && !position.expired) {
			if (position.optionType === 'C' && position.strikePx < paperPrice) {
				return true;
			}

			if (position.optionType === 'P' && position.strikePx > paperPrice) {
				return true;
			}
		}

		return false;
	}

	const calculatedPositions = data.map(position => {
		const isInTheMoney = verifyITM(position);

		if (isInTheMoney) {
			return { ...position, itm: true };
		} else {
			return { ...position, itm: false };
		}
	});

	const hasSomeITM = calculatedPositions.some(position => position.itm);

	const strikePxColumnWidth = hasSomeITM ? 30 : '';

	const columns = useMemo(
		() => [
			{
				Header: '',
				className: 'border-right',
				id: '_',
				columns: [
					{
						accessor: 'reorder',
						className: 'cell-center',
						width: 10,
						Cell: (
							<Tooltip
								arrow
								title={t('simulator.tooltip.moveIcon')}
							>
								<IconButton
									size="small"
									style={{ cursor: 'grab' }}
								>
									<DragIndicator />
								</IconButton>
							</Tooltip>
						),
					},
					{
						accessor: 'delete',
						width: 10,
						className: 'cell-center',
						Cell: ({ cell }) => {
							return (
								<Tooltip
									arrow
									title={t('simulator.tooltip.closeIcon')}
								>
									<IconButton
										size="small"
										color="secondary"
										onClick={() =>
											handleDeletePosition(
												cell.row.original
											)
										}
									>
										<Close />
									</IconButton>
								</Tooltip>
							);
						},
					},
					{
						Header: <StrategyCheckbox />,
						accessor: 'checked',
						width: 10,
						className: 'cell-center',
						Cell: ({ value, cell }) => (
							<CheckedSelector
								value={value}
								position={cell.row.original}
							/>
						),
					},
					{
						Header: t('simulator.table.buy&sell'),
						className: 'cell-center',
						Cell: ({ cell }) => (
							<InputSide position={cell.row.original} />
						),
					},
					{
						Header: t('simulator.table.qtty'),
						accessor: 'qtty',
						className: 'cell-center',
						Cell: ({ value, cell }) => (
							<InputQtty
								value={value}
								position={cell.row.original}
							/>
						),
					},
					{
						Header: t('simulator.table.expirationDate'),
						accessor: 'expirationDate',
						className: 'cell-center',
						width: 600,
						Cell: ({ value, cell }) =>
							cell.row.original.optionType && (
								<SelectSerie
									value={value}
									label="maturityDate"
									accessor="expirationDate"
									position={cell.row.original}
								/>
							),
					},
					{
						Header: t('simulator.table.strikePx'),
						accessor: 'strikePx',
						className: 'border-right cell-center',
						width: 1000,
						Cell: ({ value, cell }) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									px: 0.25,
									gap: 0.25,
								}}
							>
								<SelectStrike
									value={value}
									accessor="strikePx"
									label="strikePrice"
									position={cell.row.original}
								/>

								<OutlinedLabel
									tooltip="Strike dentro do dinheiro"
									label={cell.row.original.itm ? 'ITM' : ''}
									color="#ff7777"
									fontWeight="bold"
									style={{
										border: 'none',
										padding: 0,
										width: strikePxColumnWidth,
									}}
								/>

								<IconButton
									size="small"
									onClick={() =>
										addWidgetFromButton(
											cell.row.original.symbol
										)
									}
									disabled={
										useSelector(
											state =>
												state.configs.widgetIsLoading
										) || cell.row.original.expired
									}
								>
									<LightTooltip arrow title="Abrir book">
										<Book />
									</LightTooltip>
								</IconButton>
							</Box>
						),
					},
				],
			},
			{
				Header: t('simulator.table.theoretic'),
				id: 'Teórica',
				className: 'border-right cell-center titleCenter tittle-right',
				columns: [
					{
						Header: t('simulator.table.theorImpliedVol'),
						accessor: 'theorImpliedVol',
						className: 'cell-center',
						Cell: ({ cell, value }) => {
							const position = cell.row.original;

							return (
								position.optionType && (
									<InputVolatility
										value={value}
										accessor="theorImpliedVol"
										position={position}
									/>
								)
							);
						},
						Footer: <CellFooter accessor="theorPx" />,
					},
					{
						Header: t('simulator.table.theorPx'),
						accessor: 'theorPx',
						className: 'border-right cell-center',
						Cell: ({ cell, value }) => {
							return (
								<InputTheorPx
									value={value}
									position={cell.row.original}
								/>
							);
						},
					},
				],
			},
			{
				Header: t('simulator.table.entry'),
				id: 'Entrada',
				className: 'border-right cell-center titleCenter',
				columns: [
					{
						Header: t('simulator.table.entryImpliedVol'),
						accessor: 'entryImpliedVol',
						className: 'cell-center',
						Cell: ({ cell, value }) => {
							const position = cell.row.original;

							return (
								position.optionType && (
									<InputVolatility
										value={value}
										position={position}
										accessor="entryImpliedVol"
									/>
								)
							);
						},
						Footer: (
							<CellFooter accessor="entryPx" paddingRight={37} />
						),
					},
					{
						Header: t('simulator.table.entryPx'),
						accessor: 'entryPx',
						className: 'border-right tittle-right',
						Cell: ({ cell, value }) => {
							const position = cell.row.original;
							return (
								<InputEntryPx
									value={value}
									position={position}
									quotationServiceFunc={quotationServiceFunc}
								/>
							);
						},
					},
				],
			},
			{
				Header: t('simulator.table.exit'),
				id: 'Saída',
				className: 'border-right cell-center titleCenter',
				columns: [
					{
						Header: t('simulator.table.exitImpliedVol'),
						className: 'cell-center',
						accessor: 'exitImpliedVol',
						Cell: ({ cell, value }) => {
							const position = cell.row.original;

							return (
								position.optionType && (
									<InputVolatility
										value={value}
										position={position}
										accessor="exitImpliedVol"
									/>
								)
							);
						},
						Footer: (
							<CellFooter accessor="exitPx" paddingRight={37} />
						),
					},
					{
						Header: <HeaderExit />, //'Preço',
						accessor: 'exitPx',
						className: 'border-right tittle-right',
						Cell: ({ cell, value }) => {
							const position = cell.row.original;
							return (
								<InputExitPx
									value={value}
									position={position}
								/>
							);
						},
					},
				],
			},
			{
				Header: '',
				id: 'result',
				className: 'border-right cell-center',
				columns: [
					{
						Header: 'Resultado',
						accessor: 'result',
						className: 'border-right cell-right',
						Cell: ({ cell }) => (
							<CellResult
								position={cell.row.original}
								accessor="result"
							/>
						),
						Footer: (
							<CellFooter
								accessor="result"
								className="cell-right"
							/>
						),
					},
				],
			},
			{
				Header: <HeaderGreek />,
				id: 'greeks',
				columns: [
					{
						Header: 'Delta',
						accessor: 'Delta',
						className: 'cell-center',
						Cell: ({ cell }) => (
							<Cell
								accessor="delta"
								position={cell.row.original}
							/>
						),
						Footer: (
							<CellFooter
								accessor="delta"
								className="v-align-center"
							/>
						),
					},
					{
						Header: 'Gama',
						accessor: 'Gama',
						className: 'cell-center',
						Cell: ({ cell }) => (
							<Cell
								accessor="gamma"
								position={cell.row.original}
							/>
						),
						Footer: (
							<CellFooter
								accessor="gamma"
								className="v-align-center"
							/>
						),
					},
					{
						Header: 'Theta',
						accessor: 'Theta',
						className: 'cell-center',
						Cell: ({ cell }) => (
							<Cell
								accessor="theta"
								position={cell.row.original}
							/>
						),
						Footer: (
							<CellFooter
								accessor="theta"
								className="v-align-center"
							/>
						),
					},
					{
						Header: 'Vega',
						accessor: 'Vega',
						className: 'cell-center',
						Footer: (
							<CellFooter
								accessor="vega"
								className="v-align-center"
							/>
						),
						Cell: ({ cell }) => (
							<Cell
								accessor="vega"
								position={cell.row.original}
							/>
						),
					},
					{
						Header: 'Rho',
						accessor: 'Rho',
						className: 'cell-center',
						Cell: ({ cell }) => (
							<Cell accessor="rho" position={cell.row.original} />
						),
						Footer: (
							<CellFooter
								accessor="rho"
								className="v-align-center"
							/>
						),
					},
				],
			},
		],
		[activeStrategy, hasSomeITM]
	);

	function handleDeletePosition(position) {
		dispatch(deletePosition(position, activeStrategy));
	}

	return (
		<Table
			columns={columns}
			data={calculatedPositions}
			setRowsTable={positions => {
				dispatch(strategyUpdate({ ...wstrategy, positions }));
				dispatch(setSavedStrategy(activeStrategy, false));
			}}
		/>
	);
};
export default PositionList;
