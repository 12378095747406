import {
	BusinessCenter,
	CloudUploadOutlined,
	SpaceDashboardRounded,
} from '@mui/icons-material';
import {
	LeftSimulator,
	TemporaryGraph,
	ScannerIcon,
	OptionTrader,
	RiskIcon,
} from 'assets/icons/IconPack';
import { store } from 'store';

export function getApplicationModulesList() {
	const { profile: userProfile , riskEnabled } = store.getState().auth;

	return [
		{
			path: '/dashboard',
			label: 'Acompanhamento',
			icon: SpaceDashboardRounded,
			permission: true,
		},
		{
			path: '/posicoes',
			label: 'Posições',
			icon: BusinessCenter,
			permission: ![4].includes(Number(userProfile)),
		},
		{
			path: '/simulator',
			label: 'Simulador',
			icon: TemporaryGraph,
			permission: true,
		},
		{
			path: '/cadeia-opcoes',
			label: 'Options Trader',
			icon: OptionTrader,
			permission: true,
		},
		{
			path: '/vol-charts',
			label: 'Vol. Studio',
			icon: LeftSimulator,
			permission: true,
		},
		{
			path: '/scanner',
			label: 'Scanner',
			icon: ScannerIcon,
			permission: true,
		},
		{
			path: '/riscos',
			label: 'Limite Operacional',
			icon: RiskIcon,
			permission: ![4].includes(Number(userProfile)) && riskEnabled,
		},
		{
			path: '/upload-estrategias',
			label: 'Upload de estratégias',
			icon: CloudUploadOutlined,
			permission: [8].includes(Number(userProfile)),
		}
	];
}
