import { store } from 'store';
import {
	replaceCustomParameters,
	strategyUpdate,
	updateCustomParameters,
} from 'store/modules/bottomBullet/actions';

import { getNumOfLegs } from 'utils/strategies';
import { getStrategyTemplate } from 'utils/templates';
import { updateStrategyInfoWithLastQuotes } from 'services/lastQuotes';
import { findShortestTime } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';

export async function setPaperInfo(legIndex, data) {
	const bullet = store.getState().bottomBullet.bullet;
	const order = bullet.content;

	if (legIndex === 'band') {
		order.CustomParameters.BandPriceSymbol = data.symbol;
		order.CustomParameters.BandPriceExchange = data.subExchange;
	} else {
		order.StrategyLegs[legIndex] = {
			...order.StrategyLegs[legIndex],
			LegSymbol: data.symbol,
			LegSecurityExchange: data.subExchange,
			LegMaxClipSize: data.roundLot,
			LegMinClipSize: data.roundLot,
			LegQuantity: data.minOrderQty,
		};

		bullet.StrategyInfos[legIndex] = {
			...bullet.StrategyInfos[legIndex],
			symbol: data.symbol,
			minOrderQty: data.minOrderQty,
			maxOrderQty: data.maxOrderQty,
			minPriceIncrement: data.minPriceIncrement,
			subExchange: data.subExchange,
			contractMultiplier: data.contractMultiplier,
			roundLot: data.roundLot,
			securityType: data.securityType,
			maturityDate: data.maturityDate,
			tickSizeDenominator: data.tickSizeDenominator,
			strikePrice: data.strikePrice,
			workDaysUntilMaturity: data.workDaysUntilMaturity,
			optionType: data.optionType,
			asset: data.asset,
			underlyingSymbol: data.underlyingSymbol,
			openHour: data.openHour,
			closeHour: data.closeHour,
		};
	}

	if (bullet.content.StrategyCode === 'cvvol') {
		if (bullet.StrategyInfos[0].optionType === 'CALL') {
			order.StrategyLegs[0].LegSide = '1';
			order.StrategyLegs[1].LegSide = '2';
		} else {
			order.StrategyLegs[0].LegSide = '1';
			order.StrategyLegs[1].LegSide = '1';
		}

		if (data.securityType === 'OPT') {
			setTimeout(() => {
				store.dispatch(
					updateCustomParameters({
						DaysToExpiration: data.workDaysUntilMaturity,
					})
				);
			}, 1000);
		}
	} else if (bullet.content.StrategyCode === 'skew') {
		if (legIndex === 0) {
			store.dispatch(
				updateCustomParameters({
					DaysToExpiration1:
						bullet.StrategyInfos[0].workDaysUntilMaturity,
				})
			);
		} else if (legIndex === 1) {
			store.dispatch(
				updateCustomParameters({
					DaysToExpiration2:
						bullet.StrategyInfos[1].workDaysUntilMaturity,
				})
			);
		} else {
			return;
		}
	} else if (
		bullet.content.StrategyCode === 'grdlin' ||
		bullet.content.StrategyCode === 'grddin'
	) {
		setTimeout(() => {
			store.dispatch(
				updateCustomParameters({
					OrderQuantity: data.minOrderQty,
					NumberOfEntrances: 1,
					IncrementPriceStepEntrance: data.minPriceIncrement * 2,
					IncrementPriceStopGain: data.minPriceIncrement * 2,
					EntrancePrice: null,
					NumberOfMinPriceIncrementsToStart: 0,
					UseStartWhenCross: 'N',
					StartWhenCross: 0,
					SelectStopByPrice1: 0,
					StopByPrice1: null,
					SelectStopByPrice2: 0,
					StopByPrice2: null,
					StopLossFinance: null,
					UseStopGainGlobalFinance: 'N',
					StopGainGlobalFinance: null,
					SendStopLossOnEnd: 'Y',
					UsePreservation: 'N',
					PreservationStartTrigger: null,
					PreservationPercentual: null,
					PreservationStep: 10,
					UseRetroaliment: 'N',
					MaximumZeroTimeWindow: null,
					RetroalimentType: 5,
					RetroalimentParam1: null,
					ZerarStopLoss: 'Y',
					RetroalimentTimeWindow: 120,
				})
			);
		}, 1000);
	} else if (bullet.content.StrategyCode === 'lgshort') {
		if (bullet.content.CustomParameters.QuantityMode === 1) {
			order.StrategyLegs[legIndex] = {
				...order.StrategyLegs[legIndex],
				LegPrice: 0,
			};
		}
	}

	if (getNumOfLegs(order.StrategyCode) === 1) {
		const defaultTemplate = getStrategyTemplate(order.StrategyCode);

		if (order.StrategyCode === 'fxorder' && bullet.subUid === 3) {
			defaultTemplate.CustomParameters.stop = false;
			defaultTemplate.CustomParameters.StopType = 2;
		}

		store.dispatch(
			replaceCustomParameters(defaultTemplate.CustomParameters)
		);
	}

	const endTimes = [];
	const initTimes = [];
	bullet.StrategyInfos.forEach((info) => {
		endTimes.push(info.closeHour);
		initTimes.push(info.openHour);
	}); 
	order.EndTime = findShortestTime(endTimes, 1);
	order.InitTime = findShortestTime(initTimes, 2);
	await updateStrategyInfoWithLastQuotes(data.symbol, legIndex, bullet);
	store.dispatch(strategyUpdate(order));
}
