import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { format, isAfter, isValid, startOfDay } from 'date-fns';

import { Box, Grid } from '@mui/material';
import { useGridApiRef } from '@mui/x-data-grid-pro';

import { StyledDataGridPro } from 'components/Portfolio/styles';
import { ViewExecutionsToolbar } from './ViewExecutionsToolbar';
import { ViewExecutionsSearchForm } from './ViewExecutionsSearchForm';
import { ViewExecutionsCustomPagination } from './ViewExecutionsCustomPagination';
import { MaterialSnackbar } from 'components/MaterialComponents/MaterialSnackbar';

import { apiAWS, apiCadastro } from 'services/api';
import { postExecutionsPreferences } from 'services/userPreferences/executions';
import {
	setRows,
	setStateValues,
	updateFormValues,
	updatePaginationInfo,
	updateSearchPaginate,
	updateUserPreferences,
} from 'store/modules/executions/actions';
import { executionsInitialState } from 'store/modules/executions/reducer';
import { refreshUpdateAt } from 'utils/executionsHelpers';
import { getGroupAccountsOptions } from 'utils/getGroupAccounts';
import { executionsColumns } from 'utils/tablesColumns/executions';

export const ViewExecutions = () => {
	document.title = 'Ver Execuções | Flexscan';

	const apiRef = useGridApiRef();
	const dispatch = useDispatch();

	const {
		rows,
		searchForm,
		paginationInfo,
		searchPaginate,
		tablePreferences,
		filtersTransactionTabs,
		updatedAt,
	} = useSelector(state => state.executions);
	const { token, username, accounts, userType, profile } = useSelector(
		state => state.auth
	);
	const [snackbarState, setSnackbarState] = useState({
		isOpen: false,
		message: '',
		severity: 'warning',
	});

	const groupAccounts = getGroupAccountsOptions(accounts);

	const [isLoading, setIsLoading] = useState(false);

	function getQueryParams(filters) {
		const queryParams = { ...filters, detailed: 1 };

		Object.keys(queryParams).forEach(key => {
			if (['all', ''].includes(queryParams[key])) {
				delete queryParams[key];
			}

			if (key === 'active') {
				queryParams.symbol = queryParams[key]?.trim();
				delete queryParams[key];
			}

			if (key === 'date' && queryParams[key] !== '') {
				const formattedDate = format(queryParams[key], 'yyyy-MM-dd');
				queryParams.date = formattedDate;
			}
		});

		return queryParams;
	}

	const validateSearchForm = searchForm => {
		if (!searchForm.cblc) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe uma conta pra realizar a pesquisa!',
			});
			return;
		}

		if (!searchForm.date) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe a data para realizar a pesquisa!',
			});
			return;
		}

		if (!isValid(searchForm.date)) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message: 'Informe uma data valida para realizar a pesquisa!',
			});
			return;
		}

		if (isAfter(startOfDay(searchForm.date), startOfDay(new Date()))) {
			setSnackbarState({
				isOpen: true,
				severity: 'warning',
				message:
					'Não é possivel realizar uma pesquisa com uma data futuro!',
			});
			return;
		}

		return true;
	};

	async function handleSearch(
		paginationInfo = executionsInitialState.paginationInfo,
		searchForm,
		event
	) {
		if (event) event.preventDefault();

		if (validateSearchForm(searchForm)) {
			setIsLoading(true);

			try {
				const queryParams = {
					params: {
						...getQueryParams(searchForm),
						limit: paginationInfo.limit,
						offset: paginationInfo.offset,
					},
				};

				const { data } = await apiAWS.get('/executions', queryParams);

				const formattedExecutions = data.result.map(element => {
					element.id = `${element.Symbol}${element.StrategyId}${element.ExecID}`;

					return element;
				});

				const formattedRowsLength = formattedExecutions.length;

				if (formattedRowsLength >= 26) {
					formattedExecutions.pop();
				}

				const newPageInfo = {
					...executionsInitialState.paginationInfo,
					num_records: formattedRowsLength,
					page: paginationInfo.page,
					offset: paginationInfo.offset,
					total: paginationInfo.total
						? paginationInfo.total + 25
						: executionsInitialState.paginationInfo.total,
				};

				dispatch(setStateValues('filtersTransactionTabs', false));
				dispatch(setStateValues('updatedAt', refreshUpdateAt()));
				dispatch(setRows(formattedExecutions));
				dispatch(updatePaginationInfo(newPageInfo));
				dispatch(updateSearchPaginate(searchForm));
			} catch (error) {
				console.log(error);

				dispatch(setRows([]));
				dispatch(setStateValues('updatedAt', refreshUpdateAt()));
				dispatch(setStateValues('filtersTransactionTabs', false));
				dispatch(
					updatePaginationInfo(executionsInitialState.paginationInfo)
				);
				dispatch(updateSearchPaginate(searchForm));

				setSnackbarState({
					isOpen: true,
					severity: 'error',
					message:
						'Problema ao processar solicitação! Tente novamente em alguns instantes.',
				});
			} finally {
				setIsLoading(false);
			}
		}
	}

	async function exportExecutionsReport() {
		setIsLoading(true);

		const params = getQueryParams(searchPaginate);

		if (params.detailed) delete params.detailed;

		const body = {
			...params,
			report: 'trades',
		};

		try {
			const headers = { headers: { Authorization: `Bearer ${token}` } };

			const { data } = await apiCadastro.post('/reports', body, headers);

			window.open(data.url, '_self');
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	// Associonada  Quando há algum evento na seção de filtros do toolbar
	function onFilterModelChange(event) {
		dispatch(
			updateUserPreferences({ filter: { filterModel: { ...event } } })
		);

		postExecutionsPreferences(username, {
			tableFilter: { filterModel: { ...event } },
		});
	}

	// Associonada quando mudamos uma coluna de posição (quando trocamos elas ao arrasta-la)
	function onColumnOrderChange() {
		const columnsOrder = apiRef.current.getAllColumns().map(column => {
			return column.field;
		});

		dispatch(
			updateUserPreferences({
				columns: { orderedFields: [...columnsOrder] },
			})
		);

		postExecutionsPreferences(username, {
			orderedFields: [...columnsOrder],
		});
	}

	//Associonada  quando mudamos a visibilidade de uma coluna
	function onColumnVisibilityModelChange(event) {
		dispatch(
			updateUserPreferences({
				columns: { columnVisibilityModel: { ...event } },
			})
		);

		postExecutionsPreferences(username, {
			columnVisibilityModel: { ...event },
		});
	}

	// Associonado quando clicamos para realizar o sorte de uma coluna
	function onSortModelChange(event) {
		const sorting = { sorting: { sortModel: [...event] } };

		dispatch(updateUserPreferences(sorting));

		postExecutionsPreferences(username, sorting);
	}

	// Associonada a qualquer evento da tabela
	function onTableStateChange(event) {
		if (event.density.value !== tablePreferences.density) {
			const density = { density: event.density.value };

			dispatch(updateUserPreferences(density));
			postExecutionsPreferences(username, density);
		}
	}

	useEffect(() => {
		if (profile !== 4) {
			let formSearch = { ...searchForm };

			if (userType.id === 3 && !searchForm.cblc && groupAccounts.length > 0) {
				const [firstCblc] = groupAccounts;

				formSearch.cblc = firstCblc;

				dispatch(updateFormValues('cblc', firstCblc));
			}

			if (formSearch.cblc) {
				handleSearch(paginationInfo, formSearch, null);
			}
		}
	}, []);

	useEffect(() => {
		if (profile !== 4) {
			if (filtersTransactionTabs) {
				handleSearch(
					{
						...paginationInfo,
						page: 0,
						offset: 0,
						total: null,
					},
					searchForm,
					null
				);
			}
		}
	}, [filtersTransactionTabs]);

	return (
		<Grid
			container
			sx={{
				gap: 0.5,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				p: '1.5rem 0 0.5rem',
			}}
		>
			<ViewExecutionsSearchForm
				isLoading={isLoading}
				handleSearch={handleSearch}
				groupAccounts={groupAccounts}
				setSnackbarState={setSnackbarState}
			/>

			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexGrow: 1,
				}}
			>
				<StyledDataGridPro
					apiRef={apiRef}
					rows={rows}
					loading={isLoading}
					columns={executionsColumns}
					components={{
						Pagination: () =>
							ViewExecutionsCustomPagination(apiRef, paginationInfo),
						Toolbar: () =>
							ViewExecutionsToolbar(updatedAt, exportExecutionsReport, profile, isLoading),
					}}
					initialState={{
						columns: tablePreferences?.columns,
						sorting: tablePreferences?.sorting,
						filter: tablePreferences?.filter,
						pagination: {
							paginationModel: { pageSize: 25, page: 0 },
						},
					}}
					density={tablePreferences?.density ?? 'standard'}
					pagination
					paginationMode="server"
					rowsPerPageOptions={[25]}
					page={paginationInfo.page}
					rowCount={paginationInfo.total}
					pageSize={25}
					onPageChange={newPage =>
						handleSearch(
							{
								...paginationInfo,
								page: newPage,
								offset: newPage * 25,
							},
							searchForm
						)
					}
					onFilterModelChange={event => onFilterModelChange(event)}
					onColumnOrderChange={event => onColumnOrderChange(event)}
					onColumnVisibilityModelChange={event =>
						onColumnVisibilityModelChange(event)
					}
					onSortModelChange={event => onSortModelChange(event)}
					onStateChange={event => onTableStateChange(event)}
				/>
			</Box>

			<MaterialSnackbar
				open={snackbarState.isOpen}
				severity={snackbarState.severity ?? 'warning'}
				handleClose={() =>
					setSnackbarState({
						isOpen: false,
						message: '',
					})
				}
			>
				{snackbarState.message}
			</MaterialSnackbar>
		</Grid>
	);
};
