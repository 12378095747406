import React, { memo, useState, useEffect, createRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from 'components/Dropdown';

import {
	addWidget,
	removeWidget,
	updateWidget,
	updateWidgetList,
	expandWidget,
} from 'store/modules/widgets/actions';

import { updateConfig } from 'store/modules/panelConfig/actions';
import { Accordion, AccordionDetails, Box, IconButton } from '@mui/material';
import {
	AddCircle,
	ArrowDropDown,
	ArrowDropUp,
	Cancel,
	Menu,
} from '@mui/icons-material';
import SymbolQuotation from './SymbolQuotation';
import BlackScholesCalculator from './BlackScholesCalculator';

import { Container, PanelSummaryContainer, StyledSummary } from './styles';

function Widgets({ quotationServiceFunc, widgetOpen, setIsTyping }) {
	const dispatch = useDispatch();
	const { widgets } = useSelector(state => state.widgets) || { widgets: [] };
	const [localWidgets, setLocalWidgets] = useState([]);
	const [dropdown, setDropdown] = useState([]);
	const lockButtons = useSelector(state => state.configs.widgetIsLoading);
	const newWidget = {
		type: 'BookTrade',
		expanded: true,
		content: {
			definition: '',
			type: 'book',
			symbol: '',
			connection: '',
			paper: {
				price: null,
				var: null,
				highPx: 0.0,
				lowPx: 0.0,
				finVol: 0.0,
				openPx: 0.0,
				closePx: 0.0,
			},
			book: {
				bidQuantities: [],
				bidPrices: [],
				askPrices: [],
				askQuantities: [],
			},
		},
	};

	const initialContent = {
		BlackScholesCalculator: {
			s: 0.18,
			k: 0.01,
			v: 0.01,
			dte: 1,
			ir: 2.0,
			optType: 'C',
			delta: '0,0000',
			gamma: '0,0000',
			theta: '0,0000',
			vega: '0,0000',
			rho: '0,0000',
			price: '0,00',
		},
		BookTrade: {
			definition: '',
			type: 'book',
			symbol: '',
			connection: '',
			paper: {
				price: null,
				var: null,
				highPx: 0.0,
				lowPx: 0.0,
				finVol: 0.0,
				openPx: 0.0,
				closePx: 0.0,
			},
			book: {
				bidQuantities: [],
				bidPrices: [],
				askPrices: [],
				askQuantities: [],
			},
		},
	};

	const refs = localWidgets.map(() => createRef());

	useEffect(() => {
		setLocalWidgets([...widgets]);
	}, [widgets]);

	const options = [
		{
			id: 3,
			name: 'Resumo do Papel',
		},
		{
			id: 6,
			name: 'Calculadora Black & Scholes',
		},
	];

	const handleChange = (type, panel) => {
		let newWidgets;
		if (type === 'add') {
			dispatch(expandWidget(panel, true));
			newWidgets = localWidgets.map(item => {
				return item.id === panel
					? { ...item, expanded: type === 'add' }
					: item;
			});
		} else {
			newWidgets = localWidgets.map(item => {
				return item.id === panel
					? { ...item, expanded: type === 'add' }
					: item;
			});
			dispatch(expandWidget(panel, false));
		}

		setLocalWidgets(newWidgets);
	};

	const handleAddWidget = type => {
		dispatch(addWidget(type));
		const clonedLocalWidgets = [...localWidgets];
		const widgtetNew = { ...newWidget };
		widgtetNew.id = Math.random();
		clonedLocalWidgets.push(widgtetNew);

		setLocalWidgets(clonedLocalWidgets);
	};

	const handleRemoveWidget = (id, index) => {
		setIsTyping(false);

		refs[index].current.disconnectRemoveWidget();

		dispatch(removeWidget(id));

		const newWidgets = localWidgets.filter(item => item.id !== id);

		setLocalWidgets(newWidgets);
		dispatch(updateConfig('localWidgets', newWidgets));
	};

	const handleDropdown = id => {
		id === dropdown || id === '' ? setDropdown('') : setDropdown(id);
	};

	const handleClickMenu = (widgetId, type, index) => {
		let typeName;
		switch (type) {
			case 3:
				typeName = 'BookTrade';
				break;
			case 6:
				refs[index].current.disconnectRemoveWidget();
				typeName = 'BlackScholesCalculator';
				break;
			default:
				break;
		}
		dispatch(updateWidget(widgetId, typeName));

		const newWidgets = localWidgets.map(item => {
			return item.id === widgetId
				? { ...item, type: typeName, content: initialContent[typeName] }
				: item;
		});

		setLocalWidgets(newWidgets);

		handleDropdown('');
	};

	const handleUpdate = (id, content) => {
		const clonedLocalWidgets = [...localWidgets];

		const newWidgets = clonedLocalWidgets.map(item => {
			return item.id === id ? { ...item, content } : item;
		});

		//Verificando se o widget existe na lista de widgets
		const newWidget = clonedLocalWidgets.filter(item => item.id === id);

		if (newWidget.length > 0) {
			//Quando o simbolo for vazio é um widget novo e assim adicionamos ele na lista e atualizamos o redux
			if (newWidget[0].content.symbol === '') {
				dispatch(updateWidgetList(newWidgets));
				setLocalWidgets(newWidgets);
			}

			//Quando o simbolo é atualizado no widget, atualizamos a lista de widgets sem adicionar um novo
			if (newWidget[0].content.symbol !== content.symbol) {
				dispatch(updateWidgetList(newWidgets));
				setLocalWidgets(newWidgets);
			}
		}

		dispatch(updateConfig('localWidgets', newWidgets));
	};

	return (
		<Container>
			{localWidgets &&
				localWidgets.map((widget, index) => (
					<Accordion
						key={widget.id}
						square
						expanded={widget.expanded}
						sx={{
							'& .Mui-expanded': {
								margin: '0.25rem 0 !important',
								background: '#313131',
							},
						}}
					>
						<StyledSummary>
							<PanelSummaryContainer>
								<div className="panel-header">
									{!widget.expanded && (
										<IconButton
											size="small"
											onClick={() =>
												handleChange('add', widget.id)
											}
										>
											<ArrowDropDown
												fontSize="small"
												color="action"
											/>
										</IconButton>
									)}
									{widget.expanded && (
										<IconButton
											size="small"
											onClick={() =>
												handleChange(
													'remove',
													widget.id
												)
											}
										>
											<ArrowDropUp
												fontSize="small"
												color="action"
											/>
										</IconButton>
									)}
									<IconButton
										size="small"
										onClick={() =>
											handleDropdown(widget.id)
										}
									>
										<Menu fontSize="small" color="action" />
									</IconButton>
									<Dropdown
										id={widget.id}
										options={options}
										handleClick={handleClickMenu}
										index={index}
										visible={dropdown === widget.id}
									/>
								</div>
								<div
									className="panel-title align-items-center"
									style={{ color: 'white' }}
								>
									{widget.type === 'BookTrade' &&
										`Resumo do papel - ${widget.content.symbol}`}
									{widget.type === 'BlackScholesCalculator' &&
										'Calculadora Black & Scholes'}
								</div>
								<IconButton
									disabled={lockButtons}
									size="small"
									onClick={() =>
										handleRemoveWidget(widget.id, index)
									}
								>
									<Cancel
										fontSize="small"
										style={{ color: '#f44336' }}
									/>
								</IconButton>
							</PanelSummaryContainer>
						</StyledSummary>
						<AccordionDetails sx={{ p: 0 }}>
							{widget.type === 'BookTrade' && (
								<SymbolQuotation
									widget={widget}
									handleUpdate={handleUpdate}
									quotationServiceFunc={quotationServiceFunc}
									ref={refs[index]}
									widgetOpen={widgetOpen}
									setIsTyping={setIsTyping}
								/>
							)}
							{widget.type === 'BlackScholesCalculator' && (
								<BlackScholesCalculator
									widget={widget}
									handleUpdate={handleUpdate}
									quotationServiceFunc={quotationServiceFunc}
									ref={refs[index]}
									initialContent={
										initialContent.BlackScholesCalculator
									}
								/>
							)}
						</AccordionDetails>
					</Accordion>
				))}
			<Box pb={1} textAlign="center">
				<IconButton
					disabled={lockButtons}
					size="small"
					onClick={() => handleAddWidget('BookTrade')}
				>
					<AddCircle fontSize="large" />
				</IconButton>
			</Box>
		</Container>
	);
}
export default memo(Widgets);
