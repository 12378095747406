import { api, apiExec, apiMarketData, apiEls, apiAWS } from 'services/api';
import { toast } from 'react-toastify';

import { setBulletDrawer } from 'store/modules/strategy/actions';
import { setColorMode } from 'store/modules/ui/actions';

import {
	AUTH_SIGN_IN,
	AUTH_SIGN_OUT,
	SET_ACCOUNT_TYPE,
	SET_LAST_UPDATED,
	SET_CONTIGENCY,
	SET_VALIDATEDSIGNATURE,
	SET_DISCONNECTED,
	SET_EXECTYPE,
	SET_PORTAL_LOGIN,
	SET_USER_BLOCKED,
	SET_FAVORITE_2FA,
	SET_USER_AUTH_METHODS,
} from './actionTypes';

import {
	fetchConfigs,
	setStrategiesTabIndex,
	updateConfig,
} from '../panelConfig/actions';
import {
	setBookSubscriptionList,
	setMiniBookSubscriptionList,
	setSnapshotSubscriptionList,
	setTradeSubscriptionList,
	updateWebsocketConnection,
} from '../websocket/actions';
import { store } from '../../index';
import { setInitialStateSimulator } from '../simulator/actions';
import {
	additionalUpdate,
	handleRecentOrders,
	handleSavedOrders,
	replaceCustomParameters,
	replaceStrategyLegs,
	strategyUpdate,
} from '../bottomBullet/actions';
import { updateWidgetList } from '../widgets/actions';
import { updateStatusStreamingAll } from '../globalStatus/actions';
import { parseISO, format } from 'date-fns';
import { saveExternalStorage } from '../externalStorage/actions';
import { getStrategyTemplate } from 'utils/templates';
import {
	setActiveStrategies,
	setPortfolioStrategies,
} from '../portfolio/actions';
import { resetPositionsState } from '../positions/actions';
import { resetRiskState } from '../Risk/actions';
import { clearFormValues, setRows } from '../history/actions';
import { clearStateValues as clearStateValuesExecutions } from '../executions/actions';
import { resetCurrentStrategiesState } from '../currentStrategies/actions';
import { resetRecurrenceState } from '../recurrence/actions';
import { resetConsolidatedPositionsState } from '../consolidatedPositions/actions';
import { resetProductsState } from '../products/actions';

export const signIn = ({
	token,
	username,
	name,
	phoneCel,
	cep,
	city,
	cpf,
	account,
	accounts,
	algos,
	signature,
	email,
	execType,
	operatorCode,
	userType,
	userContractDate,
	userContractIp,
	portalAccess,
	isDisconnected,
	profile,
	registrationDate,
	riskEnabled,
	custodyEnabled,
	products,
	sponsor,
	sponsorName,
}) => ({
	type: AUTH_SIGN_IN,
	payload: {
		token,
		username,
		name,
		phoneCel,
		cep,
		city,
		cpf,
		account,
		accounts,
		algos,
		signature,
		email,
		lastUpdated: new Date(),
		execType,
		operatorCode,
		userType,
		userContractDate,
		userContractIp,
		portalAccess,
		isDisconnected,
		profile,
		registrationDate,
		riskEnabled,
		custodyEnabled,
		products,
		sponsor,
		sponsorName,
	},
});

export const signInAsync =
	({
		authToken,
		userName,
		history,
		accountType,
		handleCancelContractSign,
		userIp,
		userDate,
	}) =>
		async dispatch => {
			api.defaults.baseURL =
			accountType === 'real'
				? process.env.REACT_APP_USR_DMA_API_URL
				: process.env.REACT_APP_USR_SIM_API_URL;

			/**
		 * Adiciona token JWT em todas as chamadas subsequentes
		 */

			apiExec.defaults.baseURL =
			accountType === 'real'
				? process.env.REACT_APP_EXE_DMA_API_URL
				: process.env.REACT_APP_EXE_SIM_API_URL;

			api.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiExec.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiEls.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiMarketData.defaults.headers.common.Authorization = `Bearer ${authToken}`;
			apiAWS.defaults.headers.common.Authorization = `Bearer ${authToken}`;

			api.get(`/users/${userName}`)
				.then(user => {
					const accoutValue =
					user.data.Accounts.find(e => e.exchange === 'XBSP')?.code ??
					'';

					const algos = [];

					user.data.Algos.map(algo =>
						algos.push({
							api_code: algo.apiCode,
							atdlCode: algo.atdlCode,
							name: algo.name,
							desc: algo.desc,
							abbreviation: algo.abbreviation,
						})
					);

					// Para deixar o checbox do componente de assinatura unchecked como default
					localStorage.setItem('keep_signature', 'false');

					// adicionar chave para controle de logout em outras tabs
					localStorage.setItem('_sisemtypy', Math.random());
					/**
				 *
				 * Adiciona usuário no reducer
				 */
					const newData = {
						token: authToken,
						username: user.data.UserName,
						name: user.data.Name,
						phoneCel: user.data.PhoneCel,
						cep: user.data.Cep,
						city: user.data.City,
						cpf: user.data.Cpf,
						account: accoutValue,
						email: user.data.Email,
						accounts: user.data.Accounts,
						profile: user.data.Profile,
						algos,
						execType: user.data.ExecType,
						operatorCode: user.data.OperatorCode,
						userType: user.data.UserType,
						userContractDate: format(parseISO(userDate), 'dd/MM/yyyy'),
						userContractIp: userIp,
						portalAccess: user.data.PortalAccess ?? false,
						isDisconnected: false,
						hasPortalAdvice: 0,
						riskEnabled: user.data.RiskEnabled ?? false,
						custodyEnabled: user.data.CustodyEnabled ?? false,
						products: user.data.Products ?? [],
						sponsor: user.data.Sponsor,
						sponsorName: user.data.SponsorName,
						registrationDate: user.data.RegistrationDate,
					};

					dispatch(updateWebsocketConnection(true));
					dispatch(signIn(newData));
					dispatch(fetchConfigs());

					toast.success('Login efetuado com sucesso!');

					history.replace('/dashboard');
				})
				.catch(() => {
					handleCancelContractSign();
					dispatch(doLogout(history));
				});
		};

export const notAuthError = () => {
	return dispatch => {
		const defaultTemplate = getStrategyTemplate('sorder');
		dispatch(additionalUpdate('uid', 'sorder'));
		dispatch(additionalUpdate('subUid', 1));
		dispatch(setBulletDrawer(false));
		dispatch(setInitialStateSimulator());
		dispatch(strategyUpdate(defaultTemplate));
		dispatch(replaceCustomParameters(defaultTemplate.CustomParameters));
		dispatch(replaceStrategyLegs(defaultTemplate.StrategyLegs));
		dispatch(additionalUpdate('legs', defaultTemplate.StrategyLegs.length));

		dispatch(
			additionalUpdate(
				'StrategyInfos',
				defaultTemplate.StrategyLegs.map(() => {
					return {};
				})
			)
		);
		dispatch(additionalUpdate('isOpen', false));
		dispatch(additionalUpdate('keepSignature', ''));
		dispatch(additionalUpdate('signature', ''));
		dispatch(additionalUpdate('account', null));
		dispatch(updateWidgetList([]));
		dispatch(updateWebsocketConnection(false));
		dispatch(handleRecentOrders([]));
		dispatch(handleSavedOrders([]));
		dispatch(additionalUpdate('clone', false));
		dispatch(setBookSubscriptionList([]));
		dispatch(setTradeSubscriptionList([]));
		dispatch(setSnapshotSubscriptionList([]));
		dispatch(setMiniBookSubscriptionList([]));
		dispatch(updateStatusStreamingAll(false, false));
		dispatch(updateConfig('widgetBar', false));
		dispatch(saveExternalStorage({}, 'optionsChain'));

		// toast.error('Faça login para continuar!');
		dispatch(setBulletDrawer(false));
		dispatch(setColorMode('white'));
		localStorage.removeItem('_sisemtypy');
		dispatch(signOut());
	};
};

export const doLogout = (history, isIdle) => {
	return () => {
		const defaultTemplate = getStrategyTemplate('sorder');
		store.dispatch(additionalUpdate('uid', 'sorder'));
		store.dispatch(additionalUpdate('subUid', 1));
		store.dispatch(setBulletDrawer(false));
		store.dispatch(setInitialStateSimulator());
		store.dispatch(strategyUpdate(defaultTemplate));
		store.dispatch(
			replaceCustomParameters(defaultTemplate.CustomParameters)
		);
		store.dispatch(replaceStrategyLegs(defaultTemplate.StrategyLegs));
		store.dispatch(
			additionalUpdate('legs', defaultTemplate.StrategyLegs.length)
		);

		store.dispatch(
			additionalUpdate(
				'StrategyInfos',
				defaultTemplate.StrategyLegs.map(() => {
					return {};
				})
			)
		);

		store.dispatch(additionalUpdate('isOpen', false));
		store.dispatch(additionalUpdate('keepSignature', ''));
		store.dispatch(additionalUpdate('signature', ''));
		store.dispatch(additionalUpdate('account', null));
		store.dispatch(updateWidgetList([]));
		store.dispatch(updateWebsocketConnection(false));
		store.dispatch(handleRecentOrders([]));
		store.dispatch(handleSavedOrders([]));
		store.dispatch(additionalUpdate('clone', false));
		store.dispatch(setBookSubscriptionList([]));
		store.dispatch(setTradeSubscriptionList([]));
		store.dispatch(setSnapshotSubscriptionList([]));
		store.dispatch(setMiniBookSubscriptionList([]));
		store.dispatch(updateStatusStreamingAll(false, false));
		store.dispatch(updateConfig('widgetBar', false));
		store.dispatch(saveExternalStorage({}, 'optionsChain'));
		store.dispatch(setActiveStrategies([]));
		store.dispatch(setPortfolioStrategies([]));
		store.dispatch(resetPositionsState());
		store.dispatch(resetRiskState());
		store.dispatch(setStrategiesTabIndex(0));
		store.dispatch(setRows([]));
		store.dispatch(clearFormValues());
		store.dispatch(clearStateValuesExecutions());
		store.dispatch(resetCurrentStrategiesState());
		store.dispatch(resetRecurrenceState());
		store.dispatch(resetConsolidatedPositionsState());
		store.dispatch(resetProductsState());

		// TODO passar para o storage do redux
		store.dispatch(setColorMode('white'));

		// Remover chave para disparar logout em todas as outras tabs do browser
		localStorage.removeItem('_sisemtypy');

		if (!isIdle) {
			// Sinalizando que o usuário está sendo desconectado
			store.dispatch(setDisconnected(true));
		}
	};
};

export const signOut = () => ({ type: AUTH_SIGN_OUT });

export const setAccountType = accountType => ({
	type: SET_ACCOUNT_TYPE,
	payload: { accountType },
});

export const setLastUpdated = lastUpdated => ({
	type: SET_LAST_UPDATED,
	payload: { lastUpdated },
});

export const setContigency = contigency => ({
	type: SET_CONTIGENCY,
	payload: { contigency },
});

export const setValidatedSignature = validatedSignature => ({
	type: SET_VALIDATEDSIGNATURE,
	payload: { validatedSignature },
});

export const setExecType = execType => ({
	type: SET_EXECTYPE,
	payload: { execType },
});

export const setDisconnected = isDisconnected => ({
	type: SET_DISCONNECTED,
	payload: { isDisconnected },
});

export const setPortalLogin = isPortalLogin => ({
	type: SET_PORTAL_LOGIN,
	payload: { isPortalLogin },
});

export const setUserBlocked = isBlocked => ({
	type: SET_USER_BLOCKED,
	payload: { isBlocked },
});

export const setFavorite2FA = favoriteMethod => ({
	type: SET_FAVORITE_2FA,
	payload: { favoriteMethod },
});

export const setUserAuthMethods = authMethods => ({
	type: SET_USER_AUTH_METHODS,
	payload: { authMethods },
});
