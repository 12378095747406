import React from 'react';
import Autosuggest from 'react-autosuggest';
import '../styles/autocomplete.css';
import { apiEls } from 'services/api';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPaperSuggestions } from '../store/modules/strategy/actions';
import axios from 'axios';
import { getOptUnderlyingStockSecurityTypes } from '@investflex/iflexquantjs';

function escapeRegexCharacters(str) {
	return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

function getSuggestionValue(suggestion) {
	return suggestion.text;
}

function renderSuggestion(suggestion) {
	return <span>{suggestion.text}</span>;
}
function convertArrayToObject(array) {
	const initialValue = {};
	return array.reduce((obj, item) => {
		return {
			...obj,
			[item._source.symbol]: item._source,
		};
	}, initialValue);
}

const CancelToken = axios.CancelToken;

class PaperSearch extends React.Component {
	constructor() {
		super();

		this.state = {
			value: '',
			suggestions: [],
			selected: false,
			paperSuggestions: {},
			call: null,
			errorMessage: false,
		};

		this.onBlur = this.onBlur.bind(this);
		this.setPaperValues = this.setPaperValues.bind(this);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value });
		}

		if (
			this.props.blocked &&
			prevProps.value !== this.state.value &&
			prevProps.value !== undefined
		) {
			this.setState({ value: prevProps.value });
		}

		if (prevState.suggestions !== this.state.suggestions) {
			const objs = convertArrayToObject(this.state.suggestions);
			const local =
				JSON.parse(localStorage.getItem('paperSuggestions')) || {};
			const merged = { ...local, ...objs };
			localStorage.setItem('paperSuggestions', JSON.stringify(merged));
		}
	}

	componentDidMount() {
		const newValue = this.props.value;

		this.setState({
			value: typeof newValue !== 'undefined' ? newValue : '',
		});
	}

	onChange(event, { newValue }) {
		if (!newValue) {
			if (this.props?.onBlurWidget) this.props.onBlurWidget();
		}

		this.setState({
			value: newValue,
		});
	}

	async onBlur(event) {
		if (this.state.selected) {
			return;
		}

		const value = event.target.value;
		let definition;

		definition =
			this.state.suggestions.find(e => e.text === value.toUpperCase()) ||
			null;

		if (definition) {
			if (this.props.onlyOptions && definition.securityType !== 'OPT') {
				toast.error('Apenas opção disponível para seleção');
				return;
			}

			if (this.props.onlyPut && definition.optionType !== 'PUT') {
				toast.error('Apenas opção PUT disponível para seleção');
				return;
			}

			if (
				this.props.onlyUnderlying &&
				definition?.securityType &&
				!getOptUnderlyingStockSecurityTypes().includes(
					definition.securityType
				)
			) {
				toast.error('Apenas ativos disponível para seleção');
				return;
			}

			const response = await apiEls.get(`/search/${definition.text}`);
			if (response && response.data._source) {
				// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
				if (['DOL', 'WDO'].includes(response.data._source.asset)) {
					response.data._source.tickSizeDenominator = 2;
				}

				this.setPaperValues(response.data);
			}
		} else {
			if (value.length === 0) {
				if (this.props?.onBlurWidget) this.props.onBlurWidget();
				return;
			}

			const response = await apiEls.get(`/search/${value.toUpperCase()}`);

			if (response && response.data._source) {
				// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
				if (['DOL', 'WDO'].includes(response.data._source.asset)) {
					response.data._source.tickSizeDenominator = 2;
				}
				if (
					this.props.onlyUnderlying &&
					(!response.data._source?.securityType ||
						!getOptUnderlyingStockSecurityTypes().includes(
							response.data._source.securityType
						))
				) {
					toast.error('Apenas ativo disponível para seleção');
					return;
				}
				if (
					this.props.excludeFracionary &&
					response.data?.text &&
					response.data.text.slice(-1) === 'F'
				) {
					toast.error('Apenas ativo disponível para seleção');
					return;
				}

				this.setPaperValues(response.data);
			} else {
				if (value.length > 0) {
					if (this.state.errorMessage) {
						this.setState({ errorMessage: false });
					} else {
						toast.error(
							`O símbolo "${this.state.value.toUpperCase()}" não foi encontrado`
						);
					}
					this.input.focus();
					this.setState({
						value: '',
					});
				}
			}
		}
	}

	setPaperValues(suggestion) {
		this.props.setPaperInfo(this.props.index, suggestion._source);
		if (this.props.cleanAfterSearch) this.setState({ value: '' });
	}

	onSuggestionsFetchRequested({ value }) {
		this.setState({ selected: false });
		const escapedValue = escapeRegexCharacters(value.trim());

		if (escapedValue === '' || escapedValue.length < 3) {
			return [];
		}

		if (this.state.call) {
			this.state.call.cancel('New value searched');
		}

		const headers = {
			'content-Type': 'application/json',
		};

		this.setState(
			{
				call: CancelToken.source(),
			},
			function () {
				apiEls
					.get(`/search?symbol=${escapedValue}`, {
						cancelToken: this.state.call.token,
						headers,
					})
					.then(response => {
						const suggs =
							response.data.suggest.suggest_symbol[0].options;

						let responseArray = [...suggs];

						responseArray = responseArray.filter(
							s =>
								s.text.substring(0, escapedValue.length) ===
								escapedValue.toUpperCase()
						);

						responseArray = responseArray.sort(function (a, b) {
							return a.text > b.text
								? 1
								: b.text > a.text
									? -1
									: 0;
						});

						if (this.props.onlyOptions) {
							responseArray = responseArray.filter(
								s => s._source.securityType === 'OPT'
							);
						}

						if (this.props.onlyUnderlying) {
							responseArray = responseArray.filter(s =>
								getOptUnderlyingStockSecurityTypes().includes(
									s._source.securityType
								)
							);
						}

						if (this.props.excludeFracionary) {
							responseArray = responseArray.filter(
								s =>
									s?.text &&
									s.text.length > 0 &&
									s.text.slice(-1) !== 'F'
							);
						}

						if (this.props.onlyCall) {
							responseArray = responseArray.filter(
								s => s._source.optionType === 'CALL'
							);
						}

						this.setState({ suggestions: responseArray });
						this.setState({ call: null });
					})
					.catch(err => {
						console.log(err);
					});
			}
		);
	}

	async handleSuggestionSelected(event, { suggestion }) {
		this.setState({ selected: true });

		const response = await apiEls.get(`/search/${suggestion.text}`);

		if (response && response.data._source) {
			// workaround para ticksize de dolar e minidolar usar tickSizeDenominator com valor 2.
			if (['DOL', 'WDO'].includes(response.data._source.asset)) {
				response.data._source.tickSizeDenominator = 2;
			}
			this.setPaperValues(response.data);
		}
	}

	onSuggestionsClearRequested() {
		this.setState({
			suggestions: [],
		});
	}

	storeInputReference(autosuggest) {
		if (autosuggest !== null) {
			this.input = autosuggest.input;
			this.input.addEventListener('keypress', e => {
				if (e.key === 'Enter') {
					e.target.blur();
				}
			});
		}
	}

	render() {
		const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: this.props.placeholder || 'Papel',
			value: value,
			onChange: this.onChange.bind(this),
			onBlur: this.onBlur.bind(this),
			disabled: this.props.blocked,
			className: 'symbolValue',
			style: this.props.style,
			onFocus: this.props.onFocus,
		};

		return (
			<Autosuggest
				inputProps={inputProps}
				suggestions={suggestions}
				renderSuggestion={renderSuggestion}
				getSuggestionValue={getSuggestionValue}
				ref={this.storeInputReference.bind(this)}
				onSuggestionSelected={this.handleSuggestionSelected.bind(this)}
				onSuggestionsFetchRequested={this.onSuggestionsFetchRequested.bind(
					this
				)}
				onSuggestionsClearRequested={this.onSuggestionsClearRequested.bind(
					this
				)}
			/>
		);
	}
}
const mapStateToProps = state => ({
	paperSuggestions: state.strategy.paperSuggestions,
});

const mapDispatchToProps = dispatch =>
	bindActionCreators({ setPaperSuggestions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PaperSearch);
