import React, { useState, useLayoutEffect } from 'react';
import { useSelector } from 'react-redux';
import { TraderFactory } from '@investflex/tradejs/trader_factory';

import { CircularProgress, Box } from '@mui/material';

import '@investflex/tradejs/styles.css';
import '@investflex/simchartjs/styles.css';

import { OptionsChainBar } from './OptionsChainBar';
import { isEmptyObject } from 'utils/validators';

const workspaceId = 'w001';
const container = '#optcontainer';
const traderFactory = new TraderFactory();
var workspaceCreated = false;
var lastInstrument = null;
var optsChain = null;

// TODO: iflexapiUrl, awsapiUrl e awsToken devem ser recebidos como parámetro
// TODO: gaurdar state in redux and dynamodb and implementar restore do state no login

export default function OptionChain(props) {
	document.title = 'Cadeia de opções | Flexscan';

	const { optionsChain } = useSelector(state => state.externalStorage);
	const { token } = useSelector(state => state.auth);
	const { actionListener } = props;
	const { state } = optionsChain;
	const [showCircularProgress, setShowCircularProgress] = useState(false);
	const [paperInfo, setPaperInfo] = useState(optionsChain?.state?.instrument);

	const delay = ms => new Promise(res => setTimeout(res, ms));

	const setPaperInfoResponse = (index, data) => {
		setPaperInfo(data);
	};

	useLayoutEffect(() => {
		delay(1500);
		if (!paperInfo || isEmptyObject(paperInfo)) {
			lastInstrument = { symbol: '' };
			optsChain = traderFactory.createWorkspace(workspaceId, container, actionListener, setShowCircularProgress, paperInfo ? paperInfo : {});
			// injetar tokem do login de usuario
			optsChain.token = token;
			// definir intervalo de atualização (1 segundo)
			optsChain.refreshInterval = 1000;
			// passar url da api iflexapi
			optsChain.iflexapiUrl = 'https://apisim.flexscan.com.br';
			// passar url da api aws lambda
			optsChain.awsapiUrl = 'https://4z2z4j37p1.execute-api.us-east-1.amazonaws.com';
			// passar token lambda
			optsChain.awsToken = process.env.REACT_APP_AWS_API_TOKEN;
			// register action listener que receberá os eventos de actions do módulo
			traderFactory.drawWorkspace(workspaceId);
			workspaceCreated = true;
		} else if (workspaceCreated && lastInstrument && paperInfo?.symbol && paperInfo?.symbol !== lastInstrument?.symbol) {
			traderFactory.changeWorkspaceInstrument(workspaceId, paperInfo, setShowCircularProgress);
			lastInstrument = { ...paperInfo };
		} else if (state?.recreateFromState) {
			if (state) {
				state.token = token;
			}

			if (state?.instrument?.symbol) {
				lastInstrument = { ...paperInfo };
				optsChain = traderFactory.createWorkspaceFromState(workspaceId, container, state, actionListener, setShowCircularProgress);
				workspaceCreated = true;
			}
		} else {
			optsChain = traderFactory.instantiateWorkspace(workspaceId, setShowCircularProgress);
		}
	}, [paperInfo]);

	return (
		<>
			<OptionsChainBar
				value={paperInfo?.symbol}
				actionListener={actionListener}
				setPaperInfo={setPaperInfoResponse}
			/>

			{showCircularProgress && (
				<Box
					sx={{
						marginTop: '25%',
						justifyContent: 'center',
						zIndex: '10',
						position: 'absolute',
						marginLeft: '50%',
					}}
				>
					<CircularProgress />
				</Box>
			)}

			<div
				id="optcontainer"
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					alignContent: 'space-between',
				}}
			/>
		</>
	);
}
