import { Typography } from '@mui/material';
import React from 'react';

import { store } from 'store';
import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfoWithDefinitionApi,
} from 'store/modules/bottomBullet/actions';
import { EXM } from 'utils/bulletContext';
import {
	getSubExchangeFromSecurityType,
	getSubUid,
	hasExpiratedSymbols,
} from 'utils/strategies';
import { getStrategyTemplate } from 'utils/templates';

function getFormattedNumber(number, minDigits = null, maxDigits = null) {
	if (!number || isNaN(number)) {
		return '-';
	}

	return new Intl.NumberFormat('pt-BR', {
		minimumFractionDigits: minDigits,
		maximumFractionDigits: maxDigits ?? minDigits,
	}).format(number);
}

function getColoredClass(number) {
	if (!number || isNaN(number)) {
		return null;
	}

	return number > 0 ? 'colored-positive' : 'colored-negative';
}

function openBullet(row, field) {
	const strategyTemplate = getStrategyTemplate('sorder');
	const subUid = getSubUid(strategyTemplate);

	const fieldMappings = {
		net_pl: {
			side: +row.net_pl > 0 ? '2' : '1',
			quantity: Math.abs(row.net_pl),
		},
		buyQtty: { side: '2', quantity: +row.buyQtty },
		sellQtty: { side: '1', quantity: +row.sellQtty },
	};

	const mapping = fieldMappings[field];

	if (mapping) {
		strategyTemplate.StrategyLegs[0].LegSide = mapping.side;
		strategyTemplate.StrategyLegs[0].LegQuantity = mapping.quantity;
	}

	strategyTemplate.StrategyLegs[0].LegSymbol = row.symbol;
	strategyTemplate.StrategyLegs[0].LegSecurityExchange =
		getSubExchangeFromSecurityType(row.securityType);

	const hasExpiredSymbol = hasExpiratedSymbols(strategyTemplate.StrategyLegs);
	strategyTemplate.context = EXM;

	if (!hasExpiredSymbol) {
		store.dispatch(orderEntry(strategyTemplate, subUid));
		store.dispatch(
			updateStrategyInfoWithDefinitionApi(row.symbol, 0, false, true)
		);
		store.dispatch(additionalUpdate('isOpen', true));
		store.dispatch(additionalUpdate('isRecentListVisible', false));
		store.dispatch(additionalUpdate('isSavedListVisible', false));
	}
}

const ClickableCell = ({ value, row, field }) => {
	function handleClick() {
		if (value) {
			openBullet(row, field);
		}
	}

	return (
		<>
			<Typography
				variant="body1"
				className={!value || value === '-' ? '' : 'clickable-text'}
				onClick={handleClick}
			>
				{value ?? 0}
			</Typography>
		</>
	);
};

export const positionsColumns = [
	{
		flex: 1,
		field: 'account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'symbol',
		headerName: 'Ativo',
		type: 'string',
	},
	{
		flex: 1,
		field: 'net_pl',
		headerName: 'Posição NET',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
	{
		flex: 1,
		field: 'marcacao_mercado',
		headerName: 'Marc. Mercado',
		type: 'number',
		cellClassName: params => getColoredClass(params.value),
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'financeiro_liquido',
		headerName: 'Fin. Liq.',
		type: 'number',
		cellClassName: params => getColoredClass(params.value),
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'buyQtty',
		headerName: 'Qtd. Compra.',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
	{
		flex: 1,
		field: 'avgBuyPx',
		headerName: 'Pr. Médio Compra',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 5),
	},
	{
		flex: 1,
		field: 'sellQtty',
		headerName: 'Qtd. Venda',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
	{
		flex: 1,
		field: 'avgSellPx',
		headerName: 'Pr. Médio Venda',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 5),
	},
	{
		flex: 1,
		field: 'volume_financeiro',
		headerName: 'Vol. Financeiro',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
];

export const consolidatedPositionsColumns = [
	{
		flex: 1,
		field: 'account',
		headerName: 'Conta',
		type: 'string',
	},
	{
		flex: 1,
		field: 'symbol',
		headerName: 'Ativo',
		type: 'string',
	},
	{
		flex: 1,
		field: 'net_pl',
		headerName: 'Posição NET',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
	{
		flex: 1,
		field: 'marcacao_mercado',
		headerName: 'Valor atual',
		type: 'number',
		renderCell: params => getFormattedNumber(params.value, 2),
	},
	{
		flex: 1,
		field: 'buyQtty',
		headerName: 'Qtd. Compra.',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
	{
		flex: 1,
		field: 'sellQtty',
		headerName: 'Qtd. Venda',
		type: 'number',
		renderCell: ({ value, row, field }) => (
			<ClickableCell
				value={getFormattedNumber(value)}
				row={row}
				field={field}
			/>
		),
	},
];
