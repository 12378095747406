import { store } from 'store';
import { apiExec } from 'services/api';
import { setProducts, setUserSignatures } from 'store/modules/products/actions';

export function getProducts() {
	const params = { params: { detailed: 1 } };

	apiExec
		.get('products', params)
		.then(response => {
			store.dispatch(setProducts(response.data.records));
		})
		.catch(error => {
			console.error(error);
		});
}

export function getUserSignatures() {
	const params = { params: { detailed: true, status: true } };

	apiExec
		.get('subscriptions', params)
		.then(response => {
			store.dispatch(setUserSignatures(response.data.records));
		})
		.catch(error => {
			console.error(error);
		});
}
