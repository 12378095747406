import {
	ADD_ITEM_BOOK_SUB_LIST,
	ADD_ITEM_MINI_BOOK_SUB_LIST,
	ADD_ITEM_TRADE_SUB_LIST,
	DELETE_ITEM_BOOK_SUB_LIST,
	DELETE_ITEM_MINI_BOOK_SUB_LIST,
	DELETE_ITEM_TRADE_SUB_LIST,
	SET_BOOK_SUB_LIST,
	SET_MINI_BOOK_SUB_LIST,
	SET_TRADE_SUB_LIST,
	UPDATE_BOOK_SUB_LIST,
	UPDATE_TRADE_SUB_LIST,
	UPDATE_WEBSOCKET_CONNECTION,
	SET_SNAPSHOT_SUB_LIST,
	DELETE_ITEM_SNAPSHOT_SUB_LIST,
	ADD_ITEM_SNAPSHOT_SUB_LIST,
} from './types';

export const updateTradeSubscriptionList = subscriptionTradeList => ({
	type: UPDATE_TRADE_SUB_LIST,
	payload: { subscriptionTradeList },
});

export const updateBookSubscriptionList = subscriptionBookList => ({
	type: UPDATE_BOOK_SUB_LIST,
	payload: { subscriptionBookList },
});

export const setBookSubscriptionList = subscriptionBookList => ({
	type: SET_BOOK_SUB_LIST,
	payload: { subscriptionBookList },
});

export const setMiniBookSubscriptionList = subscriptionMiniBookList => ({
	type: SET_MINI_BOOK_SUB_LIST,
	payload: { subscriptionMiniBookList },
});

export const setTradeSubscriptionList = subscriptionTradeList => ({
	type: SET_TRADE_SUB_LIST,
	payload: { subscriptionTradeList },
});

export const setSnapshotSubscriptionList = subscriptionSnapshotList => ({
	type: SET_SNAPSHOT_SUB_LIST,
	payload: { subscriptionSnapshotList },
});

export const deleteItemSnapshotSubscriptionList = item => ({
	type: DELETE_ITEM_SNAPSHOT_SUB_LIST,
	payload: { item },
});

export const addItemSnapshotSubscriptionList = item => ({
	type: ADD_ITEM_SNAPSHOT_SUB_LIST,
	payload: { item },
});

export const deleteItemTradeSubscriptionList = symbol => ({
	type: DELETE_ITEM_TRADE_SUB_LIST,
	payload: { symbol },
});

export const deleteItemBookSubscriptionList = symbol => ({
	type: DELETE_ITEM_BOOK_SUB_LIST,
	payload: { symbol },
});

export const deleteItemMiniBookSubscriptionList = symbol => ({
	type: DELETE_ITEM_MINI_BOOK_SUB_LIST,
	payload: { symbol },
});

export const addItemBookSubscriptionList = symbol => ({
	type: ADD_ITEM_BOOK_SUB_LIST,
	payload: { symbol },
});

export const addItemMiniBookSubscriptionList = symbol => ({
	type: ADD_ITEM_MINI_BOOK_SUB_LIST,
	payload: { symbol },
});

export const addItemTradeSubscriptionList = symbol => ({
	type: ADD_ITEM_TRADE_SUB_LIST,
	payload: { symbol },
});

export const updateWebsocketConnection = isConnected => ({
	type: UPDATE_WEBSOCKET_CONNECTION,
	payload: { isConnected },
});
