import { ProductsActionTypes } from './actionTypes';

export const resetProductsState = () => ({
	type: ProductsActionTypes.RESET_PRODUCTS_STATE,
});

export const setProducts = products => ({
	type: ProductsActionTypes.SET_PRODUCTS,
	payload: { products },
});

export const setUserSignatures = signatures => ({
	type: ProductsActionTypes.SET_USER_SIGNATURES,
	payload: { signatures },
});

export const setActiveProduct = product => ({
	type: ProductsActionTypes.SET_ACTIVE_PRODUCT,
	payload: { product },
});
