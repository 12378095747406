import React from 'react';

import {
	GridToolbarExport,
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

export const HistoryToolbar = (isLoading, profile) => {
	const isButtonsDisabled = isLoading || profile === 4;

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton color="inherit" disabled={isButtonsDisabled} />

			<GridToolbarFilterButton
				color="inherit"
				disabled={isButtonsDisabled}
				title="Exibir filtros da página atual"
			/>

			<GridToolbarDensitySelector color="inherit" disabled={isButtonsDisabled} />

			<GridToolbarExport color="inherit" disabled={isButtonsDisabled} />
		</GridToolbarContainer>
	);
};
