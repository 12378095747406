import React from 'react';
import GradientBullet from '../../MaterialGradient/GradientBullet';

const GradientBodyBullet = props => {
	const { handleChange, setPaperInfo, onSuggestionsFetchRequested, bullet, stepFunction } = props;

	return (
		<>
			<GradientBullet
				handleChange={handleChange}
				setPaperInfo={setPaperInfo}
				onSuggestionsFetchRequested={onSuggestionsFetchRequested}
				subUid={bullet.subUid}
				stepFunction={stepFunction}
			/>
		</>
	);
};

export default GradientBodyBullet;
