import React, { useState, memo, forwardRef, useImperativeHandle } from 'react';
import { Box, Grid } from '@mui/material';
import { calcGreeks, calcOptionPrice } from '@investflex/iflexquantjs';
import { Container, Right, Select, ItemForm, CalcButton } from './styles';
import DecimalInput from '../../MaterialComponents/DecimalInput/index';
import { useInterestRate } from '../../../utils/getStateIr';
const BlackScholesCalculator = forwardRef(function BlackScholesCalculator(
	props,
	ref
) {
	const { quotationServiceFunc, widget, handleUpdate, initialContent } =
		props;
	const { id, content } = widget;
	const values = widget.content;
	const { ir } = useInterestRate();
	const [localValues, setLocalValues] = useState({
		s: values.s,
		k: values.k,
		v: values.v,
		dte: values.dte,
		ir: ir * 100,
		optType: values.optType,
		delta: values.delta,
		gamma: values.gamma,
		theta: values.theta,
		vega: values.vega,
		rho: values.rho,
		price: values.price,
	});
	const handleValue = (key, value, min) => {
		const obj = { ...localValues };
		if (min !== '') {
			if (value < min) {
				obj[key] = min;
			} else {
				key !== 'optType'
					? (obj[key] = parseFloat(value))
					: (obj[key] = value);
			}
		} else {
			key !== 'optType'
				? (obj[key] = parseFloat(value))
				: (obj[key] = value);
		}
		if (key === 'optType') {
			obj[key] = value;
			initialContent[key] = value;
			handleUpdate(id, initialContent);
			setLocalValues(initialContent);
			return;
		}
		setLocalValues(obj);
	};
	const handleCalc = () => {
		const { s, k, v, dte, ir, optType } = localValues;
		const pV = v / 100.0;
		const pIr = ir / 100.0;
		const greeks = calcGreeks(s, k, pV, dte, pIr, optType);
		const price = calcOptionPrice(s, k, pIr, pV, dte, optType);
		greeks.delta = greeks.delta.toFixed(4);
		greeks.gamma = greeks.gamma.toFixed(4);
		greeks.theta = greeks.theta.toFixed(4);
		greeks.vega = greeks.vega.toFixed(4);
		greeks.rho = greeks.rho.toFixed(4);
		greeks.price = price;
		const newContent = { ...localValues, ...greeks };
		setLocalValues(newContent);
		handleUpdate(id, newContent);
	};
	function stepFunction(
		stepBtnType,
		stepType,
		stepSubType,
		stepSize,
		valueToStep,
		legIndex,
		max,
		min
	) {
		if (stepBtnType === 'Up') {
			const newValue = parseFloat(localValues[stepType]) + stepSize;
			if (newValue > max) return;
			setLocalValues({
				...localValues,
				[stepType]: newValue,
			});
		} else if (stepBtnType === 'Down') {
			const newValue = parseFloat(localValues[stepType]) - stepSize;
			if (newValue < min) return;
			setLocalValues({
				...localValues,
				[stepType]: newValue,
			});
		} else {
			const newValue = parseFloat(localValues[stepType]);
			if (newValue < min) return;
			setLocalValues({
				...localValues,
				[stepType]: newValue,
			});
		}
	}

	const disconnectWidget = () => {
		const params = {
			action: 'unsubscribe',
			id: content.connection,
		};

		quotationServiceFunc(params);

		const bookParams = {
			action: 'unsubscribe',
			id: '',
		};

		quotationServiceFunc(bookParams);
	};

	useImperativeHandle(ref, () => ({
		disconnectRemoveWidget() {
			disconnectWidget();
		},
	}));
	return (
		<Container>
			<Grid
				container
				justify="center"
				spacing={1}
				style={{ padding: '10px' }}
			>
				<Grid item xs={7} style={{ paddingRight: 0 }}>
					<ItemForm>
						<label>Tipo:</label>
						<Select
							onChange={e =>
								handleValue('optType', e.currentTarget.value)
							}
							value={localValues.optType}
							textColor={localValues.optType}
						>
							<option value="C">Call</option>
							<option value="P">Put</option>
						</Select>
					</ItemForm>
					<ItemForm>
						<label>Strike:</label>
						<DecimalInput
							minVal={0.01}
							maxVal={10000}
							minDecScale={2}
							stepSize={0.01}
							stepFunction={stepFunction}
							stepType="k"
							value={localValues.k}
							valueToStep={localValues.k}
							onChange={e => handleValue('k', '', e.value, '')}
						/>
					</ItemForm>
					<ItemForm>
						<label>Pr. Ativo: </label>
						<DecimalInput
							minVal={0.01}
							maxVal={10000}
							minDecScale={2}
							stepSize={0.01}
							stepFunction={stepFunction}
							stepType="s"
							value={localValues.s}
							valueToStep={localValues.s}
							onChange={e => handleValue('s', '', e.value, '')}
						/>
					</ItemForm>
					<ItemForm>
						<label>Dias p/ exp:</label>
						<DecimalInput
							minVal={1}
							maxVal={1000}
							minDecScale={0}
							stepSize={1}
							stepFunction={stepFunction}
							stepType="dte"
							value={localValues.dte}
							valueToStep={localValues.dte}
							onChange={e => handleValue('dte', '', e.value, '')}
						/>
					</ItemForm>
					<ItemForm>
						<label>Vol: (%) </label>
						<DecimalInput
							minVal={0.01}
							maxVal={100}
							minDecScale={2}
							stepSize={0.01}
							stepFunction={stepFunction}
							stepType="v"
							value={localValues.v}
							valueToStep={localValues.v}
							onChange={e => handleValue('v', '', e.value, '')}
						/>
					</ItemForm>
					<ItemForm>
						<label>Juros: (%)</label>
						<DecimalInput
							minVal={0.01}
							maxVal={100}
							minDecScale={2}
							stepSize={0.01}
							stepFunction={stepFunction}
							stepType="ir"
							value={localValues.ir}
							valueToStep={localValues.ir}
							onChange={e => handleValue('ir', '', e.value, '')}
						/>
					</ItemForm>
				</Grid>
				<Grid item xs={5}>
					<ItemForm>
						<div className="label-result">Pr. Teor. </div>
						<Right>
							{isNaN(parseFloat(localValues.price).toFixed(2))
								? '--'
								: parseFloat(localValues.price).toFixed(2)}
						</Right>
					</ItemForm>
					<ItemForm>
						<div className="label-result">Delta </div>
						<Right>
							{isNaN(localValues.delta)
								? '--'
								: localValues.delta}
						</Right>
					</ItemForm>
					<ItemForm>
						<div className="label-result">Gama</div>
						<Right>
							{isNaN(localValues.gamma)
								? '--'
								: localValues.gamma}
						</Right>
					</ItemForm>
					<ItemForm>
						<div className="label-result">Theta</div>
						<Right>
							{isNaN(localValues.theta)
								? '--'
								: localValues.theta}
						</Right>
					</ItemForm>
					<ItemForm>
						<div className="label-result">Vega</div>
						<Right>
							{isNaN(localValues.vega) ? '--' : localValues.vega}
						</Right>
					</ItemForm>
					<ItemForm>
						<div className="label-result">Rho</div>
						<Right>
							{isNaN(localValues.rho) ? '--' : localValues.rho}
						</Right>
					</ItemForm>
				</Grid>
				<Box width="100%">
					<CalcButton onClick={() => handleCalc()}>
						Calcular
					</CalcButton>
				</Box>
			</Grid>
		</Container>
	);
});
export default memo(BlackScholesCalculator);
