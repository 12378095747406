import React, { useEffect, useState } from 'react';
import * as d3 from 'd3';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { simChart } from '@investflex/simchartjs/simChart';

import { Grid, LinearProgress } from '@mui/material';

import { store } from 'store';
import { apiMarketData, apiAWS } from 'services/api';
import { extractPositionsFromDescriptor } from './utils';
import { setSearchScanner } from 'store/modules/scanner/actions';
import { useInterestRate } from '../../utils/getStateIr';

import ScannerHead from './header/head';
import { NewScan } from './body/newScan';
import SelectScan from './body/SelectScan';
import { TableScanner } from './footer/table';
import { StrategySelect } from './selectList';
import MaterialModal from 'components/MaterialComponents/MaterialDialog';

let savedSearchs = [];
let allSearchs = [];
let searchsFound = [];
let dateArray = [];

const Scanner = ({ actionListener }) => {
	document.title = 'Scanner | Flexscan';

	const dispatch = useDispatch();
	const watermark =
		'https://iflex-repo.s3.amazonaws.com/assets/logo-fs-mdagua.png';
	const chart = simChart();
	const reduxSearch = useSelector(state => state.scanner.userSearch);
	const [listIsLoaded, setListIsLoaded] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(0);
	const [modalText, setModalText] = useState('');
	const [modalTitle, setModalTitle] = useState('');
	const [headerSaveType, setHeaderSaveType] = useState('put');
	const [open, setOpen] = useState(false);
	const [strategyLegs, setStrategyLegs] = useState('');
	const [isQuickScan, setIsQuickScan] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isFirstSave, setIsFirstSave] = useState(false);
	const [isDateLoaded, setIsDateLoaded] = useState(false);
	const [timeStampHead, setTimeStamp] = useState('');
	const [strategyRowSelected, setStrategyRowSelected] = useState(0);
	const [tablePreferences, setTablePreferences] = useState(0);
	const { ir } = useInterestRate();

	const [scannerValues, setScannerValues] = useState({
		filterIsOpen: false,
		graphIsOpen: false,
		label: '',
		scanType: 0,
		searchLimit: 50,
		orderedByType: 'profitProbability',
		orderedBy: '-orderAsc',
		filters: [
			{
				code: 'underSymbol',
				filterValue: 0,
				conditionType: 'CND_2',
				conditionCode: '-eq',
				conditionValue: 0,
				firstParam: [],
				secondParam: 0,
			},
		],
	});

	const initChart = (portifolio, underPrice) => {
		chart
			.portfolio(portifolio)
			.underPx(underPrice)
			.ir(+ir)
			.watermark(`url(${watermark}) no-repeat center `)
			.enableCenterIcon(true)
			.enablePercentSwitch(false)
			.enableControlPanel(false);

		const simChartElement = document.getElementById('sim-chart');

		if (simChartElement) {
			simChartElement.innerHTML = '';
			d3.select('#sim-chart').call(chart);
		}
	};

	const scannerSearch = async (userSearch, quickScan) => {
		dispatch(setSearchScanner(userSearch));
		searchsFound = [];
		let filterArray = '';
		let finalArray = {};
		let allRight = true;

		const formatTimeStamp = date => {
			let timeStamp = date.split(' ');
			let newDate = timeStamp[0].split('-');
			newDate = `${newDate[2]}/${newDate[1]}/${newDate[0]}`;
			let newTime = timeStamp[1].split(':');
			newTime = `${parseFloat(newTime[0]) - 3}:${newTime[1]}:${
				newTime[2].split('.')[0]
			}`;
			timeStamp = `${newDate} às ${newTime}`;
			return timeStamp;
		};

		const isCompostCode = (code, type) => {
			const codeParams = code.split(',');
			const codeFields = [
				'minPositionStrike',
				'profitIntInit1',
				'minPositionAcumFinTradeVol',
				'minPositionContractPrice',
				'minPositionTradeQtty',
			];

			if (codeFields.includes(codeParams[0])) {
				return codeParams[type];
			}
			return code;
		};

		if (!quickScan) {
			const messagesLines = {
				1: 'primeira',
				2: 'segunda',
				3: 'terceira',
				4: 'quarta',
			};

			for (
				let indexRoot = 0;
				indexRoot < userSearch.filters.length;
				indexRoot++
			) {
				let itemRoot = userSearch.filters[indexRoot];
				let firstNewParam = itemRoot.firstParam;
				let secondNewParam = itemRoot.secondParam;

				if (typeof itemRoot.firstParam === 'object') {
					if (itemRoot.code === 'strategyId') {
						if (
							!firstNewParam[0] ||
							firstNewParam[0] === 0 ||
							firstNewParam[0].length === 0
						) {
							allRight = false;
							return toast.error(
								`Nenhuma estratégia selecionado na linha ${
									indexRoot + 1
								}`
							);
						}
						itemRoot.firstParam.forEach(itemFilter => {
							filterArray += `${itemRoot.code}=${itemFilter[1]}&`;
						});
					} else {
						if (
							!firstNewParam[0] ||
							firstNewParam[0] === 0 ||
							firstNewParam[0].length === 0
						) {
							allRight = false;
							return toast.error(
								`Nenhum ativo selecionado na linha ${
									indexRoot + 1
								}`
							);
						}
						if (itemRoot.firstParam.length > 5) {
							allRight = false;
							return toast.error('Escolha no máximo de 5 ativos');
						}
						itemRoot.firstParam.forEach(itemFilter => {
							filterArray += `${itemRoot.code}=${itemFilter}&`;
						});
					}
				} else if (secondNewParam === 0) {
					if (itemRoot.filterValue === 2) {
						if (
							firstNewParam[0] === 'S' ||
							!firstNewParam[0] ||
							firstNewParam[0] === 0
						) {
							allRight = false;
							return toast.error(
								'É necessário selecionar um vencimento'
							);
						}
						if (firstNewParam[0] === 'T') {
							firstNewParam = '';
						} else {
							firstNewParam = firstNewParam.split('/');
							firstNewParam = `${firstNewParam[2]}-${firstNewParam[1]}-${firstNewParam[0]}`;
						}
					}
					filterArray += `${isCompostCode(itemRoot.code, 0)}${
						itemRoot.conditionCode
					}=${firstNewParam}&`;
				} else {
					const operators = itemRoot.conditionCode.split(',');
					filterArray += `${isCompostCode(itemRoot.code, 0)}${
						operators[0]
					}=${firstNewParam}&`;
					filterArray += `${isCompostCode(itemRoot.code, 1)}${
						operators[1]
					}=${secondNewParam}&`;
				}
				if (firstNewParam === 0) {
					allRight = false;
					return toast.error(
						`Os Valores da ${
							messagesLines[indexRoot + 1]
						} linha não são validos! Certifique-se de inserir valores maiores que zero!`
					);
				}
				if (
					(itemRoot.conditionType === 'CND_3' &&
						itemRoot.conditionValue < 1) ||
					(itemRoot.conditionType === 'CND_IL' &&
						itemRoot.conditionValue < 2)
				) {
					if (secondNewParam === 0) {
						allRight = false;
						return toast.error(
							`O segundo valor na ${
								messagesLines[indexRoot + 1]
							} linha não é valido! Certifique-se de inserir um valor maior que zero!`
						);
					}
					if (firstNewParam >= secondNewParam) {
						allRight = false;
						return toast.error(
							`O segundo valor na ${
								messagesLines[indexRoot + 1]
							} linha não é valido! Certifique-se de inserir um valor maior que o primeiro campo!`
						);
					}
				}
			}
			filterArray += `${userSearch.orderedByType}${userSearch.orderedBy}=`;
			filterArray += `&limit=${userSearch.searchLimit}`;
		}

		if (quickScan) {
			if (userSearch.underSymbol.length === 0) {
				filterArray += '';
			} else {
				userSearch.underSymbol.forEach(itemFilter => {
					filterArray += `underSymbol=${itemFilter}&`;
				});
			}

			if (userSearch.optionType[0] !== 'T') {
				filterArray += `optionType=${userSearch.optionType[0]}&`;
			}

			if (userSearch.longShort[0] !== 'T') {
				filterArray += `longShort=${userSearch.longShort[0]}&`;
			}

			if (userSearch.expirationDate[0] !== 'T') {
				const formatedData = userSearch.expirationDate.split('/');
				filterArray += `expirationDate=${formatedData[2]}-${formatedData[1]}-${formatedData[0]}&`;
			}

			filterArray += `queryId=${userSearch.queryId}`;
		}

		if (allRight) {
			if (process.env.NODE_ENV === 'development') {
				console.log('Array de envio para API:', filterArray);
			}
			setIsLoading(true);
			try {
				const { data, status } = await apiAWS.get(
					`/reports/scanner?${filterArray}`
				);

				if (status === 0) {
					setIsLoading(false);
					return toast.error('Erro de conexão');
				}
				if (status === 500) {
					setIsLoading(false);
					return toast.error(
						'Ocorreu um erro na consulta, favor tente de novo mais tarde'
					);
				}
				const listedSearchs = data;
				if (listedSearchs.records.length <= 0) {
					setIsLoading(false);
					toast.error('Sua pesquisa não trouxe resultados');
				} else {
					listedSearchs.records.forEach((item, index) => {
						const strategyIndex = StrategySelect.findIndex(
							teste => teste[1] === item.strategyId
						);
						searchsFound.push({
							id: index,
							strategyId: StrategySelect[strategyIndex][0],
							positionsDescriptor: item.positionsDescriptor,
							underSymbol: item.underSymbol,
							underPrice: item.underPrice,
							entrySpreadCost: item.entrySpreadCost,
							entryCost: item.entryCost,
							profitProbability: item.profitProbability,
							maxAbsoluteRiskAmount: item.maxAbsoluteRiskAmount,
							maxPotRiskPerc3: item.maxPotRiskPerc3,
							maxAbsoluteProfitAmount:
								item.maxAbsoluteProfitAmount,
							maxPotProfitPerc3: item.maxPotProfitPerc3,
							protectionRate: item.protectionRate,
							margin: item.margin,
							gamma: item.gamma,
							theta: item.theta,
							delta: item.delta,
							vega: item.vega,
							rho: item.rho,
							expirationDate: item.expirationDate,
							ts: item.ts,
							daysUntilExpiration: item.daysUntilExpiration,
							histVol: item.histVol,
							exerciseYield: item.exerciseYield,
						});
					});

					setStrategyLegs(
						listedSearchs.records[0].positionsDescriptor
					);

					const positions = await extractPositionsFromDescriptor(
						listedSearchs.records[0].positionsDescriptor,
						listedSearchs.records[0].expirationDate
					);

					finalArray = {
						strategies: [
							{
								underPx: listedSearchs.records[0].underPrice,
								underSymbol:
									listedSearchs.records[0].underSymbol,
								ir: +ir,
								histVol30: listedSearchs.records[0].histVol,
								histVol90: listedSearchs.records[0].histVol,
								histVol180: listedSearchs.records[0].histVol,
								histVol360: listedSearchs.records[0].histVol,
								positions,
							},
						],
					};

					setScannerValues({
						...scannerValues,
						...userSearch,
						filterIsOpen: true,
						graphIsOpen: true,
					});

					if (quickScan) {
						setIsQuickScan(true);
					}

					setIsLoading(false);

					setTimeStamp(formatTimeStamp(listedSearchs.records[0].ts));
					if (process.env.NODE_ENV === 'development') {
						console.log(
							'Resultados da tabela',
							listedSearchs.records
						);
					}

					setTimeout(() => {
						const firstLine =
							document.querySelectorAll('[data-id="0"]')[0];
						if (firstLine) {
							firstLine.classList.add('Mui-selected');
						}
					}, 300);
				}

				setTimeout(() => {
					if (listedSearchs.records[0]) {
						initChart(
							finalArray,
							listedSearchs.records[0].underPrice
						);
					}
				}, 750);

				setStrategyRowSelected(0);
				setListIsLoaded(!listIsLoaded);
			} catch (error) {
				setIsLoading(false);
				toast.error('Erro ao realizar pesquisa');
			}
		}
	};

	const getUserSearchs = async (searchIndex, type) => {
		try {
			const { username } = store.getState().auth;

			const { data } = await apiAWS.get(
				`/userpreferences/${username}?service=scanner_searchs`
			);

			if (
				type !== 'loadEdit' &&
				type !== 'execution' &&
				type !== 'tablePrefs'
			) {
				savedSearchs = [];
				allSearchs = [];
			}

			if (!data.searchs) {
				data.searchs = [];
			}

			if (type === 'loadEdit') {
				setScannerValues({
					...scannerValues,
					...data.searchs[searchIndex],
					filterIsOpen: true,
					graphIsOpen: false,
				});
			} else if (type === 'reloadList') {
				data.searchs.forEach(item => {
					savedSearchs.push({
						label: item.label,
					});
					allSearchs.push(item);
				});
			} else if (type === 'reloadChanges') {
				data.searchs.forEach(item => {
					savedSearchs.push({
						label: item.label,
					});
					allSearchs.push(item);
				});
				if (searchIndex !== null) {
					return setScannerValues({
						...scannerValues,
						...data.searchs[searchIndex + 1],
						filterIsOpen: true,
						graphIsOpen: false,
					});
				}
				return setScannerValues({
					...scannerValues,
					...data.searchs[searchIndex],
				});
			} else if (type === 'execution') {
				scannerSearch(data.searchs[searchIndex]);
			}
			if (data.tablePrefs || type === 'tablePrefs') {
				setTablePreferences(data.tablePrefs);
			}
			setListIsLoaded(!listIsLoaded);
			return data.searchs;
		} catch (error) {
			if (error && error.response?.status !== 404) {
				toast.error('Erro ao consultar pesquisas salvas');
			}
		}
	};

	const saveSearch = async (userSearch, type, searchIndex) => {
		let msgToastError = '';
		let msgToastSuccess = '';
		let isNotRight = false;
		let sameName = false;

		setOpen(false);

		if (
			type === 'delete' ||
			type === 'home' ||
			headerSaveType === 'put' ||
			allSearchs.length < 8
		) {
			if (type !== 'home' && type !== 'delete') {
				allSearchs.forEach((item, index) => {
					if (
						item.label === userSearch.label &&
						index !== searchIndex
					) {
						sameName = true;
						return toast.error(
							'Já existe uma pesquisa com esse nome'
						);
					}
				});
			}

			if (type === 'home') {
				dispatch(setSearchScanner({}));
				setTimeStamp('');
				if (isQuickScan) {
					setIsQuickScan(false);
					return setScannerValues({
						...scannerValues,
						filterIsOpen: false,
						graphIsOpen: false,
					});
				}
				if (
					userSearch.filterIsOpen &&
					userSearch.graphIsOpen &&
					allSearchs.length === 8
				) {
					return setScannerValues({
						...scannerValues,
						filterIsOpen: false,
						graphIsOpen: false,
					});
				}
				if (!isFirstSave) {
					if (allSearchs.length !== selectedIndex) {
						if (
							allSearchs[searchIndex].scanType !==
								userSearch.scanType ||
							allSearchs[searchIndex].orderedByType !==
								userSearch.orderedByType ||
							allSearchs[searchIndex].searchLimit !==
								userSearch.searchLimit ||
							allSearchs[searchIndex].orderedBy !==
								userSearch.orderedBy ||
							JSON.stringify(
								allSearchs[searchIndex].filters.sort()
							) !== JSON.stringify(userSearch.filters.sort())
						) {
							setModalTitle('Salvar alterações');
							setModalText(
								'Existem alterações que não foram salvas na sua pesquisa. Deseja salvar?'
							);
							setHeaderSaveType('put');
							setOpen(true);
							setIsFirstSave(false);
						}
					}
				}
				return setScannerValues({
					...scannerValues,
					filterIsOpen: false,
					graphIsOpen: false,
				});
			}

			if (!sameName) {
				if (type !== 'delete') {
					if (allSearchs.length !== selectedIndex) {
						if (
							allSearchs[searchIndex].label !==
								userSearch.label &&
							type === 'put'
						) {
							setModalTitle('Salvar como');
							setModalText(
								'O nome original foi modificado, a pesquisa será salva a parte da original.'
							);
							setHeaderSaveType('post');
							return setOpen(true);
						}
					}

					if (userSearch.label.trim() === '') {
						return toast.error(
							'Nome da pesquisa em branco, preencha o nome na edição para poder salvar.'
						);
					}

					userSearch.filters.forEach((item, index) => {
						if (
							item.firstParam === '' ||
							item.firstParam <= 0 ||
							item.firstParam.length <= 0
						) {
							isNotRight = true;
							return toast.error(
								`Primeiro parâmetro da linha ${
									index + 1
								}, está incorreto`
							);
						}
						if (
							item.filterValue >= 4 &&
							item.conditionType === 'CND_3' &&
							item.conditionValue === 0
						) {
							if (
								item.secondParam === '' ||
								item.secondParam <= 0
							) {
								isNotRight = true;
								return toast.error(
									`Segundo parâmetro da linha ${
										index + 1
									}, está incorreto`
								);
							}
							if (item.secondParam <= item.firstParam) {
								isNotRight = true;
								return toast.error(
									`Segundo parâmetro da linha ${
										index + 1
									}, está menor que o primeiro`
								);
							}
						}
					});
				}

				if (!isNotRight) {
					try {
						const { username } = store.getState().auth;
						if (type === 'delete') {
							allSearchs.splice(searchIndex, 1);
							msgToastSuccess = 'Pesquisa deletada com sucesso';
							msgToastError = 'Erro ao deletar pesquisa';
						}
						if (type === 'put') {
							allSearchs.splice(searchIndex, 1, userSearch);
							msgToastSuccess = 'Pesquisa salva sucesso';
							msgToastError = 'Erro ao salvar pesquisa';
						}
						if (type === 'post') {
							allSearchs.push(userSearch);
							msgToastSuccess = 'Pesquisa salva com sucesso';
							msgToastError = 'Erro ao salvar pesquisa';
						}

						const newArray = JSON.stringify({
							searchs: allSearchs,
						});

						const { status } = await apiAWS.post(
							`/userpreferences/${username}?service=scanner_searchs`,
							newArray
						);

						if (status === 0) {
							return toast.error('Erro de conexão');
						}
						if (status === 200) {
							if (type === 'delete') {
								getUserSearchs(null, 'reloadChanges');
							} else if (type === 'put') {
								allSearchs.forEach((item, index) => {
									if (allSearchs.length - 1 === index) {
										getUserSearchs(index, 'reloadList');
									}
								});
							} else if (type === 'post') {
								getUserSearchs(searchIndex, 'reloadChanges');
							}
							toast.success(msgToastSuccess);
							/* return event; */
						}
					} catch (error) {
						toast.error(msgToastError);
					}
				}
			}
		} else {
			toast.error('Máximo de pesquisas alcançado');
		}
	};

	const cancelSave = () => {
		setScannerValues({
			...scannerValues,
			filterIsOpen: false,
			graphIsOpen: false,
		});
	};

	const getMaturityDates = async () => {
		dateArray = [];
		const response = await apiMarketData.get(
			'/exchanges/BVMF/maturitydates?securityType=OPT'
		);
		const fullDates = response.data.records;

		fullDates.forEach(item => {
			const resYear = item.slice(0, 4);
			const resMonth = item.slice(4, 6);
			const resDay = item.slice(6, 8);
			const newFulldate = `${resDay}/${resMonth}/${resYear}`;
			dateArray.push(newFulldate);
		});
		dateArray.unshift('Todos');
		setIsDateLoaded(true);
	};

	useEffect(() => {
		getUserSearchs(undefined, 'reloadList');
		getMaturityDates();
	}, []);

	useEffect(() => {
		if (Object.keys(reduxSearch).length !== 0) {
			if (!reduxSearch.queryId) {
				scannerSearch(reduxSearch);
			} else {
				scannerSearch(reduxSearch, true);
			}
		}
	}, []);

	return (
		<>
			<Grid container direction="column" sx={{ height: '100%', px: 1 }}>
				<ScannerHead
					saveSearch={saveSearch}
					isQuickScan={isQuickScan}
					searchsFound={searchsFound}
					selectedIndex={selectedIndex}
					scannerSearch={scannerSearch}
					scannerValues={scannerValues}
					timeStampHead={timeStampHead}
					strategyLegsScan={strategyLegs}
					actionListener={actionListener}
					setIsFirstSave={setIsFirstSave}
					setScannerValues={setScannerValues}
					strategyRowSelected={strategyRowSelected}
				/>

				<Grid item container>
					{scannerValues.filterIsOpen ? (
						<NewScan
							dateArray={dateArray}
							scannerValues={scannerValues}
							scannerSearch={scannerSearch}
							setScannerValues={setScannerValues}
						/>
					) : (
						<SelectScan
							dateArray={dateArray}
							allSearchs={allSearchs}
							saveSearch={saveSearch}
							isDateLoaded={isDateLoaded}
							savedSearchs={savedSearchs}
							scannerValues={scannerValues}
							scannerSearch={scannerSearch}
							selectedIndex={selectedIndex}
							getUserSearchs={getUserSearchs}
							setSelectedIndex={setSelectedIndex}
							setScannerValues={setScannerValues}
						/>
					)}

					{isLoading && (
						<Grid item xs={12}>
							<LinearProgress />
						</Grid>
					)}
				</Grid>

				{scannerValues.filterIsOpen && scannerValues.graphIsOpen && (
					<Grid item xs={12} style={{ flex: 1 }}>
						<TableScanner
							initChart={initChart}
							searchsFound={searchsFound}
							setTimeStamp={setTimeStamp}
							setStrategyLegs={setStrategyLegs}
							tablePreferences={tablePreferences}
							setStrategyRowSelected={setStrategyRowSelected}
						/>
					</Grid>
				)}
			</Grid>

			<MaterialModal
				open={open}
				text={modalText}
				setOpen={setOpen}
				title={modalTitle}
				inputLabel="Nome da pesquisa"
				onCancel={cancelSave}
				onClick={() => {
					saveSearch(scannerValues, headerSaveType, selectedIndex);
				}}
			/>
		</>
	);
};

export default Scanner;
