import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';

import { CircularProgress, Grid } from '@mui/material';

import { apiAWS, apiCadastro } from '../../services/api';

import AlertToken from 'components/ModalToken';
import { NewPasswordForm } from './NewPasswordForm';
import { NewSingnature } from './NewSignature';

export const NewUserForm = () => {
	const { newUserToken } = useParams();
	const history = useHistory();
	const [showCircularProgress, setShowCircularProgress] = useState(false);
	const [step, setStep] = useState(0);
	const [modal, setModal] = useState(false);
	const [email, setEmail] = useState();

	const showProgress = () => {
		return (
			<>
				<Grid style={{ textAlign: 'center' }}>
					<CircularProgress />
				</Grid>
			</>
		);
	};

	var formattedEmail = function (email) {
		var splitAt = email.split('@', 2);
		return formattedName(splitAt[0]) + '@' + splitAt[1];
	};

	var formattedName = function (name) {
		var nameArray = Array.from(name);
		var hiddenChars = name.length % 2 == 1 ? (name.length + 1) / 2 : name.length / 2;
		var loopCount = 0;

		while (loopCount < name.length) {
			if (loopCount < hiddenChars / 2 || loopCount > Math.floor(name.length / 2) + hiddenChars / 2 - 1) {
				nameArray[loopCount] = 'x';
			}
			loopCount++;
		}

		return nameArray.join().replaceAll(',', '');
	};

	const tempHeaders = { headers: { 'x-authorization': newUserToken } };

	useEffect(() => {
		setShowCircularProgress(true);
		apiAWS
			.get('/credentials/tokens', tempHeaders)
			.then(() => {
				setStep(1);
			})
			.catch((error) => {
				if (error && error.response?.status === 401) {
					setModal(true);
				}
			})
			.finally(() => {
				setShowCircularProgress(false);
			});
	}, []);

	function handleNewPassword(event, values) {
		event.preventDefault();

		if (values.password !== values.password_confirmation) {
			toast.error('Senhas não conferem');
			return;
		}

		const body = {
			service: 'create_password',
			password: values.password,
		};

		setShowCircularProgress(true);
		apiAWS
			.post('/credentials/commands', body, tempHeaders)
			.then(() => {
				toast.success('Senha criada com sucesso, agora vamos criar a assinatura eletrônica!');
				setStep(2);
			})
			.catch(() => {
				toast.error('Erro ao criar nova senha, entre em contato com o suporte!');
				history.push('/login');
			})
			.finally(() => {
				setShowCircularProgress(false);
			});
	}

	const handleNewLink = () => {
		apiCadastro
			.post(
				'/credentials',
				{},
				{
					headers: {
						Authorization: `Bearer ${newUserToken}`,
					},
				},
			)
			.then((response) => {
				const emailResponse = atob(response.data);
				const emailDecoded = JSON.parse(emailResponse);
				const maskEmail = formattedEmail(emailDecoded.email);
				setEmail(maskEmail);
				setModal(true);
			})
			.catch(() => {
				setModal(false);
				history.push('/login');
			});
	};

	return (
		<>
			<AlertToken modalIsOpen={modal} sendLink={handleNewLink} alterEmail={email} />
			{showCircularProgress && showProgress()}
			{step === 1 && <NewPasswordForm handleSubmit={handleNewPassword} />}
			{step === 2 && <NewSingnature newUserToken={newUserToken} />}
		</>
	);
};
