import { store } from 'store';
import { apiEls, apiMarketData, apiAWS } from 'services/api';
import {
	STRATEGY_HANDLE,
	STRATEGY_UPDATE,
	REPLACE_CUSTOM_PARAMETERS,
	UPDATE_CUSTOM_PARAMETERS,
	UPDATE_STRATEGY_INFO,
	UPDATE_STRATEGY_LEG,
	UPDATE_STRATEGY_MARKET_PRICES,
	REPLACE_STRATEGY_LEGS,
	ADDITIONAL_UPDATE,
	HANDLE_SAVED_ORDERS,
	HANDLE_RECENT_ORDERS,
	ORDER_ENTRY,
	UPDATE_STRATEGY_INFOS,
	UPDATE_CONTENT,
	UPDATE_CONTEXT,
	LOADING_MARKET_PRICE,
	SET_IS_RECURRENCE_STATE,
	UPDATE_RECURRENCE_INFO,
} from './actionTypes';
import {
	getSubExchangeFromSecurityType,
	parseBook,
} from '../../../utils/strategies';
import { toast } from 'react-toastify';
import { setSaveBulletLoading } from '../ui/actions';

export const handleStrategy = strategy => ({
	type: STRATEGY_HANDLE,
	payload: { strategy },
});

export const strategyUpdate = content => ({
	type: STRATEGY_UPDATE,
	payload: { content },
});

export const replaceCustomParameters = customParameters => ({
	type: REPLACE_CUSTOM_PARAMETERS,
	payload: customParameters,
});

export const updateCustomParameters = customParameters => ({
	type: UPDATE_CUSTOM_PARAMETERS,
	payload: customParameters,
});

export const updateStrategyLeg = (legParams, legIndex) => ({
	type: UPDATE_STRATEGY_LEG,
	payload: { legParams, legIndex },
});

export const updateStrategyInfos = (infoParams, infoIndex) => ({
	type: UPDATE_STRATEGY_INFOS,
	payload: { infoParams, infoIndex },
});

export const updateStrategyInfo = (infoParams, infoIndex) => ({
	type: UPDATE_STRATEGY_INFO,
	payload: { infoParams, infoIndex },
});

export const replaceStrategyLegs = strategyLegs => ({
	type: REPLACE_STRATEGY_LEGS,
	payload: strategyLegs,
});

export const additionalUpdate = (key, value) => ({
	type: ADDITIONAL_UPDATE,
	payload: { key, value },
});

export const orderEntry = (bullet, subUid) => ({
	type: ORDER_ENTRY,
	payload: { bullet, subUid },
});

export const setMarketPriceLoading = isLoading => ({
	type: LOADING_MARKET_PRICE,
	payload: { isLoading },
});

export const handleSavedOrders = savedOrders => ({
	type: HANDLE_SAVED_ORDERS,
	payload: { savedOrders },
});

export const handleRecentOrders = recentOrders => ({
	type: HANDLE_RECENT_ORDERS,
	payload: { recentOrders },
});

export const updateContent = content => ({
	type: UPDATE_CONTENT,
	payload: content,
});

export const updateContext = context => ({
	type: UPDATE_CONTEXT,
	payload: context,
});

export const setIsRecurrenceBullet = isRecurrence => ({
	type: SET_IS_RECURRENCE_STATE,
	payload: { isRecurrence },
});

export const updateRecurrenceInfo = newInfo => ({
	type: UPDATE_RECURRENCE_INFO,
	payload: { newInfo },
});

export const resetBottomBullet = bullet => {
	store.dispatch(
		orderEntry(
			{ StrategyCode: bullet.content.StrategyCode, clean: true },
			bullet.subUid
		)
	);
};

export const saveDataBullet = (data, typeAction = 'create') => {
	return () => {
		store.dispatch(setSaveBulletLoading(true));
		const { username, token } = store.getState().auth;

		store.dispatch(handleSavedOrders(data));

		const body = {
			strategies: data,
		};

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		const messagesMap = {
			create: 'Estratégia salva com sucesso',
			delete: 'Estratégia deletada com sucesso',
		};

		let messageSuccess = messagesMap[typeAction];

		apiAWS
			.post(
				`/userpreferences/${username}?service=saved_orders&version=${process.env.REACT_APP_BULLET_VERSION}`,
				body,
				headers
			)
			.then(response => {
				toast.success(messageSuccess);
				return response.data;
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				store.dispatch(setSaveBulletLoading(false));
			});
	};
};

export const updateStrategyInfosFromApi = (
	strategyInfos
	// strategyLegs,
	// type
) => {
	return () => {
		const legsRequests = [];

		strategyInfos.forEach(leg => {
			legsRequests.push(
				apiEls.get(`/search/${leg.symbol ? leg.symbol : leg.LegSymbol}`)
			);
		});

		Promise.all(legsRequests)
			.then(values => {
				const newStrategyInfos = strategyInfos.map(legInfo => {
					const resp = values.filter(
						item =>
							item.data._source.symbol ===
							(legInfo.symbol
								? legInfo.symbol
								: legInfo.LegSymbol)
					);

					if (['DOL', 'WDO'].includes(resp[0].data._source.asset)) {
						resp[0].data._source.tickSizeDenominator = 2;
					}
					return {
						...legInfo,
						// finantial: newFinantial,
						symbol: resp[0].data._source.symbol,
						roundLot: resp[0].data._source.roundLot,
						maxOrderQty: resp[0].data._source.maxOrderQty,
						minOrderQty: resp[0].data._source.minOrderQty,
						contractMultiplier:
							resp[0].data._source.contractMultiplier,
						tickSizeDenominator:
							resp[0].data._source.symbol.includes('DOL') ||
							resp[0].data._source.symbol.includes('WDO') ||
							(!resp[0].data._source.tickSizeDenominator &&
								resp[0].data._source.tickSizeDenominator !== 0)
								? 2
								: resp[0].data._source.tickSizeDenominator,
						minPriceIncrement:
							resp[0].data._source.minPriceIncrement,
						maturityDate: resp[0].data._source.maturityDate,
						securityType: resp[0].data._source.securityType,
						optionType: resp[0].data._source.optionType,
						asset: resp[0].data._source.asset,
						subExchange: resp[0].data._source.subExchange,
						workDaysUntilMaturity:
							resp[0].data._source.workDaysUntilMaturity,
						openHour: resp[0].data._source.openHour,
						closeHour: resp[0].data._source.closeHour,
					};
				});

				store.dispatch(
					additionalUpdate('StrategyInfos', newStrategyInfos)
				);
			})
			.catch(error => {
				console.error(`Problema ao processar a requisicao! ${error}`);
			});
	};
};

export const updateStrategyInfoWithDefinitionApi = (
	legSymbol,
	legIndex,
	updateLeg = false,
	detailed = false
) => {
	return () => {
		if (legSymbol !== '') {
			apiMarketData
				.get(
					`/exchanges/BVMF/instruments/${legSymbol}${
						detailed ? '?detailed=1' : ''
					}`
				)
				.then(values => {
					const newStrategyInfos = {
						symbol: values.data.symbol,
						minOrderQty: values.data.minOrderQty,
						maxOrderQty: values.data.maxOrderQty,
						minPriceIncrement: values.data.minPriceIncrement,
						subExchange: getSubExchangeFromSecurityType(
							values.data.securityType
						),
						contractMultiplier: values.data.contractMultiplier,
						roundLot: values.data.roundLot,
						securityType: values.data.securityType,
						maturityDate: values.data.maturityDate,
						asset: values.data.asset,
						tickSizeDenominator:
							values.data.symbol.includes('DOL') ||
							values.data.symbol.includes('WDO') ||
							(!values.data.tickSizeDenominator &&
								values.data.tickSizeDenominator !== 0)
								? 2
								: values.data.tickSizeDenominator,
						openHour: values.data.openHour,
						closeHour: values.data.closeHour,
					};

					store.dispatch(
						updateStrategyInfos(newStrategyInfos, legIndex)
					);

					if (updateLeg) {
						store.dispatch(
							updateStrategyLeg(
								{
									LegSymbol: newStrategyInfos.symbol,
									LegSecurityExchange:
										newStrategyInfos.subExchange,
								},
								legIndex
							)
						);
					}
				})
				.catch(error => {
					console.error(
						`Problema ao processar a requisicao! ${error}`
					);
				});
		}
	};
};

export const updateMarketPrices = values => ({
	type: UPDATE_STRATEGY_MARKET_PRICES,
	payload: { marketPrices: values },
});

export const getCalcTaxTriggerFinanciamento = (ativoSymbol, opcaoSymbol) => {
	return () => {
		const legsRequests = [];

		[ativoSymbol, opcaoSymbol].forEach(symbol => {
			legsRequests.push(
				apiMarketData.get(
					`/exchanges/BVMF/instruments/${symbol}/snapshot`
				)
			);
		});

		Promise.all(legsRequests)
			.then(values => {
				const ativo = values.filter(
					item => item.data.symbol === ativoSymbol
				);

				const opcao = values.filter(
					item => item.data.symbol === opcaoSymbol
				);

				if (ativo && ativo.length > 0 && opcao && opcao.length > 0) {
					const bookAtivo = parseBook(ativo[0].data.book);
					const bookOpcao = parseBook(opcao[0].data.book);
					const taxa =
						(opcao[0].data.strikePrice /
							(bookAtivo.askPrices[0] - bookOpcao.bidPrices[0]) -
							1) *
						100;
					store.dispatch(updateMarketPrices(taxa));
				} else {
					store.dispatch(updateMarketPrices(0.0));
				}
			})
			.catch(error => {
				console.error(`Problema ao processar a requisicao! ${error}`);
				store.dispatch(updateMarketPrices(0.0));
			});
	};
};

export const recentDataBullet = data => {
	return () => {
		const { username, token } = store.getState().auth;

		const body = { strategies: data };

		const headers = { headers: { Authorization: `Bearer ${token}` } };

		store.dispatch(handleRecentOrders(data));

		apiAWS
			.post(
				`/userpreferences/${username}?service=recent_orders&version=${process.env.REACT_APP_BULLET_VERSION}`,
				body,
				headers
			)
			.catch(error => console.log(error));
	};
};
