import { AccordionSummary } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
	display: block;
	background: #424242;
	width: 96%;
	margin: 0 auto;
`;

export const StyledSummary = styled(AccordionSummary)`
	padding: 0 !important;
	min-height: 24px !important;
`;

export const PanelSummaryContainer = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	color: white;

	.panel-header {
		margin-left: 5px;
		display: flex;
		align-items: center;
	}

	button {
		cursor: pointer;
		background: transparent;
		outline: none;
		border: none;
		color: #666666;
	}

	.panel-title {
		font-size: 11px;
		color: #666666;
		font-weight: 600;
	}

	.panel-remove {
		margin-right: 8px;
	}

	.align-items-center {
		display: flex;
		align-items: center;
	}
`;

export const ButtonAdd = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	button {
		cursor: pointer;
		background: ${props => props.theme.colors.inputColor};
		border: 1px solid ${props => props.theme.colors.inputColor};
		border-radius: 50%;
		color: ${props => props.theme.colors.inputBg};
	}
`;
