import {
	ADD_ITEM_BOOK_SUB_LIST,
	ADD_ITEM_MINI_BOOK_SUB_LIST,
	ADD_ITEM_TRADE_SUB_LIST,
	DELETE_ITEM_BOOK_SUB_LIST,
	DELETE_ITEM_MINI_BOOK_SUB_LIST,
	DELETE_ITEM_TRADE_SUB_LIST,
	SET_BOOK_SUB_LIST,
	SET_MINI_BOOK_SUB_LIST,
	SET_TRADE_SUB_LIST,
	UPDATE_BOOK_SUB_LIST,
	UPDATE_TRADE_SUB_LIST,
	UPDATE_WEBSOCKET_CONNECTION,
	SET_SNAPSHOT_SUB_LIST,
	DELETE_ITEM_SNAPSHOT_SUB_LIST,
	ADD_ITEM_SNAPSHOT_SUB_LIST,
} from './types';

const initialState = {
	subscriptionTradeList: [],
	subscriptionBookList: [],
	subscriptionMiniBookList: [],
	subscriptionSnapshotList: [],
	isConnected: true,
};

export default (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_BOOK_SUB_LIST:
			return {
				...state,
				subscriptionBookList: [
					...state.subscriptionBookList,
					...[payload.subscriptionBookList],
				],
			};
		case SET_BOOK_SUB_LIST:
			return {
				...state,
				subscriptionBookList: [...payload.subscriptionBookList],
			};
		case SET_MINI_BOOK_SUB_LIST:
			return {
				...state,
				subscriptionMiniBookList: [...payload.subscriptionMiniBookList],
			};
		case SET_TRADE_SUB_LIST:
			return {
				...state,
				subscriptionTradeList: [...payload.subscriptionTradeList],
			};
		case SET_SNAPSHOT_SUB_LIST:
			return {
				...state,
				subscriptionSnapshotList: [...payload.subscriptionSnapshotList],
			};
		case DELETE_ITEM_SNAPSHOT_SUB_LIST: {
			const tempArray = [...state.subscriptionSnapshotList];
			const indexToRemove = state.subscriptionSnapshotList.indexOf(
				payload.item
			);

			// se existir removo
			if (indexToRemove > -1) {
				tempArray.splice(indexToRemove, 1);
			}

			return {
				...state,
				subscriptionSnapshotList: [...tempArray],
			};
		}
		case ADD_ITEM_SNAPSHOT_SUB_LIST:
			if (!state.subscriptionSnapshotList.includes(payload.item)) {
				return {
					...state,
					subscriptionSnapshotList: [
						...state.subscriptionSnapshotList,
						...[payload.item],
					],
				};
			}
			return { ...state };
		case UPDATE_TRADE_SUB_LIST:
			return {
				...state,
				subscriptionTradeList: [
					...state.subscriptionTradeList,
					...[payload.subscriptionTradeList],
				],
			};
		case DELETE_ITEM_TRADE_SUB_LIST: {
			const tempArray = [...state.subscriptionTradeList];
			const indexToRemove = state.subscriptionTradeList.indexOf(
				payload.symbol
			);
			// se existir removo
			if (indexToRemove > -1) {
				tempArray.splice(indexToRemove, 1);
			}

			return {
				...state,
				subscriptionTradeList: [...tempArray],
			};
		}
		case DELETE_ITEM_BOOK_SUB_LIST: {
			const tempArray = [...state.subscriptionBookList];
			const indexToRemove = state.subscriptionBookList.indexOf(
				payload.symbol
			);
			// se existir removo
			if (indexToRemove > -1) {
				tempArray.splice(indexToRemove, 1);
			}

			return {
				...state,
				subscriptionBookList: [...tempArray],
			};
		}
		case DELETE_ITEM_MINI_BOOK_SUB_LIST: {
			const tempArray = [...state.subscriptionMiniBookList];
			const indexToRemove = state.subscriptionMiniBookList.indexOf(
				payload.symbol
			);
			// se existir removo
			if (indexToRemove > -1) {
				tempArray.splice(indexToRemove, 1);
			}

			return {
				...state,
				subscriptionMiniBookList: [...tempArray],
			};
		}
		case ADD_ITEM_BOOK_SUB_LIST: {
			if (!state.subscriptionBookList.includes(payload.symbol)) {
				return {
					...state,
					subscriptionBookList: [
						...state.subscriptionBookList,
						...[payload.symbol],
					],
				};
			}

			return { ...state };
		}
		case ADD_ITEM_MINI_BOOK_SUB_LIST: {
			if (!state.subscriptionMiniBookList.includes(payload.symbol)) {
				return {
					...state,
					subscriptionMiniBookList: [
						...state.subscriptionMiniBookList,
						...[payload.symbol],
					],
				};
			}

			return { ...state };
		}
		case ADD_ITEM_TRADE_SUB_LIST: {
			if (!state.subscriptionTradeList.includes(payload.symbol)) {
				return {
					...state,
					subscriptionTradeList: [
						...state.subscriptionTradeList,
						...[payload.symbol],
					],
				};
			}

			return { ...state };
		}
		case UPDATE_WEBSOCKET_CONNECTION:
			return { ...state, isConnected: payload.isConnected };

		default:
			return state;
	}
};
