import React from 'react';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';

import { StyledGeneralButton } from '../style';
import { Edit, Home, Replay, Save } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';

import {
	additionalUpdate,
	orderEntry,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { EXM } from 'utils/bulletContext';
import { apiMarketData } from 'services/api';
import { getStrategyTemplate } from 'utils/templates';
import { extractPositionsFromDescriptor } from '../utils';
import { getSubUid, getSubExchangeFromSecurityType } from 'utils/strategies';
import { addClipProportion } from 'utils/addClipProportion';

const ScannerHead = ({
	scannerValues,
	setScannerValues,
	saveSearch,
	selectedIndex,
	scannerSearch,
	strategyLegsScan,
	isQuickScan,
	timeStampHead,
	strategyRowSelected,
	searchsFound,
	actionListener,
	setIsFirstSave,
}) => {
	const dispatch = useDispatch();
	const buildStrategy = async (arrayLegs, side) => {
		const legsResponses = [];
		const newLegs = [];

		const setSpreadLegsQtd = length => {
			if (length < 2) {
				return 'sorder';
			}
			if (length > 1) {
				return `spread${length}p`;
			}
		};

		arrayLegs.split('#').forEach(leg => {
			legsResponses.push(
				apiMarketData.get(
					`/exchanges/BVMF/instruments/${leg.split('@')[1]}`
				)
			);
		});

		const setLegSide = legVal => {
			if (side) {
				return legVal < 0 ? '1' : '2';
			}
			return legVal < 0 ? '2' : '1';
		};

		const strategy = getStrategyTemplate(
			arrayLegs.split('#').length === 1 ? 'sorder' : 'spread'
		);

		Promise.all(legsResponses).then(values => {
			let strategyUpdated = {};
			arrayLegs.split('#').forEach((legs, legIndex) => {
				const separatedDesc = legs.split('@');
				newLegs.push({
					ILegAllocAccount: '',
					LegMaxClipSize: values[legIndex].data.minOrderQty,
					LegQuantity: Math.abs(separatedDesc[0]),
					LegResting: 'N',
					LegSecurityExchange: getSubExchangeFromSecurityType(
						values[legIndex].data.securityType
					),
					LegSide: setLegSide(separatedDesc[0]),
					LegSymbol: separatedDesc[1],
				});

				strategyUpdated = {
					...strategy,
					StrategyInfos: newLegs.map(() => {
						return {};
					}),
					StrategyCode: setSpreadLegsQtd(arrayLegs.split('#').length),
					StrategyLegs: newLegs,
					context: EXM,
				};
			});

			const strategyInfos = values.map(el => el.data);

			addClipProportion(strategyUpdated.StrategyLegs, strategyInfos);

			dispatch(orderEntry(strategyUpdated, getSubUid(strategyUpdated)));
			dispatch(additionalUpdate('isOpen', true));
			dispatch(additionalUpdate('isRecentListVisible', false));
			dispatch(additionalUpdate('isSavedListVisible', false));
			dispatch(updateStrategyInfosFromApi(strategyUpdated.StrategyLegs));
		});
	};

	const sendToSimulator = async (strategyLegsScan, row) => {
		const legsResponses = [];

		const _positions = extractPositionsFromDescriptor(strategyLegsScan);

		console.log(strategyLegsScan);
		_positions.forEach(pos => {
			legsResponses.push(
				apiMarketData.get(`/exchanges/BVMF/instruments/${pos.symbol}`)
			);
		});

		const createDate = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
		const simStratName = scannerValues.label
			? 'Scanner-' + scannerValues.label + '-' + createDate
			: 'Scanner-' + createDate;

		Promise.all(legsResponses).then(values => {
			const strategy = {
				underSymbol: searchsFound[row].underSymbol,
				underPx: searchsFound[row].underPrice,
				name: simStratName,
				positions: [
					..._positions.map((pos, index) => {
						//Já vem: symbol, qtty, entryPx, optionType, daysToExpiration, strikePx
						let p = {
							...pos,
							index,
							id: new Date().getTime() + index,
							subExchange: values[index].data.exchange,
							margin: values[index].data.margin,
							securityType:
								pos.securityType === 'OPT' ? 'OPT' : 'PS',
						};
						if (pos.securityType === 'OPT') {
							p['expirationDate'] =
								values[index].data.maturityDate;
						}
						return p;
					}),
				],
			};

			const data = {
				strategy: strategy,
				toSave: false,
				navToSimulator: true,
			};
			actionListener('add_sim_strategy', data);
		});
	};

	return (
		<Grid container>
			<Grid
				item
				container
				alignItems="center"
				xs={6}
				style={{ padding: 8 }}
			>
				<Typography variant="h5">
					<strong>Scanner</strong>
				</Typography>

				<Box color="text.secondary" pt={0.4} pl={1} pr={2}>
					<Typography variant="overline" fontWeight="bold">
						{scannerValues.filterIsOpen && scannerValues.graphIsOpen
							? ''
							: scannerValues.filterIsOpen &&
							  !scannerValues.graphIsOpen
								? `Editando parâmetros da pesquisa: ${scannerValues.label}`
								: 'Página inicial'}
					</Typography>
				</Box>

				{scannerValues.filterIsOpen && scannerValues.graphIsOpen ? (
					<Box display="flex" pt={0.3}>
						<Box pl={2}>
							<StyledGeneralButton
								variant="contained"
								buttonColor="success"
								onClick={() =>
									sendToSimulator(
										strategyLegsScan,
										strategyRowSelected
									)
								}
							>
								Simular
							</StyledGeneralButton>
						</Box>
						<Box pl={2}>
							<StyledGeneralButton
								variant="contained"
								buttonColor="info"
								onClick={() => buildStrategy(strategyLegsScan)}
							>
								Montar
							</StyledGeneralButton>
						</Box>
						<Box pl={2}>
							<StyledGeneralButton
								variant="contained"
								buttonColor="danger"
								onClick={() =>
									buildStrategy(strategyLegsScan, true)
								}
							>
								Reverter
							</StyledGeneralButton>
						</Box>
					</Box>
				) : (
					''
				)}
			</Grid>

			<Grid
				item
				container
				alignItems="center"
				justifyContent="flex-end"
				xs={6}
				style={{
					padding: 8,
					textAlign: 'right',
				}}
			>
				{scannerValues.filterIsOpen && scannerValues.graphIsOpen ? (
					<Box pr={2} pt={1}>
						Atualizado em: {timeStampHead}
					</Box>
				) : (
					''
				)}
				{!isQuickScan && (
					<>
						<Tooltip title="Editar pesquisa">
							<IconButton
								size="small"
								onClick={() =>
									setScannerValues({
										...scannerValues,
										filterIsOpen: true,
										graphIsOpen: false,
									})
								}
								disabled={
									!scannerValues.filterIsOpen &&
									scannerValues.graphIsOpen
										? false
										: !(
											scannerValues.filterIsOpen &&
												scannerValues.graphIsOpen
										  )
								}
							>
								<Edit fontSize="large" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Salvar pesquisa">
							<IconButton
								size="small"
								onClick={() => {
									saveSearch(
										scannerValues,
										'put',
										selectedIndex
									);
									setIsFirstSave(true);
								}}
								disabled={
									!(
										scannerValues.filterIsOpen ||
										scannerValues.graphIsOpen
									)
								}
							>
								<Save fontSize="large" />
							</IconButton>
						</Tooltip>
						<Tooltip title="Repetir consulta">
							<IconButton
								size="small"
								onClick={() => scannerSearch(scannerValues)}
								disabled={
									!(
										scannerValues.filterIsOpen &&
										scannerValues.graphIsOpen
									)
								}
							>
								<Replay fontSize="large" />
							</IconButton>
						</Tooltip>
					</>
				)}

				<Tooltip title="Início">
					<IconButton
						size="small"
						onClick={() => {
							saveSearch(scannerValues, 'home', selectedIndex);
							setIsFirstSave(false);
						}}
						disabled={
							!(
								scannerValues.filterIsOpen ||
								scannerValues.graphIsOpen
							)
						}
					>
						<Home fontSize="large" />
					</IconButton>
				</Tooltip>
			</Grid>
		</Grid>
	);
};

export default ScannerHead;
