import React from 'react';

import {
	GridToolbarContainer,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';

export const RecurrenceToolbar = (isLoading, profile) => {
	const isButtonsDisabled = isLoading || profile === 4;

	return (
		<GridToolbarContainer>
			<GridToolbarColumnsButton color="inherit" disabled={isButtonsDisabled} />

			<GridToolbarDensitySelector color="inherit" disabled={isButtonsDisabled} />
		</GridToolbarContainer>
	);
};
