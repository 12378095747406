import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ptBR } from 'date-fns/locale';
import { isBefore, parse, startOfDay } from 'date-fns';

import {
	Autocomplete,
	Button,
	FormControl,
	Grid,
	TextField,
} from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';

import {
	clearFormValues,
	updateFormValues,
} from 'store/modules/executions/actions';

import { onSuggestionsFetchRequested } from 'components/MaterialComponents/MaterialBullet/bulletFunctions';

export const ViewExecutionsSearchForm = ({
	isLoading,
	handleSearch,
	groupAccounts,
	setSnackbarState,
}) => {
	const dispatch = useDispatch();

	const { userType, profile } = useSelector(state => state.auth);
	const { searchForm, paginationInfo } = useSelector(state => state.executions);

	const [suggestions, setSuggestions] = useState([]);

	function handleChangeSearchFormValues(field, value) {
		dispatch(updateFormValues(field, value));
	}

	function handleClear() {
		dispatch(clearFormValues());
	}

	function onAutoCompleteChange(value, reason) {
		if (reason === 'clear') {
			handleChangeSearchFormValues('active', '');
		} else {
			if (!value) {
				return;
			}

			const symbol = value?.source?.symbol ?? value;

			if (symbol === undefined) {
				return;
			}

			handleChangeSearchFormValues('active', symbol);
		}
	}

	async function onAutoCompleteInputChange(event) {
		const value = event.target.value.toUpperCase();

		const suggestionsResponse = await onSuggestionsFetchRequested(
			value,
			false,
			false,
			0,
			'executions'
		);

		setSuggestions(suggestionsResponse);

		handleChangeSearchFormValues('active', value);
	}

	return (
		<form
			onSubmit={event =>
				handleSearch(
					{
						...paginationInfo,
						page: 0,
						offset: 0,
						total: null,
					},
					searchForm,
					event
				)
			}
			style={{ width: '100%', padding: '0 1rem' }}
		>
			<Grid xs={12} item container spacing={1}>
				<Grid item xs={2}>
					<TextField
						size="small"
						label="ID da estratégia"
						name="strategyId"
						value={searchForm.strategyId}
						onChange={event =>
							handleChangeSearchFormValues(
								event.target.name,
								event.target.value
							)
						}
						fullWidth
					/>
				</Grid>

				<Grid item xs={2.5}>
					<Autocomplete
						id="active-autocomplete"
						size="small"
						noOptionsText="Informe um instrumento"
						inputValue={searchForm.active}
						renderInput={params => (
							<TextField {...params} label="Instrumento" />
						)}
						options={
							suggestions?.map(option => option.source.symbol) ??
							[]
						}
						onChange={(_, newInstrument, reason) =>
							onAutoCompleteChange(newInstrument, reason)
						}
						onInput={event => onAutoCompleteInputChange(event)}
						disablePortal
						fullWidth
					/>
				</Grid>

				<Grid item xs={2}>
					{userType.id === 3 ? (
						<Autocomplete
							size="small"
							name="cblc"
							value={searchForm.cblc}
							options={groupAccounts}
							getOptionLabel={option => option}
							renderInput={params => (
								<TextField
									{...params}
									variant="outlined"
									label="CBLC"
									InputLabelProps={{ shrink: true }}
								/>
							)}
							onChange={(e, value) =>
								handleChangeSearchFormValues('cblc', value)
							}
							fullWidth
							filterSelectedOptions
						/>
					) : (
						<TextField
							label="CBLC"
							size="small"
							name="cblc"
							value={searchForm.cblc}
							InputLabelProps={{ shrink: true }}
							onChange={event =>
								handleChangeSearchFormValues(
									event.target.name,
									event.target.value
								)
							}
							fullWidth
						/>
					)}
				</Grid>

				<Grid item xs={2.5}>
					<FormControl size="small" variant="outlined" fullWidth>
						<LocalizationProvider
							dateAdapter={AdapterDateFns}
							adapterLocale={ptBR}
						>
							<DatePicker
								id="date"
								size="small"
								label="Data"
								inputFormat="dd/MM/yyyy"
								value={searchForm.date}
								renderInput={params => (
									<TextField size="small" {...params} />
								)}
								onChange={value => {
									const isStartDateValid = isBefore(
										startOfDay(value),
										parse('2023-11-01', 'yyyy-MM-dd', new Date())
									);

									if (isStartDateValid) {
										setSnackbarState({
											isOpen: true,
											severity: 'warning',
											message:
												'Os dados de execuções só estão disponíveis a partir da data 01/11/2023',
										});

										return;
									} else {
										handleChangeSearchFormValues(
											'date',
											value
										);
									}
								}}
								disableFuture
							/>
						</LocalizationProvider>
					</FormControl>
				</Grid>

				<Grid item xs={2}>
					<LoadingButton
						type="submit"
						loading={isLoading}
						variant="contained"
						startIcon={<Search />}
						disabled={profile === 4}
						sx={{ height: '100%' }}
						fullWidth
					>
						Procurar
					</LoadingButton>
				</Grid>

				<Grid item xs={1}>
					<Button
						variant="contained"
						sx={{
							height: '100%',
							bgcolor: '#fff',
							color: '#2196f3',
							'&:hover': {
								bgcolor: '#e7e7e7',
								color: '#1069b3',
							},
						}}
						onClick={handleClear}
						fullWidth
					>
						Limpar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
