import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { setPositionsTabIndex } from 'store/modules/panelConfig/actions';

import { PositionsTabs } from './PositionsTabs';
import { CurrentPositions } from './CurrentPositions';
import { ConsolidatedPositions } from './ConsolidatedPositions';

export const Positions = () => {
	const dispatch = useDispatch();

	const positionsTabIndex = useSelector(
		state => state.configs.positionsTabIndex
	);

	const TabPanel = ({ children, value, index, ...other }) => {
		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				style={{ height: '100%' }}
				{...other}
			>
				{value === index && children}
			</div>
		);
	};

	function handleChangePositionsTabIndex(newIndex) {
		dispatch(setPositionsTabIndex(newIndex));
	}

	return (
		<Box sx={{ height: 'calc(100% - 50px)', width: '100%' }}>
			<PositionsTabs
				tabIndex={positionsTabIndex}
				handleChangeTabIndex={handleChangePositionsTabIndex}
			/>

			<TabPanel index={0} value={positionsTabIndex}>
				<CurrentPositions />
			</TabPanel>

			<TabPanel index={1} value={positionsTabIndex}>
				<ConsolidatedPositions />
			</TabPanel>
		</Box>
	);
};
