import React from 'react';
import ReactGA from 'react-ga4';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { format, isValid, parse } from 'date-fns';

import {
	ClickAwayListener,
	Fade,
	List,
	MenuItem,
	Paper,
	Popper,
} from '@mui/material';
import { parseMenuAction } from 'services/parseMenuAction';
import { dispatchMenuAction } from 'utils/dispatchMenuAction';
import { api, apiExec, showMessageErroWithoutStatusCode } from 'services/api';
import {
	hasExecutedQuantity,
	showActionRevertByStrategyType,
} from 'utils/strategies';
import { viewExecutionStrategy } from 'services/viewExecutionStrategy';
import { saveStrategyInSimulator } from 'services/saveStrategyInSimulator';

export const StrategiesStrategyMenu = ({ isOpen, anchorEl, onClose, row, module = 'currentStrategies' }) => {
	const history = useHistory();
	const { userType, username, account, algos } = useSelector(state => state.auth);

	function getFormattedHour(hour) {
		let hourFormat = 'HH:mm:ss';

		if (hour.length > 8) {
			hourFormat = 'yyyy-MM-dd HH:mm:ss';
		}

		const parsedHour = parse(hour, hourFormat, new Date());

		if (isValid(parsedHour)) {
			const formattedHour = format(parsedHour, 'HH:mm:ss');

			return formattedHour;
		}

		return hour;
	}

	async function handleDispatchMenuAction(actionType) {
		if (['REVERT', 'CLONE'].includes(actionType)) {
			const strategyType = row.StrategyCode?.toUpperCase() ?? row.TargetStrategy;

			ReactGA.event({
				category: 'Execution',
				action: `HISTORY_STRATEGY_${actionType}_${strategyType}`,
			});
		}
		const formattedPositions = row.Legs.map(leg => ({
			...row,
			...leg,
			InitTime: getFormattedHour(row.InitTime),
			EndTime: getFormattedHour(row.EndTime),
			CustomParameters: {
				...row.CustomParameters,
				Trigger: Number(row.CustomParameters.Trigger),
				TriggerValue: Number(row.CustomParameters.TriggerValue),
			},
		}));

		const optionsAction = await parseMenuAction(
			row.ClOrdID,
			actionType,
			{ positions: formattedPositions, algos },
			api,
			apiExec,
			showMessageErroWithoutStatusCode
		);

		dispatchMenuAction(actionType, optionsAction);
		onClose();
	}

	function getVisibilityByStrategyCode(actionType) {
		if (actionType === 'clone') {
			if (row.StrategyCode) {
				return !['portbalance', 'marketmaker'].includes(row.StrategyCode);
			} else if (row.AtdlID) {
				return !['2004', '3001'].includes(row.AtdlID);
			}
		}

		if (actionType === 'edit') {
			if (row.StrategyCode) {
				return !['portbalance'].includes(row.StrategyCode);
			} else if (row.AtdlID) {
				return !['2004'].includes(row.AtdlID);
			}
		}

		if (actionType === 'suspend') {
			if (row.StrategyCode) {
				return !['grdlin', 'sorder', 'fxorder'].includes(row.StrategyCode);
			} else if (row.AtdlID) {
				return !['1032', '1007', '1038'].includes(row.AtdlID);
			}
		}

		return false;
	}

	const availableStatus = ![0, 1, 10, 99].includes(parseInt(row.OrdStatus ?? row.Status));

	const rowHasExecutedQuantity = hasExecutedQuantity(row);

	const menuActions = [
		{
			label: 'Editar',
			isVisible:
				module === 'currentStrategies' &&
				getVisibilityByStrategyCode('edit') &&
				[3, 4, 9].includes(parseInt(row.OrdStatus ?? row.Status)),
			action: () => handleDispatchMenuAction('EDIT')
		},
		{
			label: 'Suspender',
			isVisible:
				module === 'currentStrategies' &&
				getVisibilityByStrategyCode('suspend') && 
				[3].includes(parseInt(row.OrdStatus ?? row.Status)),
			action: () => handleDispatchMenuAction('SUSPEND')
		},
		{
			label: 'Reiniciar',
			isVisible:
				module === 'currentStrategies' &&
				[9].includes(parseInt(row.OrdStatus ?? row.Status)),
			action: () => handleDispatchMenuAction('RESUME')
		},
		{
			label: 'Cancelar',
			isVisible:
				module === 'currentStrategies' &&
				[2, 3, 4, 9].includes(parseInt(row.OrdStatus ?? row.Status)),
			action: () => handleDispatchMenuAction('CANCEL')
		},
		{
			label: 'Clonar',
			isVisible: availableStatus && getVisibilityByStrategyCode('clone'),
			action: () => handleDispatchMenuAction('CLONE'),
		},
		{
			label: 'Reverter',
			isVisible: availableStatus && rowHasExecutedQuantity && showActionRevertByStrategyType(row),
			action: () => handleDispatchMenuAction('REVERT'),
		},
		{
			label: 'Ver parâmetros',
			isVisible: true,
			action: () => handleDispatchMenuAction('PARAMS'),
		},
		{
			label: 'Ver execuções',
			isVisible: hasExecutedQuantity(row) && userType.id !== 2,
			action: () => viewExecutionStrategy(row),
		},
		{
			label: 'Ver no Simulador',
			isVisible: hasExecutedQuantity(row),
			action: async () => {
				const moduleFrom = module === 'currentStrategies' ? 'CURRENT_STRATEGIES' : 'HISTORY';
				ReactGA.event({
					category: 'Execution',
					action: `${moduleFrom}_STRATEGY_VIEW_SIMULATOR_${row.StrategyCode.toUpperCase()}`,
				});
				await saveStrategyInSimulator(row, username, account, history);
			},
		},
	];

	return (
		<>
			<Popper
				open={isOpen}
				anchorEl={anchorEl}
				placement="right"
				transition
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={onClose}>
						<Fade {...TransitionProps}>
							<Paper elevation={12} square={false}>
								<List
									sx={{
										display: 'flex',
										py: 0,
										borderRadius: 1,
									}}
								>
									{menuActions
										.filter(item => item.isVisible)
										.map(item => (
											<MenuItem
												key={item.label}
												onClick={item.action}
											>
												{item.label}
											</MenuItem>
										))}
								</List>
							</Paper>
						</Fade>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};
