import { store } from 'store';

import orderFilled from '../assets/sounds/orderFilled.mp3';
import suspendStrategy from '../assets/sounds/suspendStrategy.mp3';
import cashRegisterLoud from '../assets/sounds/cashRegisterLoud.mp3';
import { addStrategy } from 'store/modules/currentStrategies/actions';

function sortArrayByLegRefID(legs) {
	legs.sort((legA, legB) => {
		const idA = parseInt(legA.LegRefID.split(':')[1], 10);
		const idB = parseInt(legB.LegRefID.split(':')[1], 10);

		return idA - idB;
	});

	return legs;
}

function mergeLegs(currentRowLegs, snapshotLegs) {
	if (!currentRowLegs && !snapshotLegs) {
		return [];
	}

	if (!snapshotLegs || snapshotLegs.length < 1) {
		return currentRowLegs;
	}

	if (!currentRowLegs || currentRowLegs.length < 1) {
		return snapshotLegs;
	}

	snapshotLegs.forEach(snapshotLeg => {
		const currentLegIndex = currentRowLegs.findIndex(
			currentRow => currentRow.LegRefID === snapshotLeg.LegRefID
		);

		if (currentLegIndex === -1) {
			currentRowLegs.push(snapshotLeg);
		} else {
			currentRowLegs = currentRowLegs.map(currentRow => {
				if (currentRow.LegRefID === snapshotLeg.LegRefID) {
					return {
						...currentRow,
						...snapshotLeg,
					};
				} else {
					return currentRow;
				}
			});
		}
	});

	return sortArrayByLegRefID(currentRowLegs);
}

function notifyExecution(currentStatus, newStatus, isPartialExecution) {
	let audio = null;

	if (newStatus === '5' && currentStatus !== '5') {
		audio = new Audio(cashRegisterLoud);
	}

	if (newStatus === '9' && currentStatus !== '9') {
		audio = new Audio(suspendStrategy);
	}

	if (isPartialExecution) {
		audio = new Audio(orderFilled);
	}

	if (audio) {
		const userVolume =
			store.getState().configs.globalSettings?.userSettings?.platform
				.volume;

		audio.volume = (Number(userVolume) ?? 30) / 100;
		audio.play();
	}
}

function getPositionsAccount(account, legs) {
	if (account) {
		return account;
	} else if (legs && legs.length > 0) {
		return legs[0]?.ILegAllocAccount;
	}

	return null;
}

// Função para formatar as pernas com o padrão da tabela
function getFormattedSnapshotLeg(leg, strategy) {
	const formattedLegSide = leg.LegSide === '1' ? 'Compra' : 'Venda';
	const formattedName = `${leg.LegSymbol} - ${formattedLegSide}`;

	const legMessage =
		leg.LegMsg && leg.LegMsg !== '' ? { Text: leg.LegMsg } : {};

	return {
		...leg,
		id: leg.LegRefID,
		hierarchy: [strategy.ClOrdID, leg.LegRefID],
		Accounts: leg.ILegAllocAccount,
		Name: formattedName,
		CumQtyPerc: leg.LegCumQtyPerc,
		TargetQtty: leg.LegQuantity,
		ExecutedQtty: leg.LegExecQty,
		AvgPrice: leg.LegAvgPx,
		StatusDate: leg.LegDatedDate, // TODO: Chamar a funcao de datas criada para as VAC
		...legMessage,
	};
}

function getTimeInForce(newTimeInForce, oldTimeInForce) {
	if (newTimeInForce) {
		return { TimeInForce: newTimeInForce };
	}

	if (oldTimeInForce) {
		return { TimeInForce: oldTimeInForce };
	}

	return {};
}

function getNumericKeyValues(snapshot, keysToUpdate, selectedRow = {}) {
	let numericKeyValues = {};

	if (
		!['grdlin', 'grddin'].includes(
			snapshot.StrategyCode ?? selectedRow?.StrategyCode
		)
	) {
		keysToUpdate.forEach(key => {
			numericKeyValues[key] = snapshot[key] ?? selectedRow?.[key] ?? 0;
		});

		return numericKeyValues;
	}

	return {};
}

export function formatSnapshot(snapshot, apiRef, soundOn) {
	console.log('formatSnapshot', soundOn);
	const { accountType, username } = store.getState().auth;
	const environmentType = accountType === 'demo' ? 'SIM' : 'PROD';

	if (snapshot.Env && snapshot.Env !== environmentType) {
		return;
	}

	if (snapshot.UserName && snapshot.UserName !== username) {
		return;
	}

	if (snapshot.MsgType === 'USN') {
		const rowsToChange = [];

		const rowIds = apiRef.current.getAllRowIds();

		const isRowExists = rowIds.find(
			id => String(id) === String(snapshot.ClOrdID)
		);

		if (isRowExists) {
			const selectedRow = apiRef.current.getRow(snapshot.ClOrdID);

			const mergedLegs = mergeLegs(selectedRow.Legs, snapshot.Legs);
			const formattedAccount =
				selectedRow.Accounts ??
				getPositionsAccount(snapshot.Accounts, snapshot.Legs);

			const strategyStatus =
				snapshot.OrdStatus ?? snapshot.Status ?? selectedRow.Status;

			const formattedTimeInForce = getTimeInForce(
				snapshot.TimeInForce,
				selectedRow.TimeInForce
			);

			const numericFieldsToFormat = [
				'CumQtyPerc',
				'TargetQtty',
				'ExecutedQtty',
			];

			const formattedNumericValues = getNumericKeyValues(
				snapshot,
				numericFieldsToFormat,
				selectedRow
			);

			const updatedRow = {
				...selectedRow,
				...snapshot,
				Status: strategyStatus,
				Legs: mergedLegs,
				Accounts: formattedAccount,
				...formattedTimeInForce,
				...formattedNumericValues,
			};

			const formattedRow = {
				...updatedRow,
				id: snapshot.ClOrdID,
				hierarchy: [snapshot.ClOrdID],
			};

			if (
				snapshot.OrdStatus &&
				selectedRow.OrdStatus !== snapshot.OrdStatus
			) {
				store.dispatch(addStrategy(formattedRow));
			}

			rowsToChange.push(formattedRow);

			if (updatedRow.Legs && updatedRow.Legs.length > 0) {
				updatedRow.Legs.forEach(leg => {
					const formattedSnapshotLeg = getFormattedSnapshotLeg(
						leg,
						selectedRow
					);

					rowsToChange.push({
						...formattedSnapshotLeg,
						Status: strategyStatus,
					});
				});
			}

			const isPartialExecution =
				snapshot.ExecutedQtty &&
				snapshot.ExecutedQtty !== selectedRow.ExecutedQtty;

			if (soundOn) {
				notifyExecution(
					selectedRow.Status,
					snapshot.OrdStatus,
					isPartialExecution
				);
			}
		} else {
			const strategyStatus = snapshot.OrdStatus ?? snapshot.Status ?? 1;

			const numericFieldsToFormat = [
				'CumQtyPerc',
				'TargetQtty',
				'ExecutedQtty',
			];

			const formattedNumericValues = getNumericKeyValues(
				snapshot,
				numericFieldsToFormat
			);

			const formattedStrategy = {
				...snapshot,
				id: snapshot.ClOrdID,
				hierarchy: [snapshot.ClOrdID],
				Status: strategyStatus,
				Accounts: getPositionsAccount(snapshot.Accounts, snapshot.Legs),
				...formattedNumericValues,
			};

			rowsToChange.push(formattedStrategy);
			store.dispatch(addStrategy(formattedStrategy));

			if (snapshot.Legs && snapshot.Legs.length > 0) {
				snapshot.Legs.forEach(leg => {
					const formattedSnapshotLeg = getFormattedSnapshotLeg(
						leg,
						snapshot
					);

					rowsToChange.push({
						...formattedSnapshotLeg,
						Status: strategyStatus,
					});
				});
			}
		}

		if (rowsToChange.length > 0) {
			apiRef.current.updateRows(rowsToChange);
		}
	}
}
