import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Box, FormControl, TextField, Typography } from '@mui/material';

import { api, apiExec } from 'services/api';
import { setAccountType } from '../../store/modules/auth/actions';

import { PasswordInput } from 'components/PasswordInput';
import { sendValidationCode } from 'services/sendValidationCode';

const REQUEST_TIMEOUT = 15 * 1000;

export const LoginForm = ({
	setAuthToken,
	setModalIsOpen,
	setLoginValues,
	setMfaCodeInfo,
	setIsTimerFinished,
	setAuthMethod,
	setLoginStep,
}) => {
	const dispatch = useDispatch();

	const [isVisible, setIsVisible] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [errors, setErrors] = useState({ user: false, psswd: false });
	const [formValues, setFormValues] = useState({ user: '', psswd: '' });
	const [whichButtonIsLoading, setWhichButtonIsLoading] = useState({
		demo: false,
		real: false,
	});

	function realAccountLogin(token, userName) {
		const headers = { headers: { Authorization: `Bearer ${token}` } };
		const formattedURL = `${process.env.REACT_APP_USR_DMA_API_URL}/authenticators`;

		apiExec
			.get(formattedURL, headers)
			.then(async response => {
				const authMethod =
					response.data.activeAuthenticatorType ?? 'email';

				if (authMethod === 'email') {
					const validationsInfo = await sendValidationCode(userName);
					setMfaCodeInfo(validationsInfo);
					setIsTimerFinished(false);
				}

				setAuthMethod(authMethod ?? 'email');
				setLoginStep('auth');
			})
			.catch(error => console.log(error));
	}

	function handleLoginSubmit(credentials, environmentType) {
		setIsLoading(true);
		localStorage.setItem('paperSuggestions', '{}');

		const tempUrl =
			environmentType === 'real'
				? process.env.REACT_APP_USR_DMA_API_URL
				: process.env.REACT_APP_USR_SIM_API_URL;

		const requestConfig = {
			headers: {
				'x-api-key': process.env.REACT_APP_API_KEY_FLEXSCAN,
			},
			timeout: REQUEST_TIMEOUT,
		};

		api.post(`${tempUrl}/connect/login`, credentials, requestConfig)
			.then(response => {
				setAuthToken(response.data.auth_token);
				setLoginValues(credentials);

				if (environmentType === 'real') {
					realAccountLogin(
						response.data.auth_token,
						credentials.user
					);
				} else {
					setLoginStep('contractValidation');
				}
			})
			.catch(error => {
				setWhichButtonIsLoading({ demo: false, real: false });
				setIsLoading(false);

				if (
					error?.response?.data?.code &&
					error.response.data.code === 3400
				) {
					setModalIsOpen(true);
				}
			});
	}

	function changeAccountType(type) {
		if (['real', 'demo'].includes(type)) {
			dispatch(setAccountType(type));
		} else {
			toast.error(
				'Erro ao selecionar ambiente. Tente novamente mais tarde!'
			);
		}
	}

	function handleSubmit(environmentType) {
		if (
			formValues.user &&
			formValues.user !== '' &&
			formValues.psswd &&
			formValues.psswd !== ''
		) {
			if (environmentType === 'real') {
				setWhichButtonIsLoading({ demo: false, real: true });
			} else {
				setWhichButtonIsLoading({ demo: true, real: false });
			}

			changeAccountType(environmentType);
			handleLoginSubmit(formValues, environmentType);
		} else {
			toast.error('Preencha todos os campos!');
		}
	}

	function handleUserChange(value) {
		if (!value || value === '') {
			setErrors({ ...errors, user: true });
		} else if (errors.user) {
			setErrors({ ...errors, user: false });
		}

		setFormValues({ ...formValues, user: String(value).toLowerCase() });
	}

	function handlePasswordChange(value) {
		if (!value || value === '') {
			setErrors({ ...errors, psswd: true });
		} else if (errors.psswd) {
			setErrors({ ...errors, psswd: false });
		}

		setFormValues({ ...formValues, psswd: value });
	}

	const isSubmitButtonDisabled = errors.psswd || errors.user;

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				gap: 2,
				mt: 2,
				mb: 1,
			}}
		>
			<FormControl fullWidth>
				<TextField
					required
					fullWidth
					id="user"
					name="user"
					label="Usuário"
					error={errors.user}
					value={formValues.user}
					onChange={event => handleUserChange(event.target.value)}
					sx={{
						'.MuiOutlinedInput-root': {
							boxShadow: 'none !important',
						},
						'.MuiInputLabel-root': {
							color: '#888888',
							textShadow: 'none',
						},
					}}
				/>

				{errors.user && (
					<Typography variant="body2" color="error" sx={{ mt: 0.5 }}>
						Campo obrigatório
					</Typography>
				)}
			</FormControl>

			<PasswordInput
				required
				id="psswd"
				name="psswd"
				label="Senha"
				error={errors.psswd}
				value={formValues.psswd}
				isPasswordVisible={isVisible}
				sx={{ boxShadow: 'none !important' }}
				labelStyles={{ color: '#888888', textShadow: 'none' }}
				changePasswordVisibility={() => setIsVisible(!isVisible)}
				onChange={event => handlePasswordChange(event.target.value)}
				helperText="Campo obrigatório"
				autoComplete="login-password"
				inputProps={{ 'aria-autocomplete': 'login-password' }}
			/>

			<LoadingButton
				data-cy="demo"
				fullWidth
				size="large"
				color="warning"
				variant="contained"
				disabled={whichButtonIsLoading.real || isSubmitButtonDisabled}
				loading={isLoading && whichButtonIsLoading.demo}
				onClick={() => handleSubmit('demo')}
				sx={{ mt: 1 }}
			>
				Ambiente Simulado
			</LoadingButton>

			<LoadingButton
				data-cy="real"
				fullWidth
				size="large"
				color="success"
				variant="contained"
				disabled={whichButtonIsLoading.demo || isSubmitButtonDisabled}
				loading={isLoading && whichButtonIsLoading.real}
				onClick={() => handleSubmit('real')}
			>
				Ambiente Real
			</LoadingButton>
		</Box>
	);
};
