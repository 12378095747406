// external libs
import React, { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { getOptUnderlyingStockSecurityTypes } from '@investflex/iflexquantjs';

// mui components
import {
	Save,
	ArrowDropDown,
	DeviceHub,
	DoneAll,
	Book,
	Article,
} from '@mui/icons-material';
import {
	Box,
	Button,
	ButtonGroup,
	ClickAwayListener,
	Divider,
	Grid,
	Grow,
	IconButton,
	ListItemIcon,
	ListItemText,
	MenuItem,
	MenuList,
	Paper,
	Popper,
	Typography,
} from '@mui/material';

// auxiliary functions
import { store } from 'store';
import {
	strategyUpdate,
	setInterestRate,
} from 'store/modules/simulator/actions';
import {
	orderEntry,
	additionalUpdate,
	updateStrategyInfosFromApi,
} from 'store/modules/bottomBullet/actions';
import { updatePosition } from 'store/modules/simulator/actions';
import { setOptions, setPaper } from 'store/modules/cache/actions';
import { updateSavedParameter } from 'store/modules/portfolio/actions';

import { roundNumber } from 'utils/numberFormat';
import {
	positionModel,
	getStrikePx,
	getOptions,
	getPaper,
	getStrategy,
	getStrategyReportData,
} from './utils';
import { handleStrategyBullet } from './handleBullet';
import { apiMarketData } from 'services/api';
import getElasticSearchValue from 'services/getElasticSearchValue';
import addWidgetFromButton from 'utils/addWidgetFromButton';
import { EXM } from 'utils/bulletContext';
import { getSubUid } from 'utils/strategies';
import { handleSaveStrategy } from 'utils/handleSaveStrategy';

// auxiliary components
import FormSaveStrategy from './components/FormSaveStrategy';
import { ModalPdf } from '../../components/ModalPdf';
import { SimulatorDecimalInput } from './components/DecimalInput';
import PdfTemplate from '../../components/PdfTemplate';
import PaperSearch from 'services/paperSearch';
import { LightTooltip } from 'components/LightTooltip';
import { addClipProportion } from 'utils/addClipProportion';

const TableTools = ({
	quotationServiceFunc,
	addLeg,
	setShowCircularProgressCallback,
}) => {
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);
	const ir = useSelector(
		state => state.simulator.ir ?? state.market.interestRate
	);

	let strategyList = useSelector(state => state.simulator.strategyList);
	let strategy = useSelector(getStrategy);
	let { saved } = strategy;

	const paper = useSelector(getPaper);
	const { price } = paper;

	const username = useSelector(state => state.auth.username);
	const account = useSelector(state => state.auth.account);
	const lockBook = useSelector(state => state.configs.widgetIsLoading);

	const popperRef = useRef(null);

	const [isPopperOpen, setIsPopperOpen] = useState(false);
	const [allStrategySaved, setAllStrategySaved] = useState(true); //Armazena se todas as estratégias estão salvas
	const [openFormSaveas, setFormSaveas] = useState(false); //Added to save as
	const [strategyToSaveasOrClone, setStrategyToSaveasOrClone] =
		useState(null); //Added to save as or clone
	const [openFormClone, setFormClone] = useState(false); //Added to clone
	const [updatedAt, setUpdatedAt] = useState();
	const dispatch = useDispatch();
	const options = useSelector(getOptions);
	const [stratSaving, setStratSaving] = useState(false);
	const [isModalOpenPdf, setIsModalOpenPdf] = useState(false);
	const [nameStrategyPdf, setNameStrategyPdf] = useState('');
	const [comments, setComments] = useState('');
	const [pdfData, setPdfData] = useState(null);
	const [isVisiblePdfReport, setIsVisiblePdfReport] = useState();
	const [initPrice, setInitPrice] = useState(0);
	const [endPrice, setEndPrice] = useState(0);

	function handleDataPDF() {
		const strategyData = getStrategyReportData(
			nameStrategyPdf,
			comments,
			initPrice,
			endPrice
		);
		setPdfData(strategyData);
	}

	function handleIntervalPrices() {
		const newInitPrice = paper?.price - (paper?.price * 10) / 100;
		const newEndPrice = paper?.price + (paper?.price * 10) / 100;

		setInitPrice(newInitPrice);
		setEndPrice(newEndPrice);
	}

	function handleOpenModalPdf() {
		if (strategy?.positions?.length > 0) {
			handleIntervalPrices();
			setIsModalOpenPdf(true);
		}
	}

	function handleCloseModalPdf() {
		setComments('');
		setIsModalOpenPdf(false);
	}

	function verifyAccount() {
		let accountVerify = false;

		if (
			[
				'fut_hernan',
				'flx_maira',
				'fut_21035',
				'flx_lceravolo',
				'fut_isilva',
				'flx_durvalsingulare',
				'gui_latarch',
				'gui_luiz',
				'fut_rtom',
				'flx_flavioguarino',
				'ora_tteixe',
				'fut_mairal',
				'ora_mliz',
				'ora_reiji',
				'ora_oraloe',
				'ora_viniciu61330',
				'fut_gjunq',
				'ora_sergmf',
				'flx_ermel',
				'flx_Ermel',
				'flx_guto',
				'ora_felippe53244',
				'flx_junger',
				'flx_lemos',
			].includes(username)
		) {
			accountVerify = true;
		} else {
			accountVerify = false;
		}

		setIsVisiblePdfReport(accountVerify);
	}

	useEffect(() => {
		verifyAccount();
	}, []);

	useEffect(() => {
		if (pdfData) {
			const pdf = new jsPDF('p', 'mm', 'a4');
			const domElement = document.getElementById('container');
			const footer2 = document.getElementById('footer2');

			html2canvas(domElement, {
				scale: 4,
			}).then(canvas => {
				const imgData = canvas.toDataURL('image/jpeg', 1);

				const width = pdf.internal.pageSize.width;
				const height = pdf.internal.pageSize.height;

				if (footer2) {
					pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
					html2canvas(footer2, {
						scale: 4,
					}).then(canvas2 => {
						const imgDataFooter = canvas2.toDataURL(
							'image/jpeg',
							1
						);
						pdf.addPage();
						pdf.addImage(imgDataFooter, 'JPEG', 0, 0, 210, 70);
						pdf.save('Relatorio.pdf');
					});
				} else {
					pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
					pdf.save('Relatorio.pdf');
				}
			});

			handleCloseModalPdf();
			setNameStrategyPdf('');
			setComments('');

			toast.success('Relatório Gerado com sucesso');
		}
	}, [pdfData]);

	useEffect(() => {
		if (stratSaving) {
			setTimeout(() => {
				setStratSaving(false);
			}, 3000);
		}
	}, [stratSaving]);

	useEffect(() => {
		const date = new Date();
		setUpdatedAt(date);
	}, [price]);

	//Criada variavel separada para marcar os botões de salvar todas e descartar todas separadamente
	useEffect(() => {
		setAllStrategySaved(
			!strategyList.find(
				i => typeof i.saved === 'boolean' && i.saved === false
			)
		);
		setStratSaving(false);
	}, [saved, activeStrategy, strategyList]);

	useEffect(() => {
		const activeStrategyTab = store
			.getState()
			.portfolio?.activeStrategies.find(stt => stt.id === strategy.id);

		if (activeStrategyTab && activeStrategyTab.saved !== saved) {
			dispatch(updateSavedParameter({ id: strategy.id, saved }));
		}
	}, [saved]);

	//Adicionado para atualizar daysToExpiration quando a partir do options.workDaysUntilMaturity
	useEffect(() => {
		if (strategy && options && options.length > 0) {
			strategy.positions.forEach(position => {
				//Para todas as posições da estrategia
				if (!position.expired && position.securityType === 'OPT') {
					//Perna expirada não tem dte
					//Procura pelo maturity no options baseado na expiração do positions - expirationDate tem formato 2020-12-11 enquanto que maturityDate tem 20201211
					const expirationDate = (
						position.expirationDate ?? ''
					).replaceAll('-', '');
					const _workDaysUntilMaturity = options.find(
						i => i.maturityDate === expirationDate
					)?.workDaysUntilMaturity;
					const _contractMultiplier = options.find(
						i => i.maturityDate === expirationDate
					)?.contractMultiplier;
					dispatch(
						updatePosition(activeStrategy, position.id, {
							daysToExpiration: _workDaysUntilMaturity,
							contractMultiplier: _contractMultiplier || 1,
						})
					);
				}
			});
		}
	}, [options]);

	const handleAddPosition = type => {
		if (type === 'ATIVOS' && !paper?.minOrderQty > 0) return;
		if (!paper || !paper.symbol) {
			return toast.error(
				'Erro ao obter informações do papel, tente novamente!'
			);
		}
		if (
			!paper.securityType ||
			!getOptUnderlyingStockSecurityTypes().includes(paper.securityType)
		) {
			return toast.error('Ativo inválido!');
		}
		let position = {
			...positionModel,
			id: new Date().getTime(),
			subExchange: paper.subExchange,
			account,
			securityId: paper.securityId,
		};
		if (type === 'ATIVOS') {
			position = {
				...position,
				symbol: paper.symbol,
				securityType: paper.securityType,
				margin: paper.margin,
				contractMultiplier: paper.contractMultiplier,
			};
			position.entryGreeks.delta = 1;
			position.exitGreeks.delta = 1;
		} else {
			const listStrikePxExisting = strategy?.positions
				.filter(p => p.strikePx)
				.map(p => p.strikePx);
			const { indexValue, data } = getStrikePx(
				options,
				paper.price,
				format(new Date(), 'yyyyMMdd'),
				type,
				listStrikePxExisting
			);
			if (indexValue === -1 || !data[indexValue]?.symbol)
				return toast.error(
					'Erro ao obter informações do papel, tente novamente!'
				);
			position = {
				...position,
				optionType: type === 'CALL' ? 'C' : 'P',
				symbol: data[indexValue].symbol,
				margin: data[indexValue].margin,
				securityType: data[indexValue].securityType,
				strikePx: data[indexValue].strikePrice,
				//Tentativa de correção para forçar o workDaysUntilMaturity correto quando exibe incorretamente
				expirationDate: data[indexValue].maturityDate,
				daysToExpiration: data[indexValue].workDaysUntilMaturity,
				contractMultiplier: data[indexValue].contractMultiplier,
			};
		}

		if (activeStrategy !== null) {
			//if (strategy.positions?.length === 0 && initChart) initChart()
			addLeg(strategy.positions?.length + 1);
			dispatch(
				strategyUpdate({
					...strategyList[activeStrategy],
					underSymbol: paper.symbol,
					underType: paper.securityType,
					positions: [
						...strategyList[activeStrategy].positions,
						{
							...position,
							index: strategyList[activeStrategy].positions
								.length,
						},
					],
				})
			);
		}
	};

	const handleSaveAsStrategy = item => {
		// if(strategyList.length > 20){
		//   return toast.error('Número máximo de estratégias salvas atingido')
		// }
		setStrategyToSaveasOrClone(item);
		setFormSaveas(true);
	};

	//Atenção para refletir alterações a serem feitas nessa função a função em handleSaveStrategyAs, pois guardam semelhança
	const handleSave = strategyToSave => {
		if (stratSaving) {
			return;
		}

		setStratSaving(true);
		handleSaveStrategy(strategyToSave, paper);
	};

	function handleSimulatorBullet(side) {
		const result = handleStrategyBullet(
			side,
			strategyList[activeStrategy],
			paper,
			options,
			format
		);

		if (result === 7) {
			toast.error(
				'Não é possível a execução de estratégia de mais de 6 pernas'
			);
			return;
		} else if (result === 0) {
			toast.error('Não existem pernas em aberto para execução');
			return;
		}

		addClipProportion(result.StrategyLegs, result.StrategyInfos);

		if (
			!Array.isArray(result.StrategyInfos) ||
			!result.StrategyInfos.length ||
			!Array.isArray(result.StrategyLegs) ||
			!result.StrategyLegs.length
		) {
			toast.error('Não existem pernas em aberto para execução');
		} else {
			result.context = EXM;
			dispatch(orderEntry(result, getSubUid(result)));
			dispatch(additionalUpdate('isOpen', true));
			dispatch(updateStrategyInfosFromApi(result.StrategyLegs));
			dispatch(additionalUpdate('resetMarketPrices', true));
			dispatch(additionalUpdate('isRecentListVisible', false));
			dispatch(additionalUpdate('isSavedListVisible', false));
			dispatch(updateStrategyInfosFromApi(result.StrategyLegs));
			dispatch(additionalUpdate('resetMarketPrices', true));
		}
	}

	const isSimdisabled =
		!paper ||
		!paper.price ||
		!paper.symbol ||
		!options ||
		options.length === 0 ||
		strategy?.positions?.length === 0;

	function setPaperInfo(data) {
		if (!data || !data?.symbol) {
			return;
		}

		if (data?.price !== paper?.price) {
			dispatch(setPaper(data.symbol, data));
		}

		const changeSymbol = paper?.symbol && data.symbol !== paper?.symbol;
		const strategyModel = { ...strategy };

		if (changeSymbol) {
			const chart = document.getElementById('sim-chart');

			if (chart) {
				chart.innerHTML = '';
			}

			strategyModel.positions = [];
		}

		dispatch(
			strategyUpdate({
				...strategyModel,
				underSymbol: data?.symbol,
				underType: data?.securityType,
				histVol30: data?.histVol30,
				histVol90: data?.histVol90,
				histVol180: data?.histVol180,
				histVol360: data?.histVol360,
				subExchange: data?.subExchange,
			})
		);
	}

	function handleToggle() {
		setIsPopperOpen(prevOpen => !prevOpen);
	}

	function handleClose(event) {
		if (popperRef.current && popperRef.current.contains(event.target)) {
			return;
		}

		setIsPopperOpen(false);
	}

	const saveActions = [
		{
			label: 'Salvar como',
			icon: Save,
			disabled:
				!paper ||
				!strategy ||
				(strategy && strategy?.positions?.length === 0)
					? true
					: false,
			handleClick: () => handleSaveAsStrategy(strategy),
		},
		{
			label: 'Criar cópia',
			icon: DeviceHub,
			disabled:
				(activeStrategy >= 0 && !strategyList[activeStrategy]?.saved) || //Não permite clonar estratégia não salva
				!paper ||
				!strategy ||
				(strategy && strategy?.positions?.length === 0)
					? true
					: false,
			handleClick: () => handleSaveAsStrategy(strategy),
		},
		{
			label: 'Salvar tudo',
			icon: DoneAll,
			disabled: allStrategySaved,
			handleClick: () => {
				strategyList.forEach(strat => {
					//Salva todas as estratégias não salvas
					if (!strat?.saved) {
						//Se não está salvo, salva
						handleSave(strat);
					}
				});

				//Assumo que salvar todas vai funcionar
				setAllStrategySaved(true);
			},
		},
	];

	const greenButtonStyles = {
		color: '#fff !important',
		backgroundColor: '#206123a1 !important',
		'&.Mui-disabled': {
			backgroundColor: '#666 !important',
			opacity: 0.8,
		},
	};

	return (
		<>
			<PaperSubscriber
				quotationServiceFunc={quotationServiceFunc}
				strategy={strategy}
				activeStrategy={activeStrategy}
				paper={paper}
				setShowCircularProgressCallback={
					setShowCircularProgressCallback
				}
				options={options}
			/>

			{(openFormSaveas || openFormClone) && (
				<FormSaveStrategy
					strategyToSave={strategyToSaveasOrClone}
					openForm={openFormSaveas || openFormClone}
					setForm={openFormSaveas ? setFormSaveas : setFormClone}
					saveMode={openFormSaveas ? 'saveas' : 'clone'}
				/>
			)}

			{activeStrategy !== null && (
				<Grid
					container
					item
					xs={12}
					sx={{
						alignItems: 'flex-end',
						p: 1,
						justifyContent: 'space-between',
					}}
				>
					<Grid item sx={{ display: 'flex', gap: 2 }}>
						<Grid
							item
							sx={{
								display: 'flex',
								alignItems: 'flex-end',
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									gap: 0.5,
								}}
							>
								<Typography sx={{ ml: 0.25 }} variant="body1">
									Ativo
								</Typography>
								<PaperSearch
									data-cy="paperField"
									index={0}
									blocked={
										strategyList[activeStrategy]
											?.persisted ?? false
									}
									onlyUnderlying={true}
									excludeFracionary={true}
									setPaperInfo={(_, data) =>
										setPaperInfo(data)
									}
									value={strategy?.underSymbol || ''}
								/>
							</Box>

							<IconButton
								size="small"
								disabled={lockBook}
								onClick={() =>
									addWidgetFromButton(strategy?.underSymbol)
								}
							>
								<LightTooltip arrow title="Abrir book">
									<Book />
								</LightTooltip>
							</IconButton>
						</Grid>

						<Grid
							item
							display="flex"
							sx={{
								display: 'flex',
								gap: 1,
								alignItems: 'flex-end',
							}}
						>
							<Typography
								variant="h5"
								fontWeight="bold"
								color="#ce8100"
							>
								{paper?.price
									? roundNumber(paper?.price)
									: 'N/A'}
							</Typography>
						</Grid>

						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 0.5,
								alignItems: 'center',
								justifyContent: 'flex-end',
							}}
						>
							<Typography
								variant="body2"
								color={
									paper?.var
										? Math.sign(paper?.var) < 0
											? '#ff5a5a'
											: '#37b53d'
										: '#FF8C04'
								}
							>
								{paper?.var || paper?.var === 0
									? `${roundNumber(paper?.var)}%`
									: '-'}
							</Typography>

							<Typography variant="body2">
								{paper?.symbol
									? updatedAt?.toLocaleTimeString('pt-PT', {
										hour12: false,
									  })
									: ''}
							</Typography>
						</Grid>

						<Divider orientation="vertical" flexItem light />

						<Grid item>
							<Typography variant="body1" sx={{ mb: 0.25 }}>
								Juros
							</Typography>

							<SimulatorDecimalInput
								disabled={false}
								minVal={0}
								maxVal={999999}
								minDecScale={4} // casas decimais
								stepSize={0.1} // incremento
								value={ir * 100}
								width={60}
								textColor="#FF8C04"
								onChange={e => {
									if (typeof e.value === 'undefined') {
										return;
									}

									const ir = parseFloat(
										(parseFloat(e.value) / 100).toFixed(4)
									);

									dispatch(setInterestRate(ir));
								}}
							/>
						</Grid>

						<Divider orientation="vertical" flexItem light />

						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 0.5,
							}}
						>
							<Typography variant="body1">
								Agregar pernas
							</Typography>
							<Box fullWidth sx={{ display: 'flex', gap: 1 }}>
								<Button
									variant="contained"
									size="small"
									data-cy="activeBtn"
									disabled={
										!paper ||
										!paper.price ||
										!paper?.minOrderQty ||
										!paper.symbol
									}
									onClick={e => {
										if (!e.disabled) {
											handleAddPosition('ATIVOS');
										}
									}}
								>
									+Ativo
								</Button>

								<Button
									variant="contained"
									size="small"
									data-cy="callBtn"
									disabled={
										!paper ||
										!paper.price ||
										!paper.symbol ||
										paper.symbol !==
											strategy?.underSymbol ||
										!options ||
										options.length === 0
									}
									onClick={e => {
										if (!e.disabled) {
											handleAddPosition('CALL');
										}
									}} //Corrigido para esperar as opções chegarem
								>
									+Call
								</Button>

								<Button
									variant="contained"
									size="small"
									data-cy="putBtn"
									disabled={
										!paper ||
										!paper.price ||
										!paper.symbol ||
										!options ||
										options.length === 0
									}
									onClick={e => {
										if (!e.disabled) {
											handleAddPosition('PUT');
										}
									}} //Corrigido para esperar as opções chegarem
								>
									+Put
								</Button>
							</Box>
						</Grid>

						<Divider orientation="vertical" flexItem light />

						<Grid
							item
							sx={{
								display: 'flex',
								flexDirection: 'column',
								gap: 0.5,
							}}
						>
							<Typography variant="body1">Execução</Typography>
							<Box
								sx={{
									display: 'flex',
									gap: 1,
								}}
							>
								<Button
									size="small"
									variant="contained"
									data-cy="setBtn"
									disabled={isSimdisabled}
									onClick={() => handleSimulatorBullet(false)}
								>
									Montar
								</Button>

								<Button
									size="small"
									variant="contained"
									data-cy="resetBtn"
									color="error"
									disabled={isSimdisabled}
									onClick={() => handleSimulatorBullet(true)}
									sx={{ mt: 0.125 }}
								>
									Reverter
								</Button>
							</Box>
						</Grid>
					</Grid>

					<Grid
						item
						sx={{
							display: 'flex',
							gap: 2,
							justifyContent: 'flex-end',
						}}
					>
						<Grid item>
							<>
								<ButtonGroup
									variant="contained"
									size="small"
									color="success"
									ref={popperRef}
								>
									<Button
										sx={{ ...greenButtonStyles }}
										disabled={
											activeStrategy >= 0 &&
											(stratSaving ||
												strategyList[activeStrategy]
													?.saved)
										}
										onClick={() => handleSave(strategy)}
										startIcon={<Save />}
									>
										Salvar
									</Button>

									<Button
										onClick={handleToggle}
										sx={{
											position: 'relative',
											...greenButtonStyles,
										}}
									>
										<ArrowDropDown />
									</Button>
								</ButtonGroup>

								{activeStrategy !== null && (
									<Popper
										sx={{ zIndex: 10 }}
										open={isPopperOpen}
										anchorEl={popperRef.current}
										role={undefined}
										transition
										disablePortal
									>
										{({ TransitionProps, placement }) => (
											<Grow
												{...TransitionProps}
												style={{
													transformOrigin:
														placement === 'bottom'
															? 'center top'
															: 'center bottom',
												}}
											>
												<Paper>
													<ClickAwayListener
														onClickAway={
															handleClose
														}
													>
														<MenuList autoFocusItem>
															{saveActions.map(
																option => (
																	<MenuItem
																		key={
																			option.label
																		}
																		disabled={
																			option.disabled
																		}
																		onClick={
																			option.handleClick
																		}
																	>
																		<ListItemIcon>
																			<option.icon />
																		</ListItemIcon>

																		<ListItemText>
																			{
																				option.label
																			}
																		</ListItemText>
																	</MenuItem>
																)
															)}
														</MenuList>
													</ClickAwayListener>
												</Paper>
											</Grow>
										)}
									</Popper>
								)}
							</>
						</Grid>

						{isVisiblePdfReport && (
							<Grid item>
								<Button
									variant="contained"
									size="small"
									fullWidth
									onClick={handleOpenModalPdf}
									color="success"
									disabled={!paper?.price ? true : false}
									startIcon={<Article />}
									sx={{ ...greenButtonStyles }}
								>
									Relatório
								</Button>
							</Grid>
						)}
					</Grid>

					{activeStrategy !== null && (
						<ModalPdf
							isModalOpen={isModalOpenPdf}
							handleClose={handleCloseModalPdf}
							setNameStrategy={setNameStrategyPdf}
							comments={comments}
							setComments={setComments}
							handleDataPDF={handleDataPDF}
							setInitPrice={setInitPrice}
							setEndPrice={setEndPrice}
							initPrice={initPrice}
							endPrice={endPrice}
						/>
					)}
				</Grid>
			)}
			<PdfTemplate dataPdf={pdfData} username={username} />
		</>
	);
};

function PaperSubscriber({
	quotationServiceFunc,
	strategy,
	activeStrategy,
	paper,
	setShowCircularProgressCallback,
	options,
}) {
	const dispatch = useDispatch();
	const timerUpdate = useSelector(state => state.simulator.timerUpdate);
	const [updatePxPending, setUpdatePxPending] = useState(false);
	const [workingPrice, setWorkingPrice] = useState(null);
	const [workingPxVar, setWorkingPxVar] = useState(null);
	const [streamingConnectionId, setStreamingConnectionId] = useState('');
	const prevStreamingConnectionId = useRef();

	useEffect(() => {
		if (updatePxPending) {
			if (workingPrice && workingPrice !== paper?.price) {
				dispatch(
					setPaper(paper?.symbol, {
						price: workingPrice,
						var: workingPxVar,
					})
				);
			}
			setUpdatePxPending(false);
		}
	}, [timerUpdate]);

	useEffect(() => {
		if (!strategy || !strategy?.underSymbol) return;

		async function fetchOptions(maximumRetry = 3) {
			if (!options || (options && options.length === 0)) {
				setShowCircularProgressCallback(true);
			}
			apiMarketData
				.get(
					`/exchanges/BVMF/instruments/${strategy?.underSymbol}/derivatives?securityType=OPT`,
					{
						timeout: 25000, //25s
						timeoutErrorMessage:
							'Falha de comunicação com servidor para trazer opções do papel. Verifique a conexão.',
					}
				)
				.then(function (response) {
					setShowCircularProgressCallback(false);
					const { records = [] } = response.data;
					if (records.length === 0) {
						if (maximumRetry > 0) {
							fetchOptions(maximumRetry - 1);
							return;
						} else {
							return toast.error(
								'Erro ao trazer opções do papel'
							);
						}
					}
					dispatch(
						setOptions(
							strategy?.underSymbol,
							response.data.records[0].derivatives
						)
					);
				})
				.catch(function (error) {
					setShowCircularProgressCallback(false);
					if (maximumRetry > 0) {
						fetchOptions(maximumRetry - 1);
						return;
					} else {
						return toast.error(
							error?.message || 'Erro ao trazer opções do papel'
						);
					}
				});
		}
		async function fetchPrice() {
			const currentPaper = await getElasticSearchValue(
				strategy?.underSymbol
			);

			dispatch(setPaper(currentPaper.symbol, currentPaper));

			dispatch(
				strategyUpdate({
					id: strategy.id,
					underType: currentPaper.securityType,
					histVol30: currentPaper.histVol30,
					histVol90: currentPaper.histVol90,
					histVol180: currentPaper.histVol180,
					histVol360: currentPaper.histVol360,
					subExchange: currentPaper.subExchange,
				})
			);
		}

		paperSubscribe(strategy.underSymbol);
		fetchPrice();
		fetchOptions();

		return () => paperUnsubs();
	}, [activeStrategy, strategy?.underSymbol]);

	const paperSubscribe = symbol => {
		const props = {
			action: 'subscribe',
			type: 'trade',
			key: symbol,
			callbackFunction: data => {
				if (data.msgType === 'T') {
					const p = {};
					if (data.var) p.var = data.var;
					if (data.price || data.lastPx)
						p.price = data.price || data.lastPx;

					setWorkingPxVar(p.var);
					setWorkingPrice(p.price);
					setUpdatePxPending(true);
				}
			},
		};
		setTimeout(() => {
			let result = quotationServiceFunc(props);
			paperUnsubs();

			setStreamingConnectionId(result);
		}, 1500);
	};

	React.useEffect(() => {
		prevStreamingConnectionId.current = streamingConnectionId;
	}, [streamingConnectionId]);

	const paperUnsubs = () => {
		if (!(prevStreamingConnectionId.current || streamingConnectionId))
			return;
		const props = {
			action: 'unsubscribe',
			id: prevStreamingConnectionId.current || streamingConnectionId,
		};
		quotationServiceFunc(props);
	};

	return '';
}

export default TableTools;
