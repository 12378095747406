import { ProductsActionTypes } from './actionTypes';

const productsInitialState = {
	activeProduct: null,
	products: [],
	userSignatures: [],
};

export const products = (state = productsInitialState, { type, payload }) => {
	switch (type) {
		case ProductsActionTypes.SET_ACTIVE_PRODUCT:
			return { ...state, activeProduct: payload.product };
		case ProductsActionTypes.SET_PRODUCTS:
			return { ...state, products: payload.products };
		case ProductsActionTypes.SET_USER_SIGNATURES:
			return { ...state, userSignatures: payload.signatures };
		case ProductsActionTypes.RESET_PRODUCTS_STATE:
			return productsInitialState;
		default:
			return state;
	}
};
