import React from 'react';
import { useSelector } from 'react-redux';

import { AppBar, Typography } from '@mui/material';

import { HeaderToolbar, HeaderInfo, AccountBox } from './styles';

import { HeaderTools } from './HeaderTools';

import flexScanImage from 'assets/images/flexscan_white_logo.png';

export const Header = ({ handleOpenModal, openedModal }) => {
	const { username, accountType } = useSelector(state => state.auth);

	return (
		<AppBar position="fixed">
			<HeaderToolbar>
				<HeaderInfo>
					<img src={flexScanImage} alt="flexscan" />

					<Typography variant="subtitle1">
						Usuário: <strong>{username}</strong>
					</Typography>

					<AccountBox accountType={accountType}>
						<Typography variant="h6">
							{accountType === 'demo'
								? 'CONTA SIMULADA'
								: 'CONTA REAL'}
						</Typography>
					</AccountBox>
				</HeaderInfo>

				<HeaderTools
					openedModal={openedModal}
					handleOpenModal={handleOpenModal}
				/>
			</HeaderToolbar>
		</AppBar>
	);
};
