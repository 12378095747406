import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { calcOptionPrice } from '@investflex/iflexquantjs';
import { useTranslation } from 'react-i18next';

import { Box, IconButton } from '@mui/material';
import { Explicit, LockOpen, LockOutlined } from '@mui/icons-material';

import {
	updatePosition,
	setSavedStrategy,
} from 'store/modules/simulator/actions';
import { getPaper } from '../utils';

import PositionSubscriber from './PositionSubscriber';
import { SimulatorDecimalInput } from './DecimalInput';
import { LightTooltip } from 'components/LightTooltip';

export const InputEntryPx = memo(function InputEntryPx({
	value,
	position,
	quotationServiceFunc,
}) {
	const {
		id,
		expired,
		strikePx,
		optionType,
		last_entryPx,
		exitPxLocked,
		entryPxLocked,
		entryManualMode,
		entryImpliedVol,
		daysToExpiration,
	} = position;

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const price = useSelector(getPaper).price;
	const ir = useSelector(state => state.simulator.ir);
	const activeStrategy = useSelector(state => state.simulator.activeStrategy);

	const [workingPrice, setWorkingPrice] = useState(value);

	useEffect(() => {
		if (optionType) {
			//OPÇÃO
			if (!entryPxLocked && !entryManualMode) {
				setWorkingPrice(last_entryPx);
				updateValue(last_entryPx);
			}
		} else if (!entryPxLocked) {
			//ATIVO
			const newValue = last_entryPx || 0;

			if (newValue !== workingPrice) {
				setWorkingPrice(newValue);
				updateValue(newValue);
			}
		}
	}, [last_entryPx, entryPxLocked, entryManualMode]);

	// Função que atualiza o preço baseado na vol somente em simulação (manual mode) e cadeado verde
	useEffect(() => {
		if (!entryManualMode || entryPxLocked) {
			return;
		}

		handleUpdateTheorPx();
	}, [price, strikePx, ir, daysToExpiration, entryImpliedVol]);

	//Função que atualiza o preço baseado na vol para cada acessor sendo que entry e entry somente em simulação
	function handleUpdateTheorPx() {
		const volatility = entryImpliedVol / 100;

		const newValue = parseFloat(
			calcOptionPrice(
				price,
				strikePx,
				ir,
				volatility,
				daysToExpiration,
				optionType
			).toFixed(4)
		);

		if (newValue !== value && newValue !== workingPrice) {
			setWorkingPrice(newValue);
			updateValue(newValue);
		}
	}

	function updateValue(value) {
		dispatch(
			updatePosition(activeStrategy, id, {
				entryPx: parseFloat(value),
			})
		);
	}

	function handleToggle() {
		if (expired) {
			return;
		}

		dispatch(setSavedStrategy(activeStrategy, false));

		const greeksDefault = { delta: 0, gamma: 0, vega: 0, theta: 0, rho: 0 };
		const greeksDefaultNA = {
			delta: null,
			gamma: null,
			vega: null,
			theta: null,
			rho: null,
		};

		if (entryPxLocked && exitPxLocked) {
			//Se ambos estão fechados, nada acontece
			return;
		}

		if (entryPxLocked) {
			//Se estava fechado, e vai abrir a saída
			const updatedGreeks =
				value === 0 && optionType
					? greeksDefaultNA
					: { ...greeksDefault, delta: !optionType ? 1 : 0 };

			dispatch(
				updatePosition(activeStrategy, id, {
					entryGreeks: updatedGreeks,
					entryPxLocked: false,
					entryPx: value,
					entryManualMode: false, //Tira do manual mode se o cadeado (da entrada) for fechado
				})
			);
		} else {
			dispatch(
				updatePosition(activeStrategy, id, {
					entryPxLocked: true,
					entryGreeks: greeksDefault,
					entryManualMode: false, //Tira do manual mode se o cadeado for fechado
				})
			);
		}
	}

	function handleChange(event) {
		if (!(typeof event.value === 'undefined')) {
			updateValue(event.value); //Comentado para dispachar somente depois de timeout

			if (event.type === 'change') {
				dispatch(setSavedStrategy(activeStrategy, false));
			}
		}
	}

	const iconColor = entryPxLocked
		? expired
			? '#ce8100'
			: '#ff5a5a'
		: '#388e3c';

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
				my: 0.25,
			}}
		>
			<SimulatorDecimalInput
				width={40}
				minVal={0}
				minDecScale={4}
				stepSize={0.01}
				maxVal={999999.99}
				disabled={!entryPxLocked}
				onChange={event => handleChange(event)}
				value={entryPxLocked ? value : workingPrice || '-'} //Exibe '-' se o valor quando editavel for zero
			/>

			<IconButton
				size="small"
				onClick={handleToggle}
				style={{ color: iconColor }}
			>
				<LightTooltip
					arrow
					title={
						entryPxLocked &&
						expired &&
						t('simulator.tooltip.expiredButton')
					}
				>
					{entryPxLocked ? (
						expired ? (
							<Explicit />
						) : (
							<LockOutlined />
						)
					) : (
						<LockOpen />
					)}
				</LightTooltip>
			</IconButton>

			<PositionSubscriber
				position={position}
				quotationServiceFunc={quotationServiceFunc}
			/>
		</Box>
	);
});
