import React from 'react';

import {
	GridToolbarContainer,
	GridToolbarFilterButton,
	GridToolbarColumnsButton,
	GridToolbarDensitySelector,
} from '@mui/x-data-grid-pro';
import { Box, Button } from '@mui/material';
import { Refresh } from '@mui/icons-material';

import { CurrentStrategiesExportMenu } from './CurrentStrategiesExportMenu';
import { CurrentStrategiesToolbarActions } from './CurrentStrategiesToolbarActions';

export const CurrentStrategiesToolbar = (
	isLoading,
	setIsLoading,
	apiRef,
	setSnackbarState,
	handleRefresh,
	profile
) => {
	const isButtonsDisabled = isLoading || apiRef.current.getAllRowIds().length === 0 || profile === 4;

	return (
		<GridToolbarContainer
			sx={{
				display: 'flex',
				justifyContent: 'space-between'
			}}
		>
			<Box sx={{ display: 'flex' }}>
				<GridToolbarColumnsButton
					size="small"
					color="inherit"
					disabled={isButtonsDisabled}
				/>

				<GridToolbarFilterButton
					size="small"
					color="inherit"
					disabled={isButtonsDisabled}
				/>

				<GridToolbarDensitySelector
					size="small"
					color="inherit"
					disabled={isButtonsDisabled}
				/>

				<CurrentStrategiesExportMenu
					apiRef={apiRef}
					disabled={isButtonsDisabled}
				/>

				<Button
					size="small"
					color="inherit"
					disabled={isLoading || profile === 4}
					onClick={handleRefresh}
					startIcon={<Refresh />}
				>
					Recarregar
				</Button>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<CurrentStrategiesToolbarActions
					apiRef={apiRef}
					isLoading={isLoading}
					setIsLoading={setIsLoading}
					setSnackbarState={setSnackbarState}
					profile={profile}
				/>
			</Box>
		</GridToolbarContainer>
	);
};
