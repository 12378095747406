import React from 'react';

import { Tabs } from '@mui/material';
import { AccountBalanceWallet, Wallet } from '@mui/icons-material';

import { StyledTab } from 'components/Portfolio/TabsHeader/styles';
import { useSelector } from 'react-redux';

export const PositionsTabs = ({ tabIndex, handleChangeTabIndex }) => {
	const { custodyEnabled } = useSelector(state => state.auth);

	function handleChange(_, newIndex) {
		handleChangeTabIndex(newIndex);
	}

	function a11yProps(index) {
		return {
			'aria-controls': `simple-tabpanel-${index}`,
		};
	}

	return (
		<>
			<Tabs
				value={tabIndex}
				onChange={handleChange}
				variant="scrollable"
				scrollButtons="auto"
			>
				<StyledTab
					label="Posições de hoje"
					icon={<AccountBalanceWallet />}
					iconPosition="start"
					sx={{ minHeight: '50px !important' }}
					{...a11yProps(0)}
				/>

				{custodyEnabled && (
					<StyledTab
						label="Posições consolidadas"
						icon={<Wallet />}
						iconPosition="start"
						sx={{ minHeight: '50px !important' }}
						{...a11yProps(1)}
					/>
				)}
			</Tabs>
		</>
	);
};
