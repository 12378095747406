import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { LoadingButton } from '@mui/lab';
import { Search } from '@mui/icons-material';
import { Button, Grid, TextField } from '@mui/material';

import { removeDuplicatesItem } from 'utils/removeDuplicatesItem';

import { StrategyCodeSelect } from '../StrategyCodeSelect';
import { resetRecurrenceSearchForm, updateRecurrenceSearchForm } from 'store/modules/recurrence/actions';

export const RecurrenceSearchForm = ({ searchForm, handleSearch, paginationInfo, isLoading }) => {
	const dispatch = useDispatch();

	const { accounts, userType, profile } = useSelector(state => state.auth);

	const userAccounts = removeDuplicatesItem(accounts, 'code');

	const formattedAccounts =
		userAccounts.length === 1
			? userAccounts
			: [{ code: 'Todos' }, ...userAccounts];

	const freqTypeOptions = [
		{ value: 'all', label: 'Todos' },
		{ value: 1, label: 'Única' },
		{ value: 2, label: 'Diária' },
		{ value: 3, label: 'Semanal' }
	];

	const statusOptions = [
		{ value: 'all', label: 'Todos' },
		{ value: 0, label: 'Encerradas' },
		{ value: 1, label: 'Em andamento' },
		{ value: 2, label: 'Canceladas' },
		{ value: 3, label: 'Com erro' },
		{ value: 4, label: 'Concluídas' },
	];

	function handleSubmit(event) {
		if (event) {
			event.preventDefault();
		}

		handleSearch(
			{
				...paginationInfo,
				page: 0,
				offset: 0,
				total: null,
			},
			event
		);
	}

	function handleChangeSearchForm(field, value) {
		dispatch(updateRecurrenceSearchForm(field, value));
	}

	function handleClear() {
		dispatch(resetRecurrenceSearchForm());
	};

	return (
		<form
			onSubmit={handleSubmit}
			style={{ width: '100%', padding: '0 1rem' }}
		>
			<Grid xs={12} item container spacing={1}>
				<Grid xs={2} item>
					<TextField
						label="Tipo"
						id="freqType"
						name="freqType"
						size="small"
						defaultValue="all"
						value={searchForm.freqType}
						SelectProps={{ native: 'native' }}
						fullWidth
						select
						onChange={event => handleChangeSearchForm(
							event.target.name,
							event.target.value
						)}
					>
						{freqTypeOptions.map(status => (
							<option key={status.value} value={status.value}>
								{status.label}
							</option>
						))}
					</TextField>
				</Grid>

				<Grid item xs={2}>
					<TextField
						label="Status"
						id="status"
						name="status"
						size="small"
						defaultValue={1}
						value={searchForm.status}
						SelectProps={{ native: 'native' }}
						fullWidth
						select
						onChange={event => handleChangeSearchForm(
							event.target.name,
							event.target.value
						)}
					>
						{statusOptions.map(status => (
							<option key={status.value} value={status.value}>
								{status.label}
							</option>
						))}
					</TextField>
				</Grid>

				<Grid item xs={1}>
					<StrategyCodeSelect
						id="strategyCode"
						name="strategyCode"
						label="Estratégia"
						value={searchForm.strategyCode}
						onChange={event => handleChangeSearchForm(
							event.target.name,
							event.target.value
						)}
					/>
				</Grid>

				<Grid item xs={1}>
					{[2, 5].includes(userType.id) ? (
						<TextField
							id="account"
							name="account"
							label="Conta"
							size="small"
							fullWidth
							value={
								searchForm.account === 'all'
									? ''
									: searchForm.account
							}
							onChange={event => handleChangeSearchForm(
								event.target.name,
								event.target.value
							)}
						/>
					) : (
						<TextField
							id="account"
							name="account"
							labelId="account-label"
							label="Conta (CBLC)"
							SelectProps={{ native: 'native' }}
							value={searchForm.account}
							defaultValue={
								userAccounts.length === 1
									? userAccounts[0]
									: 'all'
							}
							fullWidth
							size="small"
							InputLabelProps={{ shrink: true }}
							select
							onChange={event => handleChangeSearchForm(
								event.target.name,
								event.target.value
							)}
						>
							{formattedAccounts.map(account => (
								<>
									<option
										key={account.code}
										value={account.code}
									>
										{account.code}
									</option>
								</>
							))}
						</TextField>
					)}
				</Grid>

				<Grid item xs={1.5}>
					<LoadingButton
						fullWidth
						type="submit"
						loading={isLoading}
						variant="contained"
						startIcon={<Search />}
						sx={{ height: '100%' }}
						disabled={profile === 4}
					>
						Procurar
					</LoadingButton>
				</Grid>

				<Grid item xs={1}>
					<Button
						fullWidth
						variant="contained"
						onClick={handleClear}
						sx={{
							height: '100%',
							bgcolor: '#fff',
							color: '#2196f3',
							'&:hover': {
								bgcolor: '#e7e7e7',
								color: '#1069b3',
							},
						}}
					>
						Limpar
					</Button>
				</Grid>
			</Grid>
		</form>
	);
};
